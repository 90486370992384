<template>
  <form class="row g-sm-4 g-2" @submit.prevent="changePassword">
    <div>
      <label
        for="old-pass"
        class="form-label"
        :class="{ incorrect: passOldIncorrect }"
        >{{ $t("oldPassword") }}</label
      >
      <input
        type="text"
        class="form-control input-text font-poppins"
        id="old-pass"
        ref="input"
        v-model="oldPass"
        required
      />
      <div v-if="passOldIncorrect" class="mt-2">
        <!-- {{ $t("incorrectPassword") }} -->
        {{ textPassOldIncorrect }}
      </div>
    </div>
    <div>
      <label for="new-pass" class="form-label">{{ $t("newPassword") }}</label>
      <input
        type="text"
        class="form-control input-text font-poppins"
        id="new-pass"
        v-model="newPass"
        required
      />
    </div>
    <div>
      <label
        for="repeat-pass"
        class="form-label"
        :class="{ incorrect: passNotMatch }"
        >{{ $t("repeatPassword") }}</label
      >
      <input
        type="text"
        class="form-control input-text font-poppins"
        id="repeat-pass"
        v-model="repeatPass"
        required
      />
      <div v-if="passNotMatch" class="mt-2">
        <!-- {{ $t("passwordDoNotMatch") }} -->
        {{ textPassNotMatch }}
      </div>
    </div>
    <button class="btn btn-orange mt-sm-5 mt-4" type="submit">
      {{ $t("save") }}
    </button>
  </form>
</template>

<script>
import { ref, watch } from "vue";
import saveInfoAlert from "@/assets/js/saveInfoAlert";
import axios from "axios";

export default {
  name: "ChangePassword",
  setup() {
    const oldPass = ref("");
    const newPass = ref("");
    const repeatPass = ref("");
    const passOldIncorrect = ref(false);
    const textPassOldIncorrect = ref("");
    const passNotMatch = ref(false);
    const textPassNotMatch = ref("");

    watch(oldPass, () => {
      passOldIncorrect.value = false;
    });
    watch(repeatPass, () => {
      passNotMatch.value = false;
    });

    return {
      oldPass,
      newPass,
      repeatPass,
      passNotMatch,
      passOldIncorrect,
      textPassOldIncorrect,
      textPassNotMatch,
    };
  },
  mounted() {
    this.$refs.input.focus();
  },
  methods: {
    async changePassword() {
      await axios
        .post("dashboard/reset-password", {
          current_password: this.oldPass,
          password: this.newPass,
          password_confirmation: this.repeatPass,
        })
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
          this.oldPass = "";
          this.newPass = "";
          this.repeatPass = "";
        })
        .catch((err) => {
          if (this.newPass.length < 8) {
            saveInfoAlert(
              this.$i18n.locale,
              "error",
              err.response.data.message
            );
          } else if (this.newPass !== this.repeatPass) {
            this.textPassNotMatch = err.response.data.message;
            this.passNotMatch = true;
          } else {
            this.textPassOldIncorrect = err.response.data.message;
            this.passOldIncorrect = true;
          }
        });
    },
  },
};
</script>

<style scoped>
form {
  width: 500px;
  margin: 0;
}
form > div {
  padding: 0;
}
form input {
  color: var(--black);
}
@media (max-width: 992px) {
  form {
    width: 100%;
  }
}
</style>
