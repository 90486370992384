<template>
  <form
    class="d-flex flex-column flex-md-row flex-wrap gap-md-4 gap-3"
    @submit.prevent="$emit('search')"
  >
    <div class="name">
      <input
        type="text bg-transparent"
        class="form-control input-text bg-transparent font-poppins"
        :placeholder="$t('listCommission[2].placeholderUser')"
        v-model="username"
      />
    </div>
    <div
      class="d-flex align-items-md-center flex-md-row flex-column align-items-start gap-2 gap-md-3"
    >
      <span class="from">{{ $t("from") }}</span>
      <div
        class="date d-flex align-items-center justify-content-between gap-2 rad-10"
      >
        <VueDatePicker
          class="date-picker"
          v-model="startDate"
          :enable-time-picker="false"
          :format="'dd.MM.yyyy'"
          selectText="OK"
          @update:model-value="getStartDate"
        />
      </div>
    </div>
    <div
      class="d-flex align-items-md-center flex-md-row flex-column align-items-start gap-2 gap-md-3"
    >
      <span class="to">{{ $t("to") }}</span>
      <div
        class="date d-flex align-items-center justify-content-between gap-2 rad-10"
      >
        <VueDatePicker
          class="date-picker"
          v-model="endDate"
          :enable-time-picker="false"
          :format="'dd.MM.yyyy'"
          selectText="OK"
          @update:model-value="getEndDate"
        />
      </div>
    </div>
    <div class="dropdown custom-select">
      <button
        class="dropdown-toggle d-flex align-items-center justify-content-between"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span>{{ value }}</span>
        <i class="bi bi-chevron-down"></i>
      </button>
      <ul class="dropdown-menu">
        <li>
          <button
            type="button"
            class="dropdown-item active status"
            :class="{ rtl: $i18n.locale === 'ar' }"
            @click="chooseStatus($event, '')"
          >
            {{ $t("all") }}
          </button>
        </li>
        <li>
          <button
            type="button"
            class="dropdown-item status"
            :class="{ rtl: $i18n.locale === 'ar' }"
            @click="chooseStatus($event, 'pending')"
          >
            {{ $t("listCommission[2].waitReview") }}
          </button>
        </li>
        <li>
          <button
            type="button"
            class="dropdown-item status"
            :class="{ rtl: $i18n.locale === 'ar' }"
            @click="chooseStatus($event, 'draft')"
          >
            {{ $t("listCommission[2].draft") }}
          </button>
        </li>
        <li>
          <button
            type="button"
            class="dropdown-item status"
            :class="{ rtl: $i18n.locale === 'ar' }"
            @click="chooseStatus($event, 'confirm')"
          >
            {{ $t("listCommission[2].confirmed") }}
          </button>
        </li>
        <li>
          <button
            type="button"
            class="dropdown-item status"
            :class="{ rtl: $i18n.locale === 'ar' }"
            @click="chooseStatus($event, 'ignore')"
          >
            {{ $t("listCommission[2].ignore") }}
          </button>
        </li>
      </ul>
    </div>
    <input type="submit" class="btn btn-orange" :value="$t('search')" />
    <input
      type="button"
      class="btn btn-blue"
      :value="$t('export')"
      @click="exportData"
    />
    <!-- Refresh Searching -->
    <button type="button" @click="init" class="refrsh-btn">
      <img src="@/assets/images/refresh-search.png" alt="" />
    </button>
  </form>
</template>

<script>
import axios from "axios";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import chooseItem from "@/assets/js/chooseItem";

import { useCommission } from "@/stores/Commission";
import { mapActions } from "pinia";
import saveInfoAlert from "@/assets/js/saveInfoAlert";

export default {
  name: "SearchCommission",
  components: {
    VueDatePicker,
  },
  props: ["id"],
  data() {
    return {
      username: "",
      startDate: null,
      endDate: null,
      value: this.$t("all"),
      status: "",
      isSearch: false,
    };
  },
  watch: {
    "$i18n.locale"() {
      this.init();
      this.username = "";
      this.value = this.$t("all");
      this.status = "";
    },
  },
  methods: {
    ...mapActions(useCommission, [
      "getUsersCommission",
      "getCommissionRecords",
    ]),
    init() {
      const date = new Date();
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      this.startDate = `${year}-${month}-${day}`;
      this.endDate = `${year}-${month}-${day}`;
      if (this.id == "commissionRecord") {
        this.getCommissionRecords("2020-01-01", this.endDate, "", "", 10, 1);
      } else {
        this.getUsersCommission("2020-01-01", this.endDate, "", "", 10, 1);
      }
      this.isSearch = false;
    },
    chooseStatus($event, status) {
      chooseItem(".status", $event);
      this.value = $event.target.textContent;
      this.status = status;
    },
    getStartDate(date) {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      this.startDate = `${year}-${month}-${day}`;
    },
    getEndDate(date) {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      this.endDate = `${year}-${month}-${day}`;
    },
    async exportData() {
      if (this.id == "commissionRecord") {
        await axios
          .get("dashboard/commissions/commission-records", {
            params: {
              from_date: this.startDate,
              to_date: this.endDate,
              status: this.status,
              export_excel: "",
            },
            responseType: "blob",
          })
          .then((res) => {
            const filename = res.headers.export_file_name;
            const url = URL.createObjectURL(
              new Blob([res.data], {
                type: "application/vnd.ms-excel",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            saveInfoAlert(
              this.$i18n.locale,
              "error",
              err.response.data.message
            );
          });
      } else {
        await axios
          .get("dashboard/commissions/users-commission", {
            params: {
              from_date: this.startDate,
              to_date: this.endDate,
              status: this.status,
              export_excel: "",
            },
            responseType: "blob",
          })
          .then((res) => {
            const filename = res.headers.export_file_name;
            const url = URL.createObjectURL(
              new Blob([res.data], {
                type: "application/vnd.ms-excel",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            saveInfoAlert(
              this.$i18n.locale,
              "error",
              err.response.data.message
            );
          });
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
form {
  font-weight: 500;
  color: var(--black);
}
form .name input {
  width: 330px;
  height: 100%;
}
@media (max-width: 767px) {
  form .name input {
    width: 100%;
  }
}

form .date {
  border: 1px solid var(--border);
  padding: 15px;
  cursor: pointer;
}
@media (max-width: 767px) {
  form .date {
    width: 100%;
    padding: 12px;
  }
  form span.from,
  form span.to {
    font-size: 12px;
  }
}

/** Style Of DropDown **/
.custom-select {
  width: 190px;
}
.custom-select ul button {
  padding: 15px;
}
@media (max-width: 767px) {
  .custom-select {
    width: 100%;
  }
}

.custom-select ul li:not(:last-of-type) button {
  border-bottom: 1px solid var(--border);
}
.custom-select ul button.active,
.custom-select ul button:hover {
  background-color: var(--border);
}

.refrsh-btn {
  background-color: transparent;
}
.refrsh-btn img {
  width: 45px;
}
</style>
