<template>
  <div class="mid collapse row p-0">
    <div class="col">{{ child.id }}</div>
    <div class="col name">
      <div>
        <button
          v-if="child.level == 2"
          type="button"
          class="toggle"
          :class="{ expand: expand }"
          data-bs-toggle="collapse"
          :data-bs-target="`#${child.id}`"
          aria-expanded="false"
          :aria-controls="`#${child.id}`"
          @click="toggleMenu"
        ></button>
        <span>{{ child.name }}</span>
      </div>
    </div>
    <div
      class="col"
      type="button"
      data-bs-toggle="modal"
      data-bs-target="#editSort"
      @click="getCategory(child)"
    >
      {{ child.sort }}
    </div>
    <div class="col">
      <input
        type="checkbox"
        class="custom-toggle"
        :defaultChecked="child.status"
        @change="changeStatus(child.id)"
      />
    </div>
    <div class="col"></div>
    <div class="col">
      <input
        type="checkbox"
        class="custom-toggle"
        :defaultChecked="child.recommended"
        @change="changeRecommended(child.id)"
      />
    </div>
    <!-- Options -->
    <div class="col d-flex justify-content-evenly">
      <button
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#editMidCategory"
        class="bg-transparent"
        @click="getCategoryInfo(child, parentId)"
      >
        <img src="@/assets/images/edit.svg" />
      </button>
      <button
        v-if="child.level == 2"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#addfinalCategory"
        class="bg-transparent"
        @click="[getCategory(child), deleteSquare()]"
      >
        <img src="@/assets/images/add-square.svg" />
      </button>
      <button
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#deleteCategory"
        class="bg-transparent"
        @click="getCategory(child)"
      >
        <img src="@/assets/images/trash.svg" />
      </button>
    </div>

    <!-- Collapse - Final Category -->
    <div
      class="collapse row p-0"
      :id="child.id"
      v-for="final in child.children"
      :key="final.id"
    >
      <div class="col">{{ final.id }}</div>
      <div class="col name finale">
        <div>{{ final.name }}</div>
      </div>
      <div
        class="col"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#editSort"
        @click="getCategory(final)"
      >
        {{ final.sort }}
      </div>
      <div class="col">
        <input
          type="checkbox"
          class="custom-toggle"
          :defaultChecked="final.status"
          @change="changeStatus(final.id)"
        />
      </div>
      <div class="col"></div>
      <div class="col">
        <input
          type="checkbox"
          class="custom-toggle"
          :defaultChecked="final.recommended"
          @change="changeRecommended(final.id)"
        />
      </div>
      <div class="col d-flex justify-content-evenly">
        <button
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#editFinalCategory"
          class="bg-transparent"
          @click="getCategoryInfo(final, child.id)"
        >
          <img src="@/assets/images/edit.svg" alt="edit" />
        </button>
        <button
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#deleteCategory"
          class="bg-transparent"
          @click="getCategory(final)"
        >
          <img src="@/assets/images/trash.svg" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import saveInfoAlert from "@/assets/js/saveInfoAlert";
import { useFetchAll } from "@/stores/FetchAll";
import axios from "axios";
import { mapActions } from "pinia";

export default {
  name: "MidCategory",
  props: ["child", "parentId"],
  data() {
    return {
      expand: false,
    };
  },
  methods: {
    ...mapActions(useFetchAll, ["getCategory", "getCategoryInfo"]),
    toggleMenu() {
      this.expand = !this.expand;
    },
    deleteSquare() {
      this.expand = false;
      const items = document.querySelectorAll(".mid .collapse.show");
      items.forEach((el) => {
        el.classList.remove("show");
      });
    },
    async changeStatus(id) {
      await axios
        .post(`dashboard/categories/${id}/change-status`)
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        });
    },
    async changeRecommended(id) {
      await axios
        .post(`dashboard/categories/${id}/change-recommended`)
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        });
    },
  },
};
</script>

<style scoped>
.body-table div.name div {
  margin-left: 50px;
}
.body-table.rtl div.name div {
  margin-right: 50px;
  margin-left: 0;
}
.body-table div.name.finale div {
  margin-left: 75px;
}
.body-table.rtl div.name.finale div {
  margin-right: 75px;
  margin-left: 0;
}
</style>
