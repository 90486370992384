<template>
  <div class="table-classes responsive-table">
    <div class="table" :class="{ rtl: $i18n.locale === 'ar' }">
      <div class="column" v-for="item in categories" :key="item.id">
        <div class="head p-md-4 p-3 d-flex">
          <label class="d-flex align-items-center">
            <input
              type="checkbox"
              :id="item.id"
              class="custom-checkbox m-0"
              :class="'parent' + item.id.toString()"
              @change="checkAll($event, item.id)"
            />
            <span v-if="$i18n.locale === 'en'">{{ item.name_en }}</span>
            <span v-else>{{ item.name_ar }}</span>
          </label>
        </div>
        <div
          class="items p-md-4 p-3 d-flex"
          v-for="child in item.children"
          :key="child.id"
        >
          <label class="d-flex align-items-center">
            <input
              type="checkbox"
              :id="child.id"
              class="custom-checkbox m-0 mid-cat"
              :class="'child' + item.id.toString()"
              @change="removeCheckParent(item.id)"
            />
            <span v-if="$i18n.locale === 'en'">{{ child.name_en }}</span>
            <span v-else>{{ child.name_ar }}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useFetchAll } from "@/stores/FetchAll";
import { mapState } from "pinia";

export default {
  name: "TableClasses",
  computed: {
    ...mapState(useFetchAll, ["categories"]),
  },
  methods: {
    checkAll($event, className) {
      const items = document.querySelectorAll(`.child${className}`);
      if ($event.target.checked == true) {
        items.forEach((el) => {
          el.checked = true;
        });
      } else {
        items.forEach((el) => {
          el.checked = false;
        });
      }
    },
    removeCheckParent(parentId) {
      const item = document.querySelector(`.parent${parentId}`);
      item.checked = false;
    },
  },
};
</script>

<style scoped>
.responsive-table {
  max-height: 650px;
  height: 100vh;
}
.table {
  display: flex;
  width: fit-content;
}
.table > div div {
  border: 1px solid #9e9e9e;
  min-width: 230px;
}
.table > div div:not(:last-child) {
  border-bottom: none;
}
.table label {
  gap: 30px;
  margin-left: 20px;
  margin-bottom: 0;
  white-space: nowrap;
  cursor: pointer;
}
.table.rtl label {
  margin-right: 20px;
  margin-left: 0;
}
.table div.head label {
  font-size: 18px;
}
.table div.items label {
  font-size: 16px;
  color: var(--black-alt);
}

@media (max-width: 767px) {
  .table > div div {
    min-width: 180px;
  }
  .table label {
    gap: 20px;
  }
  .table div.head label {
    font-size: 14px !important;
  }
  .table div.items label {
    font-size: 12px !important;
  }
}
</style>
