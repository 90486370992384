<template>
  <div class="free-commission">
    <h4 class="title">{{ $t("listCommission[1].title") }}</h4>
    <form @submit.prevent="saveFreeCommission">
      <div class="position-relative">
        <label class="form-label fs-5">{{
          $t("listCommission[1].freeCommissionAmount")
        }}</label>
        <div
          class="input-div position-relative"
          :class="{ rtl: $i18n.locale === 'ar' }"
        >
          <input
            type="text"
            class="form-control input-text font-poppins"
            :placeholder="$t('listCommission[1].freeplaceholder')"
            @keypress="onlyNumber"
            v-model="freeCommission"
            required
          />
          <span
            class="position-absolute"
            :class="{ rtl: $i18n.locale === 'ar' }"
            >{{ $t("sr") }}</span
          >
        </div>
      </div>
      <h5>
        {{ $t("listCommission[0].operation") }}
      </h5>
      <ul>
        <li>
          {{ $t("listCommission[0].defenitions[0]") }}
        </li>
        <li>
          {{ $t("listCommission[0].defenitions[1]") }}
        </li>
        <li>
          {{ $t("listCommission[0].defenitions[2]") }}
        </li>
        <li>
          {{ $t("listCommission[0].defenitions[3]") }}
        </li>
      </ul>
      <button data-v-1442a548="" type="submit" class="btn btn-orange">
        {{ $t("save") }}
      </button>
    </form>
  </div>
</template>

<script>
import onlyNumber from "@/assets/js/onlyNumber";
import saveInfoAlert from "@/assets/js/saveInfoAlert";
import axios from "axios";

export default {
  name: "FreeCommission",
  data() {
    return {
      freeCommission: "",
    };
  },
  methods: {
    onlyNumber,
    async saveFreeCommission() {
      await axios
        .post("dashboard/commissions/set-free-commission-value", {
          value: this.freeCommission,
        })
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        });
    },
  },
  async mounted() {
    await axios
      .get("dashboard/settings/get-free-commission")
      .then((res) => {
        this.freeCommission = res.data;
      })
      .catch((err) => {
        saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
      });
  },
};
</script>

<style scoped>
.free-commission form {
  margin-top: 120px;
}
.free-commission form .input-div {
  max-width: 500px;
}
@media (max-width: 767px) {
  .free-commission form {
    margin-top: 40px;
  }
  .free-commission form .input-div {
    margin-right: 30px;
  }
  .free-commission form .input-div.rtl {
    margin-right: 0;
    margin-left: 40px;
  }
  .free-commission form label {
    font-size: 15px !important;
  }
}
.free-commission form input::placeholder {
  color: #cacaca !important;
}
.free-commission form span {
  font-size: 20px;
  font-weight: 500;
  right: -35px;
  top: 50%;
  transform: translateY(-50%);
}
.free-commission form span.rtl {
  right: auto;
  left: -47px;
}
@media (max-width: 767px) {
  .free-commission form span {
    font-size: 14px;
    right: -25px;
  }
  .free-commission form span.rtl {
    left: -40px;
  }
}
.free-commission h5 {
  font-weight: 600;
  color: var(--black);
  margin-block: 30px;
  line-height: 1.6;
  text-transform: capitalize;
}
.free-commission ul li {
  font-weight: 500;
  color: var(--black-alt);
  text-transform: capitalize;
  margin: 20px 0;
}

.free-commission button {
  width: 230px;
  margin: 70px auto;
}

@media (max-width: 767px) {
  .free-commission h5 {
    font-size: 14px;
  }
  .free-commission ul li {
    font-size: 12px;
  }
  .free-commission button {
    width: 170px;
    margin: 30px auto;
  }
}
</style>
