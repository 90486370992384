<template>
  <div class="modal-body">
    <!-- Category Title Arabic -->
    <div>
      <label for="titleAR" class="form-label">{{
        $t("categoryTitle") + "(" + $t("ar") + ")"
      }}</label>
      <input
        type="text"
        class="form-control input-text"
        id="titleAR"
        dir="auto"
        :value="category.name_ar"
        @input="getNameAr"
        required
      />
    </div>

    <!-- Category Title English -->
    <div>
      <label for="titleEN" class="form-label">{{
        $t("categoryTitle") + "(" + $t("en") + ")"
      }}</label>
      <input
        type="text"
        class="form-control input-text font-poppins"
        id="titleEN"
        dir="auto"
        :value="category.name_en"
        @input="getNameEn"
        required
      />
    </div>

    <!-- Category Sort -->
    <div>
      <label for="sort" class="form-label">{{
        $t("listCategoryTable[2]")
      }}</label>
      <input
        type="number"
        min="1"
        class="form-control input-text font-poppins"
        id="sort"
        :value="category.sort"
        @input="getSort"
        required
      />
    </div>
  </div>
</template>

<script>
import { useFetchAll } from "@/stores/FetchAll";
import { mapState } from "pinia";

export default {
  name: "EditCategory",
  data() {
    return {
      titleAR: "",
      titleEN: "",
      sort: "",
    };
  },
  computed: {
    ...mapState(useFetchAll, ["category"]),
  },
  methods: {
    getNameAr($event) {
      this.titleAR = $event.target.value;
    },
    getNameEn($event) {
      this.titleEN = $event.target.value;
    },
    getSort($event) {
      this.sort = $event.target.value;
    },
  },
};
</script>

<style scoped></style>
