<template>
  <div class="table">
    <div class="text-center grid-table">
      <!-- Table Head -->
      <div class="row">
        <div class="col" v-for="head in 7" :key="head" :class="`list${head}`">
          {{ $t(`listCategoryTable[${head - 1}]`) }}
        </div>
      </div>

      <GridTableCategory
        v-for="category in categories"
        :key="category.id"
        :category="category"
      />
    </div>
    <div v-if="loading" class="loading load">
      <div class="text-center">
        <img src="@/assets/images/loading.svg" alt="spinner" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useFetchAll } from "@/stores/FetchAll";

import GridTableCategory from "@/components/categories/GridTableCategory.vue";

export default {
  name: "GridTable",
  components: {
    GridTableCategory,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(useFetchAll, ["categories", "loading"]),
  },
};
</script>

<style scoped>
.table {
  overflow: auto;
  margin: 40px 0;
}
.table .grid-table {
  min-width: 1200px;
  margin-bottom: 7px;
  color: var(--black);
  border-top: 1px solid #9e9e9e;
  border-left: 1px solid #9e9e9e;
}
.grid-table div.col {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #9e9e9e;
  border-right: 1px solid #9e9e9e;
  height: 80px;
  font-weight: 600;
}
.grid-table .list2 {
  min-width: 250px;
}
@media (max-width: 767px) {
  .grid-table div.col {
    font-size: 13px;
    height: 60px;
  }
}
.row {
  --bs-gutter-x: 0;
}
</style>
