<template>
  <div
    class="d-flex justify-content-between flex-column flex-md-row flex-wrap gap-4"
  >
    <SearchingUser
      :placeholder="placeholder"
      @search="search"
      @refresh="refresh"
    />
    <!-- Button trigger modal -->
    <button
      type="button"
      class="btn btn-blue"
      data-bs-toggle="modal"
      :data-bs-target="'#' + ident"
      @click="getCategoriesLength"
    >
      {{ add }}
    </button>
    <ModalPopup
      :title="add"
      :ident="ident"
      :width="width"
      @send="$emit('send')"
      @cancelModal="$emit('cancelModal')"
      ref="categoryModal"
    >
      <slot />
    </ModalPopup>
  </div>
</template>

<script>
import { ref } from "vue";

import SearchingUser from "../userSetting/SearchingUser.vue";
import ModalPopup from "./ModalPopup.vue";
import { mapActions } from "pinia";
import { useFetchAll } from "@/stores/FetchAll";
export default {
  name: "SearchingByStatus",
  props: ["ident", "add", "width", "placeholder"],
  components: {
    SearchingUser,
    ModalPopup,
  },
  methods: {
    ...mapActions(useFetchAll, ["getCategoriesLength"]),
    search(payload) {
      this.$emit("search", payload);
    },
    refresh() {
      this.$emit("refresh");
    },
  },
  setup(_, { expose }) {
    const categoryModal = ref(null);

    expose({ categoryModal });

    return {
      categoryModal,
    };
  },
};
</script>

<style scoped>
button {
  min-width: 200px;
}
@media (max-width: 767px) {
  button {
    width: 100%;
  }
}
</style>
