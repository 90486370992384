<template>
  <div class="modal-body">
    <div>
      <label for="sort" class="form-label">{{ $t("videoTable[3]") }}</label>
      <input
        type="number"
        class="form-control input-text font-poppins"
        id="sort"
        v-model="sort"
        min="1"
      />
    </div>
  </div>
</template>

<script>
import { useFetchAll } from "@/stores/FetchAll";
import { mapState } from "pinia";

export default {
  name: "EditSort",
  data() {
    return {
      sort: "",
    };
  },
  computed: {
    ...mapState(useFetchAll, ["catSort"]),
  },
  methods: {
    getSort($event) {
      this.sort = $event.target.value;
    },
  },
};
</script>

<style scoped></style>
