<template>
  <div class="user-commission">
    <h4 class="title">{{ $t("listCommission[2].title") }}</h4>
    <SearchCommission ref="serachCommission" @search="searchUsers" />
    <div class="d-flex flex-wrap gap-md-5 gap-3">
      <p>{{ $t("online") }} {{ userOnlineAmount }} {{ $t("sr") }}</p>
      <p>{{ $t("offline") }} {{ userOfflineAmount }} {{ $t("sr") }}</p>
    </div>
    <TableUsercomm @refreshUsers="refreshUsers" />
    <PaginationBox
      :pages="pagesUsers"
      :current_page="current_page"
      :next_page="next_page"
      :prev_page="prev_page"
      @chooseRec="chooseRecord"
      @thisPage="thisPage"
      @nextPage="nextPage"
      @prevPage="prevPage"
    />
    <!-- For Loading -->
    <div v-if="loading" class="loading load">
      <div class="text-center">
        <img src="@/assets/images/loading.svg" alt="spinner" />
      </div>
    </div>
  </div>
</template>

<script>
import SearchCommission from "@/components/commission/SearchCommission.vue";
import TableUsercomm from "@/components/commission/TableUsercomm.vue";
import PaginationBox from "@/components/global/PaginationBox.vue";

import { useCommission } from "@/stores/Commission";
import { mapState, mapActions } from "pinia";

export default {
  name: "UserCommission",
  components: {
    SearchCommission,
    TableUsercomm,
    PaginationBox,
  },
  data() {
    return {
      records: 10,
    };
  },
  computed: {
    ...mapState(useCommission, [
      "loading",
      "pagesUsers",
      "current_page",
      "next_page",
      "prev_page",
      "userOnlineAmount",
      "userOfflineAmount",
    ]),
  },
  methods: {
    ...mapActions(useCommission, ["getUsersCommission", "getDate"]),
    getData(record, page) {
      if (this.$refs.serachCommission.isSearch) {
        this.getUsersCommission(
          this.$refs.serachCommission.startDate,
          this.$refs.serachCommission.endDate,
          this.$refs.serachCommission.username,
          this.$refs.serachCommission.status,
          record,
          page
        );
      } else {
        this.getUsersCommission(
          "2020-01-01",
          this.$refs.serachCommission.endDate,
          "",
          "",
          record,
          page
        );
      }
    },
    searchUsers() {
      this.$refs.serachCommission.isSearch = true;
      this.getData(this.records, 1);
    },
    chooseRecord(record) {
      this.records = record;
      this.getData(record, 1);
    },
    thisPage(p) {
      this.getData(this.records, p);
    },
    nextPage() {
      this.getData(this.records, this.current_page + 1);
    },
    prevPage() {
      this.getData(this.records, this.current_page - 1);
    },
    refreshUsers() {
      this.getData(this.records, this.page);
    },
  },
};
</script>

<style scoped>
.user-commission > div:first-of-type {
  margin-top: 50px;
}
.user-commission > div p {
  font-size: 24px;
  font-weight: 600;
}
@media (max-width: 767px) {
  .user-commission > div:first-of-type {
    margin-top: 30px;
  }
  .user-commission > div p {
    font-size: 17px;
    font-weight: 500;
  }
}
</style>
