export default {
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Out"])},
  "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Name"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "savedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved Successfully"])},
  "confirmationWasSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation Was Successful"])},
  "capatcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type The Above Characters"])},
  "incorrectAcount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Account Or Password is incorrect"])},
  "invalidUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid user name . please try again"])},
  "invalidPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect password . please try again"])},
  "invalidChars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect characters , please try again"])},
  "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights reserved to Tops Gulf 2022"])},
  "thisFieldIsMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Field Is Mandatory"])},
  "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
  "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minutes"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])},
  "times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Times"])},
  "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours"])},
  "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
  "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
  "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline"])},
  "linksList": [
    {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Page"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/en"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bi-house"])}
    },
    {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/en/category-management"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bi-grid"])}
    },
    {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad Specification"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/en/specification-management"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bi-card-checklist"])}
    },
    {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ads List"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/en/ad-list"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bi-list-ul"])}
    },
    {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bi-receipt"])}
    },
    {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/en/statistic"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bi-clipboard-data"])}
    },
    {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Setting"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/en/user-setting"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bi-person"])}
    },
    {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Center"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bi-question-octagon"])}
    },
    {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complaining"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/en/complaining"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bi-exclamation-circle"])}
    },
    {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner Setting"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/en/banner-setting"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bi-sliders"])}
    },
    {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Setting"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bi-gear"])}
    },
    {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publisher List"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/en/publisher-list"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bi-person-vcard"])}
    }
  ],
  "systemStat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Statistics"])},
  "pageView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page View"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
  "newAd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Ad"])},
  "totalAd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Ads"])},
  "newAds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Ads"])},
  "revenue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Revenue"])},
  "byTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Transfer"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "byOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Online"])},
  "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M"])},
  "sr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SR"])},
  "k": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K"])},
  "ex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ex: "])},
  "commissionRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission Record"])},
  "underAudit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under Audit"])},
  "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
  "onSaleOnStop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On & Off Ads"])},
  "thisYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Year"])},
  "lastYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Year"])},
  "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Month"])},
  "last3Months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 3 Months"])},
  "thisWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Week"])},
  "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom"])},
  "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On Sale"])},
  "stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On Stop"])},
  "months": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feb"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mar"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apr"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jun"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jul"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aug"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sep"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oct"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nov"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dec"])}
  ],
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
  "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old Password"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
  "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat Password"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "selectEnableStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Enable Status"])},
  "selectSaleStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Status"])},
  "enterPublisherName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter Publisher Name"])},
  "enterUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter User Name"])},
  "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Select Country"])},
  "countryName": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saudi Arabia"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UAE"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuwait"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatar"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahrain"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oman"])}
  ],
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "tablePublish": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publisher ID"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publisher Name"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable Status"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liked / dislike"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products(On & Off Stop)"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last LogIn Time"])}
  ],
  "record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records"])},
  "publishAd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publisher Ads"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "praiseTreat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liked / Dislike"])},
  "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liked"])},
  "treat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dislike"])},
  "title1publish": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad Image"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad Title"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish Time"])}
  ],
  "title2publish": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liked / Dislike"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish Time"])}
  ],
  "tableAdList": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad ID"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad Title"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad Photo"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publisher Name"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommend"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibility"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complains"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish Time"])}
  ],
  "noComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Comments"])},
  "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
  "submitTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Time"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
  "noPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Phone"])},
  "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pictures"])},
  "noPictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Pictures"])},
  "allowComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow Comment"])},
  "listCommission": [
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category Commission"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/en/category-commission-setting"])},
      "table": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad Category ID"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category Name"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission Rate"])}
      ],
      "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Commission = (Transaction Amount - Free Commission Amount )*(Major Category Commission -User Discount)"])},
      "defenitions": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 transaction amount : data inputed in the “commission calculator” in app by app user ."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 free commission amount : data set in the “free commission setting“ in backstage by system admin ."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 major category commission rate: data set in the “category commission setting” in backstage by system admin ."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 user discount rate : data set in “user commission record” in backstage by system admin ."])}
      ],
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage Commission"])},
      "fixedComm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed Commission"])}
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Commission"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/en/free-commission-setting"])},
      "freeCommissionAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Commission Amount"])},
      "freeplaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ex: 30 SA As Commission And Less Will Be Free"])}
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Commission"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/en/user-commission"])},
      "placeholderUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter user ID Or User Name"])},
      "waitReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait Review"])},
      "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
      "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed"])},
      "ignore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignore"])},
      "setDiscountRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Discount Rate"])},
      "enterCorrectRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter Rate Between 0 And 100"])},
      "ThisRateWillBeStartedFromTheNextPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this rate will be started from the next payment"])},
      "table": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User ID"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Name"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount Rate"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission Total Amount"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait Review"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])}
      ]
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission Record"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/en/commission-record"])},
      "viewInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Invoice"])},
      "confirmAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Amount"])},
      "ignore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignore"])},
      "ignored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignored"])},
      "commissionTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission Total (SR)"])},
      "areYouSureToLgnoreThisRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure To Ignore This Record ?"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
      "reasonOpt": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Copy Of The Proof Of Transfer Is Not Clear"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Amount Received Is Not The Required Amount"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Transfer Is Not Directed To Us Or Fake"])}
      ],
      "table": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Name"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voucher"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount Rate"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation Time"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])}
      ]
    }
  ],
  "onlinePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Payment"])},
  "bankTransferred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Transferred"])},
  "statList": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["major category"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total ad numbers"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on sale ad number"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user commission total amount (SR)"])}
  ],
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "addNewUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New User"])},
  "editUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit User"])},
  "tableUserSetting": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User ID"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Name"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["True Name"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created Date"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable Status"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login Time"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])}
  ],
  "ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AR"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN"])},
  "listSystem": [
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Account"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/en/bank-account"])}
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Setting"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/en/other-setting"])}
    }
  ],
  "bankInfo": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Account information"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Name"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Name"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holder Name"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holder Name"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
  ],
  "invoiceInfo": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Invoice Information"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Your Company Name"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Your Email Address"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT Number"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neighborhood"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street Name"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building NO"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post Number"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If You Don't Know It Just Keep 00000"])}
  ],
  "timePeriod": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time / Period"])},
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh Time Period"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this is the time period limit for app user to refresh ad again"])},
      "ex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for example, if set to 30 minutes after user, refreshes ad he / she could only refresh the same ad for the second time after 30 minutes"])}
    },
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Stop Sale” Notification Time"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Is The Time Period That How Long The User Has Not Refreshed Ad"])},
      "ex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for example , if set to 30 days when user has not refreshed ad for 30 days then system will send a notification message to app user automatically"])}
    },
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Stop Sale” Operation After Notification"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this is the time period that how long the user has not refreshed ad after the stop sale notification time"])},
      "ex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for example , if set to 5 days when user has not refreshed ad for 30 days after the stop sale notification time it means that the user has not refreshed ad"])}
    },
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Stop Sale” Operation After Complains"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this is the times of complains of one ad"])},
      "ex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for example , if set to 15 times when the ad receives 15 complains then system wil stop sale the ad and send a notification message to app user automatically"])}
    },
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish New Ad After Every"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hot Search"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press (ctrl + enter) To Add New Model"])}
  ],
  "pleaseEnterModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter New Model"])},
  "emailSetting": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Setting"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing Mail (SMTP) Server"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Port"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMTP Server Prot"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Account"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Name Of The Email Account"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Password"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Of The Email Account"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From Email"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender Email Of The Outgoing Mails"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Sender"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender Name Of The Outgoing Mails"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bcc Email"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blind Carbon Copy (Bcc) Email Address (Optional)"])}
  ],
  "twilioSetting": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twilio Setting"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twilio Account SID"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twilio Account Auth Token"])}
  ],
  "onlinePay": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Payment"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paytabs Profile ID"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paytabs API Key"])}
  ],
  "invoicing": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoicing"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Key"])}
  ],
  "pusherInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pusher Information"])},
  "pusherID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pusher APP ID"])},
  "pusherKEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pusher APP KEY"])},
  "pusherSecret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pusher Secret"])},
  "shareMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share Message"])},
  "shareMessageplatform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share Message Platform"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
  "listHelp": [
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agreements"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/en/protocols-management"])}
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videos"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/en/video-management"])}
    }
  ],
  "PublisherManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publisher List"])},
  "processingDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing Done"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
  "addNewBanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Banner"])},
  "listBannerTable": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image Web"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image App"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])}
  ],
  "bannerApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner App"])},
  "bannerWeb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner Web"])},
  "uploadImg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a Png or Jpg Images , 6MB Max , 300 * 200 Px or more"])},
  "uploadImgWeb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a Png or Jpg Images , 6MB Max , 1800 * 900 Px Or More"])},
  "imageSizeLargerThan6MB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image size is larger than 6MB"])},
  "sizeLongMuch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image size is larger than 4MB"])},
  "imageDimensionsAreSmallerThan300200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image Dimensions Are Smaller Than 300 * 200 px"])},
  "imageDimensionsAreSmallerThan1800900": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image Dimensions Are Smaller Than 1800 * 900 px"])},
  "thisIconLargerThan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon size is larger than 50KB"])},
  "iconDimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon dimensions are larger than 50 * 50 px"])},
  "UploadYourPicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Your Picture"])},
  "editBanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Banner"])},
  "ImageApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image / App"])},
  "ImageWeb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image / Web"])},
  "editImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Image"])},
  "enterSpecificationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter Specification Name"])},
  "addNewSpecification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Specification"])},
  "editSpecification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Specification"])},
  "listSpecificationTable": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable Status"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandatory"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])}
  ],
  "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CheckBox"])},
  "radio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radio Button"])},
  "dropDown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop Down List"])},
  "selfWrite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self Write"])},
  "pdfFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pdf File"])},
  "chooseClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Class"])},
  "relatedClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related Class"])},
  "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])},
  "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible"])},
  "enterCategoryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter Category Name"])},
  "addNewCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Category"])},
  "editCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Category"])},
  "deleteCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Category"])},
  "areYouSureToDeleteThisCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure To Delete This Category?"])},
  "listCategoryTable": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad Category"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category Name"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable Status"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])}
  ],
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "categoriesName": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real Estate"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livestock"])}
  ],
  "categoryTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category Title"])},
  "uploadPng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload A Png Or Svg Icon , 50 KB Max , 50 * 50 Px"])},
  "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon"])},
  "uploadYourIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Your Icon"])},
  "editIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Icon"])},
  "addMinCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Min Category"])},
  "addFinalCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Final Category"])},
  "addNewVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Video"])},
  "editVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Video"])},
  "videoTable": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Title"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Link"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])}
  ],
  "coverPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cover Photo"])},
  "uploadPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upload the photo as png, jpeg file, 4MB max, 300 * 200 px or more"])},
  "pleaseUploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Upload Images"])},
  "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose File"])},
  "pleaseUploadIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Upload Icon"])},
  "pleaseUploadCoverPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Upload The Cover Photo"])},
  "addedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added Successfully"])},
  "editedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edited Successfully"])},
  "removedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removed Successfully"])},
  "pleaseChooseClasses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Choose Classes"])},
  "areYouSuredeleteVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure You Want To Delete This Video ?"])},
  "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
  "useOfTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use Of Terms"])},
  "returnPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Policy"])},
  "aboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
  "pleaseSelectPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Select Permissions"])},
  "oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops..."])},
  "youtubeLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youtube Link"])},
  "noLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Link"])}
}