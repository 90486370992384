<template>
  <div
    class="modal fade"
    :id="ident"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    ref="myModal"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      :style="{ 'max-width': width }"
    >
      <div class="modal-content popup-box">
        <!-- Header -->
        <div v-if="title" class="modal-header justify-content-center">
          <h1 class="modal-title text-center" id="staticBackdropLabel">
            {{ title }}
          </h1>
        </div>
        <!-- Body -->
        <form class="row g-sm-4 g-2" @submit.prevent="send">
          <slot />
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-blue"
              data-bs-dismiss="modal"
              ref="closeModalButton"
            >
              {{ $t("cancel") }}
            </button>
            <button type="submit" class="btn btn-orange">
              {{ $t("confirm") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "ModalPopup",
  props: ["ident", "title", "width"],

  methods: {
    send() {
      this.$emit("send");
    },
  },
  mounted() {
    this.$refs.myModal.addEventListener("hide.bs.modal", () => {
      this.$emit("cancelModal");
    });
  },
  setup(_, { expose }) {
    const closeModalButton = ref(null);

    expose({ closeModalButton });

    return {
      closeModalButton,
    };
  },
};
</script>

<style scoped>
.modal form.row {
  margin-left: 0;
  margin-right: 0;
}
.modal-title {
  font-size: 24px;
  margin-bottom: 40px;
}
@media (max-width: 575px) {
  .modal-title {
    font-size: 18px;
    margin-bottom: 20px;
  }
}
</style>
