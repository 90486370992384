<template>
  <form
    class="d-flex flex-column flex-md-row flex-wrap gap-4"
    @submit.prevent="$emit('search', [userName, status])"
  >
    <div class="search-complaining">
      <input
        type="text"
        class="form-control input-text bg-transparent font-poppins"
        v-model="userName"
        :placeholder="$t('enterUserName')"
      />
    </div>
    <div class="dropdown custom-select">
      <button
        class="dropdown-toggle d-flex align-items-center justify-content-between"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span>{{ type }}</span>
        <i class="bi bi-chevron-down"></i>
      </button>
      <ul class="dropdown-menu">
        <li>
          <button
            type="button"
            class="dropdown-item type"
            :class="{ rtl: $i18n.locale === 'ar' }"
            data-status=""
            @click="choose($event)"
          >
            {{ $t("all") }}
          </button>
        </li>
        <li>
          <button
            type="button"
            class="dropdown-item type active"
            :class="{ rtl: $i18n.locale === 'ar' }"
            data-status="pending"
            @click="choose($event)"
          >
            {{ $t(`new`) }}
          </button>
        </li>
        <li>
          <button
            type="button"
            class="dropdown-item type"
            :class="{ rtl: $i18n.locale === 'ar' }"
            data-status="completed"
            @click="choose($event)"
          >
            {{ $t(`processingDone`) }}
          </button>
        </li>
      </ul>
    </div>
    <input type="submit" class="btn btn-orange" :value="$t('search')" />
  </form>
</template>

<script>
export default {
  name: "SearchingComplaining",
  data() {
    return {
      type: this.$t("new"),
      userName: "",
      status: "pending",
    };
  },
  watch: {
    "$i18n.locale"() {
      this.type = this.$t("new");
    },
  },
  methods: {
    choose($event) {
      const items = document.querySelectorAll(".type");
      items.forEach((e) => {
        e.classList.remove("active");
      });
      this.type = $event.target.textContent;
      $event.target.classList.add("active");
      this.status = $event.target.dataset.status;
    },
  },
};
</script>

<style scoped>
.search-complaining input {
  width: 330px;
}
@media (max-width: 767px) {
  .search-complaining input {
    width: 100%;
  }
}
.custom-select {
  width: 222px;
}
.custom-select ul button {
  padding: 15px;
}
.custom-select ul li:not(:last-of-type) button {
  border-bottom: 1px solid var(--border);
}
.custom-select ul button.active,
.custom-select ul button:hover {
  background-color: var(--border);
}
</style>
