<template>
  <div class="custom-box">
    <h4 v-text="$t('revenue')"></h4>
    <div class="our-grid box">
      <div class="rad-10" :class="flex">
        <p>
          <span>{{ $t("byTransfer") }} : </span>
          <span>{{ numberConversion(revenue.revenue_transfer_record) }}</span>
        </p>
        <p>
          <span>{{ $t("amount") }} : </span>
          <span
            >{{ numberConversion(revenue.revenue_transfer_amount) }}
            {{ $t("sr") }}</span
          >
        </p>
      </div>
      <div class="rad-10" :class="flex">
        <p>
          <span>{{ $t("byOnline") }} : </span>
          <span>{{ numberConversion(revenue.revenue_online_record) }}</span>
        </p>
        <p>
          <span>{{ $t("amount") }} : </span>
          <span
            >{{ numberConversion(revenue.revenue_online_amount) }}
            {{ $t("sr") }}</span
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TotalRevenue",
  props: ["revenue"],
  data() {
    return {
      flex: "d-flex justify-content-between align-items-center flex-wrap flex-md-nowrap",
    };
  },
  methods: {
    numberConversion(number) {
      let newNumber = 0;
      if (number >= 1000000) {
        newNumber = number / 1000000 + " " + this.$t("m");
      } else if (number >= 1000) {
        newNumber = number / 1000 + " " + this.$t("k");
      } else {
        newNumber = number;
      }
      return newNumber;
    },
  },
};
</script>

<style scoped>
.box > div:first-child {
  background-color: rgba(253, 248, 247, 1);
}
.box > div:last-child {
  background-color: rgba(237, 254, 251, 1);
}
</style>
