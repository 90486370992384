import { createRouter, createWebHistory } from "vue-router";
import i18n from "../i18n";
import DashboardPage from "@/views/DashboardPage";
import LoginPage from "@/views/LoginPage";
import HomePage from "@/views/HomePage";
import InfoPage from "@/components/HomePage/info/InfoPage";
import ChangePassword from "@/components/HomePage/password/ChangePassword";
import CategoryPage from "@/views/CategoryPage";
import AdSpecification from "@/views/AdSpecification";
import AdList from "@/views/AdList";
import CategoryCommission from "@/views/comminsion/CategoryCommission";
import FreeCommission from "@/views/comminsion/FreeCommission";
import UserCommission from "@/views/comminsion/UserCommission";
import CommissionRecord from "@/views/comminsion/CommissionRecord";
import StatisticsAnalysis from "@/views/StatisticsAnalysis";
import UserSetting from "@/views/UserSetting";
import AgreementsManag from "@/views/helpCenter/AgreementsManag";
import VideoManagment from "@/views//helpCenter/VideoManagment";
import ComplainingPage from "@/views/ComplainingPage";
import BannerSetting from "@/views/BannerSetting";
import BankAccount from "@/views/systemSetting/BankAccount";
import OtherSetting from "@/views/systemSetting/OtherSetting";
import PublisherPage from "@/views/PublisherPage";
import ErrorPage from "@/components/global/ErrorPage";
import { RouterView } from "vue-router";

const routes = [
  // Redirect Page
  {
    path: "/",
    redirect: `/${i18n.global.locale}`,
  },
  {
    path: "/:lang(en|ar)?",
    caseSensitive: true,
    component: RouterView,
    children: [
      {
        path: "",
        name: "home",
        component: DashboardPage,
        meta: { requiresAuth: true },
        children: [
          {
            path: "/:lang(en|ar)?",
            component: HomePage,
            children: [
              {
                path: "",
                component: InfoPage,
              },
              {
                path: "change-password",
                component: ChangePassword,
              },
            ],
          },
          {
            path: "category-management",
            component: CategoryPage,
          },
          {
            path: "specification-management",
            component: AdSpecification,
          },
          {
            path: "ad-list",
            component: AdList,
          },
          {
            path: "category-commission-setting",
            component: CategoryCommission,
          },
          {
            path: "free-commission-setting",
            component: FreeCommission,
          },
          {
            path: "user-commission",
            component: UserCommission,
          },
          {
            path: "commission-record",
            component: CommissionRecord,
          },
          {
            path: "statistic",
            component: StatisticsAnalysis,
          },
          {
            path: "user-setting",
            component: UserSetting,
          },
          {
            path: "protocols-management",
            component: AgreementsManag,
          },
          {
            path: "video-management",
            component: VideoManagment,
          },
          {
            path: "complaining",
            component: ComplainingPage,
          },
          {
            path: "banner-setting",
            component: BannerSetting,
          },
          {
            path: "bank-account",
            component: BankAccount,
          },
          {
            path: "other-setting",
            component: OtherSetting,
          },
          {
            path: "publisher-list",
            component: PublisherPage,
          },
        ],
      },
      {
        path: "login",
        name: "login",
        component: LoginPage,
        meta: { requiresGuest: true },
      },
    ],
  },
  // 404 Page Not Found
  {
    path: "/:pathMath(.*)*",
    component: <ErrorPage />,
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(),
  routes,
});

router.beforeEach((to) => {
  const auth_token = localStorage.getItem("topz_admin_token");

  if (to.meta.requiresGuest && auth_token) {
    return {
      path: "/",
    };
  } else if (to.meta.requiresAuth && !auth_token) {
    return {
      path: `/${i18n.global.locale}/login`,
    };
  }
});

export default router;
