<template>
  <div class="box-info">
    <h5 class="sub-title">{{ $t("invoicing[0]") }}</h5>
    <div>
      <label for="key" class="form-label">{{ $t("invoicing[1]") }}</label>
      <input
        type="text"
        class="form-control input-text"
        id="key"
        v-model="api"
        autocomplete="off"
      />
    </div>
  </div>
</template>

<script>
import { useOtherSettingsInfo } from "@/stores/OtherSettingsInfo";
import { mapState } from "pinia";

export default {
  name: "InVoicing",
  data() {
    return {
      api: "",
    };
  },
  computed: {
    ...mapState(useOtherSettingsInfo, ["apiKey"]),
  },
  mounted() {
    if (this.apiKey) {
      setTimeout(() => {
        this.api = this.apiKey;
      }, 1500);
    }
  },
};
</script>
