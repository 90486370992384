<template>
  <div class="categories">
    <h4 class="title">{{ $t("linksList[1].title") }}</h4>
    <TableCategories />
  </div>
</template>

<script>
import TableCategories from "@/components/categories/TableCategories.vue";

export default {
  name: "CategoryPage",
  components: {
    TableCategories,
  },
};
</script>

<style scoped></style>
