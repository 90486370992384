<template>
  <div class="box-info">
    <h5 class="sub-title">{{ $t("pusherInfo") }}</h5>
    <div>
      <label for="id" class="form-label">{{ $t("pusherID") }}</label>
      <input
        type="text"
        class="form-control input-text"
        id="id"
        v-model="id"
        autocomplete="off"
      />
    </div>
    <div>
      <label for="key" class="form-label">{{ $t("pusherKEY") }}</label>
      <input
        type="text"
        class="form-control input-text"
        id="key"
        v-model="key"
        autocomplete="off"
      />
    </div>
    <div>
      <label for="secret" class="form-label">{{ $t("pusherSecret") }}</label>
      <input
        type="text"
        class="form-control input-text"
        id="secret"
        v-model="secret"
        autocomplete="off"
      />
    </div>
  </div>
</template>

<script>
import { useOtherSettingsInfo } from "@/stores/OtherSettingsInfo";
import { mapState } from "pinia";
export default {
  name: "pusherInfo",
  data() {
    return {
      id: "",
      key: "",
      secret: "",
    };
  },
  computed: {
    ...mapState(useOtherSettingsInfo, ["publisher"]),
  },
  mounted() {
    if (this.publisher) {
      setTimeout(() => {
        this.id = this.publisher[0].value;
        this.key = this.publisher[1].value;
        this.secret = this.publisher[2].value;
      }, 1500);
    }
  },
};
</script>

<style scoped>
label {
  font-family: "Poppins", sans-serif;
}
</style>
