<template>
  <div class="notes rad-10" :class="{ rtl: $i18n.locale == 'ar' }">
    <QuillEditor
      ref="myEditor"
      :options="options"
      :style="{
        'font-family': font,
      }"
      @update:content="change"
    />
  </div>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
  name: "NotePad",
  props: ["placeholder", "align", "font", "content"],
  components: { QuillEditor },
  data() {
    return {
      options: {
        modules: {
          toolbar: [
            { size: ["small", false, "large", "huge"] },
            { header: [1, 2, 3, 4, 5, 6, false] },
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
            { color: [] },
            // { background: [] },
            "link",
            "image",
            "bold",
            "italic",
            "underline",
            "strike",
          ],
        },
        placeholder: this.placeholder,
        readOnly: false,
        theme: "snow",
      },
      text: "",
    };
  },
  methods: {
    change() {
      this.text = this.$refs.myEditor.getHTML();
    },
  },
  updated() {
    this.$refs.myEditor.setHTML(this.$props.content);
  },
  expose: ["text"],
};
</script>

<style>
.notes {
  border: 1px solid var(--border);
  background: var(--white-color);
  margin-right: 65px;
}
.notes.rtl {
  margin-left: 65px;
  margin-right: 0;
  direction: ltr;
}
@media (max-width: 991px) {
  .notes {
    margin-right: 0;
  }
  .notes.rtl {
    margin-left: 0;
  }
}
.ql-toolbar.ql-snow {
  border: none;
  border-bottom: 1px solid var(--border);
}
.ql-toolbar.ql-snow .ql-formats {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.ql-editor {
  height: 700px;
  font-size: 18px;
}
.ql-container.ql-snow {
  border: none;
  margin: 20px 15px;
}
@media (max-width: 767px) {
  .ql-editor {
    font-size: 12px;
    padding: 5px;
    height: 500px;
  }
  .ql-editor p {
    font-size: 12px;
  }
  .ql-container.ql-snow {
    margin: 15px 10px;
  }
}
</style>
