<template>
  <div class="modal-body">
    <!-- Spec Name -->
    <div class="d-flex gap-sm-4 gap-2 flex-column flex-md-row">
      <div>
        <label for="nameEn" class="form-label">{{
          $t("listSpecificationTable[0]") + "(" + $t("en") + ")"
        }}</label>
        <input
          type="text"
          class="form-control input-text font-poppins"
          id="nameEn"
          v-model="nameEn"
          dir="auto"
          required
        />
      </div>
      <div>
        <label for="nameAr" class="form-label">{{
          $t("listSpecificationTable[0]") + "(" + $t("ar") + ")"
        }}</label>
        <input
          type="text"
          class="form-control input-text"
          id="nameAr"
          dir="auto"
          v-model="nameAr"
          required
        />
      </div>
    </div>

    <!-- Spec Description -->
    <div
      v-if="type == 'self_write'"
      class="description d-flex gap-sm-4 gap-2 flex-column flex-md-row"
    >
      <div>
        <label for="descEn" class="form-label">{{
          $t("listSpecificationTable[1]") + "(" + $t("en") + ")"
        }}</label>
        <textarea
          class="form-control input-text font-poppins"
          id="descEn"
          v-model="descEn"
          dir="auto"
          required
        ></textarea>
      </div>
      <div>
        <label for="descAr" class="form-label">{{
          $t("listSpecificationTable[1]") + "(" + $t("ar") + ")"
        }}</label>
        <textarea
          class="form-control input-text"
          id="descAr"
          dir="auto"
          v-model="descAr"
          required
        ></textarea>
      </div>
    </div>

    <!-- Spec Types -->
    <div class="types">
      <label class="form-label">{{ $t("listSpecificationTable[2]") }}</label>
      <div class="d-flex gap-sm-4 gap-3 flex-wrap flex-md-nowrap mt-sm-3 mt-2">
        <label class="d-flex align-items-center gap-2">
          <input type="radio" value="checkbox" v-model="type" />
          {{ $t("check") }}
        </label>
        <label class="d-flex align-items-center gap-2">
          <input type="radio" value="radio_button" v-model="type" />
          {{ $t("radio") }}
        </label>
        <label class="d-flex align-items-center gap-2">
          <input type="radio" value="dropdown_list" v-model="type" />
          {{ $t("dropDown") }}
        </label>
        <label class="d-flex align-items-center gap-2">
          <input type="radio" value="self_write" v-model="type" />
          {{ $t("selfWrite") }}
        </label>
        <label class="d-flex align-items-center gap-2">
          <input type="radio" value="pdf_file" v-model="type" />
          {{ $t("pdfFile") }}
        </label>
      </div>
    </div>

    <!-- Default -->
    <div
      v-if="type != 'self_write' && type != 'pdf_file'"
      class="def d-flex gap-sm-4 gap-2 flex-column flex-md-row"
    >
      <div>
        <label for="defEn" class="form-label">{{
          $t("listSpecificationTable[5]") + "(" + $t("en") + ")"
        }}</label>
        <textarea
          class="form-control input-text font-poppins"
          id="defEn"
          v-model="defEn"
          dir="auto"
          required
        ></textarea>
      </div>
      <div>
        <label for="defAr" class="form-label">{{
          $t("listSpecificationTable[5]") + "(" + $t("ar") + ")"
        }}</label>
        <textarea
          class="form-control input-text"
          id="defAr"
          dir="auto"
          v-model="defAr"
          required
        ></textarea>
      </div>
    </div>

    <div class="choose">
      <button
        type="button"
        class="btn btn-blue"
        data-bs-toggle="modal"
        :data-bs-target="id"
        @click="chooseClass"
      >
        {{ $t("chooseClass") }}
      </button>
    </div>

    <div>
      <label class="toggle mb-4 mt-4">
        <span>{{ $t("required") }}</span>
        <input
          type="checkbox"
          class="custom-toggle"
          :class="{ rtl: $i18n.locale == 'ar' }"
          v-model="required"
        />
      </label>
      <label class="toggle mb-3">
        <span>{{ $t("visible") }}</span>
        <input
          type="checkbox"
          class="custom-toggle"
          :class="{ rtl: $i18n.locale == 'ar' }"
          v-model="visible"
        />
      </label>
    </div>
  </div>
</template>

<script>
import saveInfoAlert from "@/assets/js/saveInfoAlert";
import axios from "axios";

// import { mapActions, mapState } from "pinia";
// import { useSpecClasses } from "@/stores/SpecClasses";

export default {
  name: "AddSpecification",
  props: ["id", "idSpec"],
  data() {
    return {
      nameEn: "",
      nameAr: "",
      descEn: "",
      descAr: "",
      defEn: "",
      defAr: "",
      type: "checkbox",
      required: true,
      visible: true,
    };
  },
  computed: {
    // ...mapState(useFetchAll, ["specifications"]),
    // ...mapState(useSpecClasses, ["ids"]),
  },
  methods: {
    // ...mapActions(useFetchAll, ["fechSpecifications"]),
    // ...mapActions(useSpecClasses, ["reset"]),
    async chooseClass() {
      this.$emit("chooseClass");
      if (this.id == "#editChooseClass") {
        const items = document.querySelectorAll(".edit-table-classes input");
        await axios
          .get("dashboard/specifications/" + this.idSpec)
          .then((res) => {
            res.data.data.categories.forEach((el) => {
              Array.from(items).forEach((e) => {
                if (el.id == e.id) {
                  e.checked = true;
                }
              });
            });
          })
          .catch((err) => {
            saveInfoAlert(
              this.$i18n.locale,
              "error",
              err.response.data.message
            );
          });
        // this.itemSpec.categories.forEach((el) => {
        //   items.forEach((e) => {
        //     if (el.id == e.id) {
        //       e.checked = true;
        //     }
        //   });
        // });
      }
    },
  },
};
</script>

<style scoped>
.modal-body > div:not(.types) > div {
  width: 50%;
}
@media (max-width: 767px) {
  .modal-body > div:not(.types) > div {
    width: 100%;
  }
}
.modal .modal-body > div.description textarea {
  height: 100px;
}
.modal .modal-body > div.def textarea {
  height: 140px;
}
.modal .modal-body .types div label {
  font-size: 14px;
  cursor: pointer;
  width: fit-content;
}
.modal .modal-body .types label input[type="radio"] {
  accent-color: #e0760f;
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin: 0;
}
@media (max-width: 575px) {
  .modal .modal-body .types div label {
    font-size: 11px;
  }
  .modal .modal-body .types label input[type="radio"] {
    width: 18px;
    height: 18px;
  }
}

.modal .modal-body > div.choose button {
  padding: 8px 20px;
}

/** Custom Toggle**/
.modal .modal-body label.toggle {
  display: flex;
  gap: 45px;
  font-size: 18px;
  cursor: pointer;
}
.modal .modal-body label.toggle span {
  width: 80px;
}
.modal .modal-body input.custom-toggle::before {
  width: 37px;
  height: 22px;
}
.modal .modal-body input.custom-toggle::after {
  width: 18px;
  height: 18px;
  left: -17px;
}
.modal .modal-body input.custom-toggle:checked::after {
  left: -1px;
}
</style>
