<template>
  <form
    class="searching-user d-flex flex-column flex-md-row flex-wrap gap-4"
    @submit.prevent="$emit('search', [name, status])"
  >
    <!-- Name Input -->
    <div class="search-user">
      <input
        type="text"
        class="form-control input-text bg-transparent"
        v-model="name"
        :placeholder="placeholder"
      />
    </div>

    <!-- Status -->
    <div class="dropdown custom-select">
      <button
        class="dropdown-toggle d-flex align-items-center justify-content-between"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span v-if="type.length === 0">{{ $t("selectEnableStatus") }}</span>
        <span v-if="type.length !== 0">{{ type }}</span>
        <i class="bi bi-chevron-down"></i>
      </button>
      <ul class="dropdown-menu">
        <li>
          <button
            class="dropdown-item disabled"
            :class="{ rtl: $i18n.locale === 'ar' }"
          >
            {{ $t("selectEnableStatus") }}
          </button>
        </li>
        <li>
          <button
            type="button"
            class="state dropdown-item"
            :class="{ rtl: $i18n.locale === 'ar' }"
            data-status="1"
            @click="choose"
          >
            {{ $t("yes") }}
          </button>
        </li>
        <li>
          <button
            type="button"
            class="state dropdown-item"
            :class="{ rtl: $i18n.locale === 'ar' }"
            data-status="0"
            @click="choose"
          >
            {{ $t("no") }}
          </button>
        </li>
      </ul>
    </div>

    <!-- Search Button -->
    <button type="submit" class="btn btn-orange">{{ $t("search") }}</button>

    <!-- Refresh Searching -->
    <button type="button" @click="refreshSearching">
      <img src="@/assets/images/refresh-search.png" alt="" />
    </button>
  </form>
</template>

<script>
import chooseItem from "@/assets/js/chooseItem";

export default {
  name: "SearchingUser",
  props: ["placeholder"],
  data() {
    return {
      name: "",
      type: "",
      status: "",
    };
  },
  watch: {
    "$i18n.locale"() {
      this.name = "";
      this.type = "";
      this.status = "";
    },
  },
  methods: {
    choose($event) {
      chooseItem(".state", $event);
      this.type = $event.target.textContent;
      this.status = $event.target.dataset.status;
    },
    refreshSearching() {
      this.name = "";
      this.type = "";
      this.status = "";
      this.$emit("refresh");
      const status = document.querySelectorAll(".searching-user .state");
      status.forEach((state) => {
        state.classList.remove("active");
      });
    },
  },
};
</script>

<style scoped>
.search-user input {
  width: 330px;
}
@media (max-width: 767px) {
  .search-user input {
    width: 100%;
  }
}
.custom-select > button {
  color: var(--black-alt);
}
.custom-select ul {
  background-color: var(--white-color);
  border-radius: 5px;
}
.custom-select ul button.disabled {
  font-size: 12px;
}

form > button:first-of-type {
  width: 130px;
}
form > button:last-of-type {
  background-color: transparent;
}
form > button:last-of-type img {
  width: 45px;
}
@media (max-width: 767px) {
  form > button:first-of-type {
    width: 100%;
  }
}
</style>
