<template>
  <div class="invoice-info box-info">
    <h5 class="sub-title">{{ $t("invoiceInfo[0]") }}</h5>
    <!-- Company Name -->
    <div>
      <label for="company" class="form-label">{{ $t("invoiceInfo[1]") }}</label>
      <input
        type="text"
        class="form-control input-text"
        :placeholder="$t('invoiceInfo[2]')"
        id="company"
        v-model="companyName"
      />
    </div>

    <!-- Email Address -->
    <div>
      <label for="email" class="form-label">{{ $t("invoiceInfo[3]") }}</label>
      <input
        type="email"
        class="form-control input-text"
        :placeholder="$t('invoiceInfo[4]')"
        id="email"
        v-model="email"
      />
    </div>

    <!-- Phone Number -->
    <div>
      <label for="phone" class="form-label">{{ $t("phone") }}</label>
      <div class="phone position-relative">
        <div class="dropdown">
          <button
            class="dropdown-toggle d-flex align-items-center gap-1"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span>{{ phoneKey }}</span>
            <i class="bi bi-caret-down-fill fs-6"></i>
          </button>
          <ul class="dropdown-menu">
            <li v-for="key in keys" :key="key">
              <button
                class="dropdown-item"
                type="button"
                @click="choosePhoneKey"
              >
                +{{ key }}
              </button>
            </li>
          </ul>
        </div>
        <input
          type="text"
          class="form-control input-text"
          id="phone"
          v-model="phone"
          @keypress="onlyNumber"
        />
      </div>
    </div>

    <!-- Vat Number -->
    <div>
      <label for="vat" class="form-label">{{ $t("invoiceInfo[5]") }}</label>
      <input
        type="text"
        class="form-control input-text"
        id="vat"
        v-model="vat"
      />
    </div>

    <!-- country -->
    <div>
      <label for="country" class="form-label">{{ $t("invoiceInfo[6]") }}</label>
      <input
        type="text"
        class="form-control input-text"
        id="country"
        v-model="country"
      />
    </div>

    <!-- city -->
    <div>
      <label for="city" class="form-label">{{ $t("invoiceInfo[7]") }}</label>
      <input
        type="text"
        class="form-control input-text"
        id="city"
        v-model="city"
      />
    </div>

    <!-- neighborhood -->
    <div>
      <label for="neighborhood" class="form-label">{{
        $t("invoiceInfo[8]")
      }}</label>
      <input
        type="text"
        class="form-control input-text"
        id="neighborhood"
        v-model="neighborhood"
      />
    </div>

    <!-- street -->
    <div>
      <label for="street" class="form-label">{{ $t("invoiceInfo[9]") }}</label>
      <input
        type="text"
        class="form-control input-text"
        id="street"
        v-model="street"
      />
    </div>

    <!-- buildingNo -->
    <div>
      <label for="buildingNo" class="form-label">{{
        $t("invoiceInfo[10]")
      }}</label>
      <input
        type="text"
        class="form-control input-text"
        id="buildingNo"
        v-model="buildingNo"
      />
    </div>

    <!-- Post Number -->
    <div>
      <label for="post" class="form-label">{{ $t("invoiceInfo[11]") }}</label>
      <input
        type="text"
        class="form-control input-text"
        :placeholder="$t('invoiceInfo[12]')"
        id="post"
        v-model="post"
        @keypress="onlyNumber"
        required
      />
    </div>

    <button type="submit" class="btn btn-orange mt-sm-5 mb-sm-5 mb-4 mt-4">
      {{ $t("save") }}
    </button>
  </div>
</template>

<script>
import onlyNumber from "@/assets/js/onlyNumber";
import saveInfoAlert from "@/assets/js/saveInfoAlert";
import axios from "axios";

export default {
  name: "InvoiceInfo",
  data() {
    return {
      values: [],
      keys: ["966", "971", "965", "974", "973", "968"],
      companyName: "",
      email: "",
      phoneKey: "+966",
      phone: "",
      vat: "",
      country: "",
      city: "",
      neighborhood: "",
      street: "",
      buildingNo: "",
      post: "",
    };
  },
  methods: {
    onlyNumber,
    choosePhoneKey($event) {
      this.phoneKey = $event.target.textContent;
    },
  },
  async mounted() {
    await axios
      .get("dashboard/bank_settings")
      .then((res) => {
        this.values = res.data.data;
        this.companyName = this.values[6].value;
        this.email = this.values[7].value;
        this.phoneKey = this.values[8].value.slice(0, 4);
        this.phone = this.values[8].value.slice(4);
        this.vat = this.values[9].value;
        this.country = this.values[10].value;
        this.city = this.values[11].value;
        this.neighborhood = this.values[12].value;
        this.street = this.values[13].value;
        this.buildingNo = this.values[14].value;
        this.post = this.values[15].value;
      })
      .catch((err) => {
        saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
      });
  },
};
</script>

<style scoped>
.invoice-info input {
  background-color: transparent;
  font-family: "Poppins", sans-serif;
}
/** Phone Number **/
.invoice-info > div .phone {
  direction: ltr;
  font-family: "Poppins", sans-serif;
}
.invoice-info > div .phone div {
  position: absolute;
  width: 55px;
  top: 52%;
  transform: translateY(-50%);
}
.invoice-info > div .phone button {
  width: 100%;
  color: var(--black-alt);
  font-size: 14px;
  font-weight: 500;
  background-color: transparent;
  padding: 5px 10px;
}
.invoice-info > div .phone ul {
  min-width: 100%;
  border-radius: 0px;
  overflow: auto;
}
.invoice-info > div .phone input {
  padding-left: 80px;
}

@media (max-width: 575px) {
  .invoice-info > div .phone button,
  .invoice-info > div .phone button i {
    font-size: 12px !important;
  }
  .invoice-info > div .phone input {
    padding-left: 65px;
  }
}
/** Phone Number **/

.invoice-info > button[type="submit"] {
  margin-left: auto;
  width: 230px;
}
@media (max-width: 575px) {
  .invoice-info > button[type="submit"] {
    width: 100%;
  }
}
</style>
