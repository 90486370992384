<template>
  <div class="modal-body">
    <p>{{ $t("listCommission[3].areYouSureToLgnoreThisRecord") }}</p>
    <div>
      <label>{{ $t("listCommission[3].reason") }}</label>
      <div class="dropdown custom-select w-100">
        <button
          class="dropdown-toggle d-flex align-items-center justify-content-between w-100"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span class="text-capitalize">{{ reason }}</span>
          <i class="bi bi-chevron-down"></i>
        </button>
        <ul class="dropdown-menu">
          <li v-for="reason in reasons" :key="reason">
            <button
              type="button"
              class="dropdown-item reason text-capitalize"
              :class="{ rtl: $i18n.locale === 'ar' }"
              @click="
                chooseType(
                  $i18n.locale == 'en' ? reason.reason_en : reason.reason_ar,
                  reason.id
                )
              "
            >
              <span v-if="$i18n.locale == 'en'">{{ reason.reason_en }}</span>
              <span v-else>{{ reason.reason_ar }}</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import saveInfoAlert from "@/assets/js/saveInfoAlert";
import axios from "axios";

export default {
  name: "IgnoreRecord",
  data() {
    return {
      reasons: [],
      reason: "",
      reasonId: "",
    };
  },
  methods: {
    chooseType(reason, id) {
      this.reason = reason;
      this.reasonId = id;
    },
    async getReasons() {
      await axios
        .get("dashboard/home/lookup")
        .then((res) => {
          this.reasons = res.data.data.commission_ignore_reasons;
          this.reason =
            this.$i18n.locale == "en"
              ? this.reasons[0].reason_en
              : this.reasons[0].reason_ar;
          this.reasonId = this.reasons[0].id;
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        });
    },
  },
  watch: {
    "$i18n.locale"() {
      this.getReasons();
    },
  },
  mounted() {
    this.getReasons();
  },
};
</script>

<style scoped>
p {
  font-size: 20px;
  font-weight: 500;
  color: rgba(247, 61, 23, 1);
}
.custom-select > button {
  white-space: normal;
}
.custom-select ul button {
  padding: 15px;
  white-space: normal;
}
.custom-select ul button.active,
.custom-select ul button:hover {
  background-color: var(--border);
}
@media (max-width: 767px) {
  p {
    font-size: 15px;
  }
  .custom-select ul button {
    padding: 10px;
  }
  .custom-select > button span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
