<template>
  <div class="user-setting">
    <h4 class="title">{{ $t("linksList[6].title") }}</h4>
    <TableUser />
  </div>
</template>

<script>
import TableUser from "@/components/userSetting/TableUser.vue";
export default {
  name: "UserSetting",
  components: {
    TableUser,
  },
};
</script>
