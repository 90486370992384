<template>
  <div class="error">
    <h1>Error Page</h1>
  </div>
</template>

<script>
export default {
  name: "ErrorPage",
  data() {
    return {};
  },
};
</script>
