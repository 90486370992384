<template>
  <div class="time-block" :class="{ rtl: $i18n.locale === 'ar' }">
    <label :for="label.slice(15, -1)" class="form-label">{{ label }}</label>
    <div class="position-relative" :mark="mark" :class="className">
      <input
        v-if="placeholder"
        type="text"
        class="form-control input-text"
        :placeholder="`${$t('ex')} ${placeholder}`"
        :id="label.slice(15, -1)"
        :value="modelValue"
        @input="getValue"
        @keypress="onlyNumber"
      />
    </div>
    <p v-if="desc" class="desc">
      {{ desc }}
    </p>
    <p v-if="ex" class="ex">
      {{ ex }}
    </p>
  </div>
</template>

<script>
import onlyNumber from "@/assets/js/onlyNumber";
export default {
  name: "TimePeriod",
  props: [
    "label",
    "desc",
    "ex",
    "placeholder",
    "mark",
    "className",
    "val",
    "modelValue",
  ],
  emits: ["update:modelValue"],
  data() {
    return {
      time: "",
    };
  },
  methods: {
    onlyNumber,
    getValue($event) {
      this.$emit("update:modelValue", $event.target.value);
    },
  },
};
</script>

<style scoped>
@import "@/assets/css/systemSetting/timeBlock.css";
</style>
