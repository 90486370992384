<template>
  <div class="side-bar" id="sidebar" :class="{ rtl: $i18n.locale == 'ar' }">
    <div class="links-list">
      <div class="logo d-flex align-items-center justify-content-between">
        <img src="@/assets/images/logo.png" alt="logo" />
        <button
          type="button"
          class="btn-close d-block d-lg-none"
          aria-label="Close"
          @click="closeSidebar"
        ></button>
      </div>
      <nav class="pt-5">
        <CustomLink />
      </nav>
    </div>
    <!-- <div ref="overlay" class="overlay" @click="closeSidebar"></div> -->
  </div>
</template>

<script>
import CustomLink from "./CustomLink.vue";
export default {
  name: "SideBar",
  components: {
    CustomLink,
  },
  methods: {
    closeSidebar() {
      document.getElementById("sidebar").classList.remove("show");
    },
  },
};
</script>

<style scoped>
.side-bar {
  width: 300px;
  flex-shrink: 0;
}
.links-list {
  min-height: 100%;
  padding: 30px 15px;
  background-color: #fff;
  transition: var(--transition);
}
.links-list .logo .btn-close:focus {
  box-shadow: none;
}

@media (max-width: 991px) {
  .side-bar {
    position: fixed;
    height: 100vh;
    overflow: auto;
    left: -310px;
    width: 280px;
    z-index: 15;
    transition: var(--transition);
  }
  .side-bar.rtl {
    left: auto;
    right: -310px;
  }
  .side-bar.show {
    left: 0;
  }
  .side-bar.rtl.show {
    left: auto;
    right: 0;
  }
  .links-list .logo img {
    width: 120px;
  }
  /** Overlay **/
  /* .side-bar .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgb(0 0 0 / 50%);
    opacity: 0;
    z-index: -1;
    transition: 0.2s;
  }
  .side-bar.show .overlay {
    opacity: 1;
    width: 100%;
  } */
}
</style>
