import { defineStore } from "pinia";
import { ref } from "vue";

export const useSpecClasses = defineStore("spec", () => {
  const ids = ref([]);

  function addIds(classes) {
    ids.value = Array.from(classes);
  }

  function reset() {
    ids.value = [];
  }

  return { ids, addIds, reset };
});
