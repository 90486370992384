<template>
  <div class="box-info">
    <h5 class="sub-title">{{ $t("shareMessage") }}</h5>
    <div>
      <label for="share_ar" class="form-label"
        >{{ $t("shareMessage") }}
        <span>({{ $t("ar") }})</span>
      </label>
      <input
        type="text"
        class="form-control input-text"
        id="share_ar"
        v-model="share_msg_ar"
        dir="auto"
      />
    </div>
    <div>
      <label for="share_en" class="form-label"
        >{{ $t("shareMessage") }}
        <span>({{ $t("en") }})</span>
      </label>
      <input
        type="text"
        class="form-control input-text"
        id="share_en"
        v-model="share_msg_en"
        dir="auto"
      />
    </div>
    <div>
      <label for="share_platfrom" class="form-label">{{
        $t("shareMessageplatform")
      }}</label>
      <input
        type="text"
        class="form-control input-text"
        id="share_platfrom"
        v-model="share_msg_platform"
        dir="auto"
      />
    </div>
  </div>
</template>

<script>
import { useOtherSettingsInfo } from "@/stores/OtherSettingsInfo";
import { mapState } from "pinia";
export default {
  name: "pusherInfo",
  data() {
    return {
      share_msg_ar: "",
      share_msg_en: "",
      share_msg_platform: "",
    };
  },
  computed: {
    ...mapState(useOtherSettingsInfo, ["shareMessage"]),
  },
  mounted() {
    if (this.shareMessage) {
      setTimeout(() => {
        this.share_msg_ar = this.shareMessage[0].value;
        this.share_msg_en = this.shareMessage[1].value;
        this.share_msg_platform = this.shareMessage[2].value;
      }, 1500);
    }
  },
};
</script>

<style scoped>
label span {
  color: var(--black-alt);
  display: inline-block;
  font-size: 14px;
}
@media (max-width: 575px) {
  label span {
    font-size: 12px;
  }
}
</style>
