<template>
  <div class="link accordion" id="accordionExample">
    <!-- Home Page Link -->
    <router-link
      v-if="permissionList.includes('home_page')"
      :to="$t('linksList[0].to')"
      :class="flex"
      id="link"
      @click="closeSidebar"
    >
      <i class="bi bi-house"></i>
      <div>{{ $t("linksList[0].title") }}</div>
    </router-link>

    <!-- Categories Link -->
    <router-link
      v-if="permissionList.includes('category_management')"
      :to="$t('linksList[1].to')"
      :class="flex"
      id="link"
      @click="closeSidebar"
    >
      <i class="bi bi-grid"></i>
      <div>{{ $t("linksList[1].title") }}</div>
    </router-link>

    <!-- Specification Link -->
    <router-link
      v-if="permissionList.includes('specification_management')"
      :to="$t('linksList[2].to')"
      :class="flex"
      id="link"
      @click="closeSidebar"
    >
      <i class="bi bi-card-checklist"></i>
      <div>{{ $t("linksList[2].title") }}</div>
    </router-link>

    <!-- Publisher List Link -->
    <router-link
      v-if="permissionList.includes('publisher_list')"
      :to="$t('linksList[11].to')"
      :class="flex"
      id="link"
      @click="closeSidebar"
    >
      <i class="bi bi-exclamation-circle"></i>
      <div>{{ $t("linksList[11].title") }}</div>
    </router-link>

    <!-- Ads List Link-->
    <router-link
      v-if="permissionList.includes('ad_list')"
      :to="$t('linksList[3].to')"
      :class="flex"
      id="link"
      @click="closeSidebar"
    >
      <i class="bi bi-list-ul"></i>
      <div>{{ $t("linksList[3].title") }}</div>
    </router-link>

    <!-- Commission -->
    <button
      v-if="checkCommission"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#homePage"
      class="commission d-flex justify-content-between align-items-center bg-transparent position-relative"
      :class="{ rtl: $i18n.locale == 'ar' }"
      @click="rotateChevron"
    >
      <div :class="flex">
        <i class="bi bi-receipt"></i>
        <div>{{ $t("linksList[4].title") }}</div>
      </div>
      <img src="@/assets/images/arrow-down.png" />
      <div
        v-if="commissionNotification > 0"
        class="position-absolute commission-notifications"
        :class="{ rtl: $i18n.locale == 'ar' }"
      >
        {{ commissionNotification }}
      </div>
    </button>

    <!-- SubLink Commission-->
    <div
      class="collapse fall"
      :class="{ rtl: $i18n.locale == 'ar' }"
      id="homePage"
    >
      <SubLink
        v-if="permissionList.includes('category_commission_setting')"
        :link="$t('listCommission[0].to')"
        >{{ $t("listCommission[0].title") }}</SubLink
      >
      <SubLink
        v-if="permissionList.includes('free_commission_setting')"
        :link="$t('listCommission[1].to')"
        >{{ $t("listCommission[1].title") }}</SubLink
      >
      <SubLink
        v-if="permissionList.includes('user_commission')"
        :link="$t('listCommission[2].to')"
        >{{ $t("listCommission[2].title") }}</SubLink
      >
      <SubLink
        v-if="permissionList.includes('commission_record')"
        :link="$t('listCommission[3].to')"
      >
        <div class="d-flex align-items-center justify-content-between">
          {{ $t("listCommission[3].title") }}
          <div
            v-if="commissionNotification > 0"
            class="commission-notifications"
            :class="{ rtl: $i18n.locale == 'ar' }"
          >
            {{ commissionNotification }}
          </div>
        </div>
      </SubLink>
    </div>

    <!-- statistics & analysis Link -->
    <router-link
      v-if="permissionList.includes('statistic')"
      :to="$t('linksList[5].to')"
      :class="flex"
      id="link"
      @click="closeSidebar"
    >
      <i class="bi bi-clipboard-data"></i>
      <div>{{ $t("linksList[5].title") }}</div>
    </router-link>

    <!-- user setting Link -->
    <router-link
      v-if="permissionList.includes('user_setting')"
      :to="$t('linksList[6].to')"
      :class="flex"
      id="link"
      @click="closeSidebar"
    >
      <i class="bi bi-person"></i>
      <div>{{ $t("linksList[6].title") }}</div>
    </router-link>

    <!-- Help Center -->
    <button
      v-if="checkHelpCenter"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#helpCenter"
      class="d-flex justify-content-between align-items-center bg-transparent"
      @click="rotateChevron"
    >
      <div :class="flex">
        <i class="bi bi-receipt"></i>
        <div>{{ $t("linksList[7].title") }}</div>
      </div>
      <img src="@/assets/images/arrow-down.png" />
    </button>
    <!-- SubLink Help Center -->
    <div
      class="collapse fall"
      :class="{ rtl: $i18n.locale == 'ar' }"
      id="helpCenter"
    >
      <SubLink
        v-if="permissionList.includes('protocols_management')"
        :link="$t('listHelp[0].to')"
        >{{ $t("listHelp[0].title") }}</SubLink
      >
      <SubLink
        v-if="permissionList.includes('video_management')"
        :link="$t('listHelp[1].to')"
        >{{ $t("listHelp[1].title") }}</SubLink
      >
    </div>

    <!-- Complaining Link -->
    <router-link
      v-if="permissionList.includes('complaining')"
      :to="$t('linksList[8].to')"
      class="complaining position-relative"
      :class="[flex, { rtl: $i18n.locale == 'ar' }]"
      id="link"
      @click="closeSidebar"
    >
      <i class="bi bi-exclamation-circle"></i>
      <div>{{ $t("linksList[8].title") }}</div>
      <div
        v-if="complaintNotification > 0"
        class="position-absolute notifications"
      >
        {{ complaintNotification }}
      </div>
    </router-link>

    <!-- Banner Setting Link -->
    <router-link
      v-if="permissionList.includes('banner_setting')"
      :to="$t('linksList[9].to')"
      :class="flex"
      id="link"
      @click="closeSidebar"
    >
      <i class="bi bi-sliders"></i>
      <div>{{ $t("linksList[9].title") }}</div>
    </router-link>

    <!-- System Setting -->
    <button
      v-if="checkSystemSetting"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#systemSetting"
      class="d-flex justify-content-between align-items-center bg-transparent"
      @click="rotateChevron"
    >
      <div :class="flex">
        <i class="bi bi-gear"></i>
        <div>{{ $t("linksList[10].title") }}</div>
      </div>
      <img src="@/assets/images/arrow-down.png" />
    </button>
    <!-- SubLink System Setting -->
    <div
      class="collapse fall"
      :class="{ rtl: $i18n.locale == 'ar' }"
      id="systemSetting"
    >
      <SubLink
        v-if="permissionList.includes('bank_account')"
        :link="$t('listSystem[0].to')"
        >{{ $t("listSystem[0].title") }}</SubLink
      >
      <SubLink
        v-if="permissionList.includes('other_setting')"
        :link="$t('listSystem[1].to')"
        >{{ $t("listSystem[1].title") }}</SubLink
      >
    </div>
  </div>
</template>

<script>
import SubLink from "./SubLink.vue";
import { mapState } from "pinia";
import { usePusherStore } from "@/stores/PusherNotification";

export default {
  name: "CustomLink",
  components: {
    SubLink,
  },
  data() {
    return {
      flex: "d-flex align-items-center gap-3",
      permissionList: [],
    };
  },
  methods: {
    rotateChevron($event) {
      $event.target.classList.toggle("rotate");
    },
    closeSidebar() {
      document.getElementById("sidebar").classList.remove("show");
    },
  },
  computed: {
    ...mapState(usePusherStore, [
      "commissionNotification",
      "complaintNotification",
    ]),
    checkCommission() {
      return this.permissionList.some((e) => {
        return (
          e == "category_commission_setting" ||
          e == "free_commission_setting" ||
          e == "user_commission" ||
          e == "commission_record"
        );
      });
    },
    checkHelpCenter() {
      return this.permissionList.some((e) => {
        return e == "protocols_management" || e == "video_management";
      });
    },
    checkSystemSetting() {
      return this.permissionList.some((e) => {
        return e == "bank_account" || e == "other_setting";
      });
    },
  },
  mounted() {
    this.permissionList = JSON.parse(
      localStorage.getItem("topz_admin_permissions")
    );
  },
};
</script>

<style scoped>
.link > a,
button {
  padding: 15px 10px;
  color: var(--black);
  border-radius: 10px;
  margin-bottom: 20px;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  transition: var(--transition);
}
@media (max-width: 767px) {
  .link > a,
  button {
    padding: 13px 10px;
    color: var(--black);
    border-radius: 7px;
    margin-bottom: 13px;
    font-size: 15px;
  }
}
.link > a:hover,
button:hover {
  background-color: #ddd;
}
.link > a.router-link-exact-active,
.link > a.router-link-active,
button.active {
  background-color: var(--primary-opc-orange);
  color: var(--primary-orange);
}
button img {
  transition: var(--transition);
  pointer-events: none;
}
button div {
  pointer-events: none;
}
button.rotate img {
  transform: rotate(180deg);
}
.fall {
  padding: 0 40px;
  padding-right: 20px;
}
.fall.rtl {
  padding: 0 40px;
  padding-left: 20px;
}
@media (max-width: 767px) {
  button img {
    width: 19px;
  }
}

.commission-notifications,
a.complaining .notifications {
  right: 65px;
  font-size: calc(8px + 0.390625vw);
  background: #d80f0f;
  color: #fff;
  width: 35px;
  border-radius: 25px;
  font-weight: 500;
  text-align: center;
  line-height: 24px;
  font-family: "Poppins", sans-serif;
}
.commission-notifications.rtl,
a.complaining.rtl .notifications {
  right: 70px;
  right: auto;
  left: 70px;
}
@media (max-width: 1399px) {
  .commission-notifications,
  a.complaining .notifications {
    width: 30px;
  }
}
@media (max-width: 575px) {
  .commission-notifications,
  a.complaining .notifications {
    right: 75px;
    width: 25px;
  }
}
</style>
