import { createApp } from "vue";
import { createPinia } from "pinia";
import Vue3Autocounter from "vue3-autocounter";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import axios from "axios";

const pinia = createPinia();

router.beforeEach((to, from, next) => {
  let language = to.params.lang;
  if (!language) {
    language = "en";
  }
  i18n.global.locale = language;
  next();
});

axios.defaults.baseURL = "https://apis.topz.app/api";

axios.interceptors.request.use((config) => {
  let token = localStorage.getItem("topz_admin_token");
  config.headers = Object.assign(
    {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Accept-Language": i18n.global.locale,
    },
    config.headers
  );
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // إذا كان الخطأ 401 (غير مفوض)، قم بتسجيل خروج المستخدم
      localStorage.removeItem("topz_admin_token");
      localStorage.removeItem("topz_admin_permissions");
      router.push(`/${i18n.global.locale}/login`);
    }
    return Promise.reject(error);
  }
);

createApp(App)
  .use(i18n)
  .use(router)
  .use(pinia)
  .use(Vue3Autocounter)
  .mount("#app");
