<template>
  <div>
    <SearchByTime @search="searchStat" />
    <div class="responsive-table">
      <table class="custom-table table-bordered">
        <thead>
          <tr>
            <th scope="col" v-for="head in 4" :key="head">
              {{ $t(`statList[${head - 1}]`) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="stat in statistics" :key="stat.category.id">
            <td>{{ stat.category.name }}</td>
            <td>{{ stat.total_ad_number }}</td>
            <td>{{ stat.on_sale }}</td>
            <td>{{ stat.commission_total }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th
              scope="col"
              style="color: var(--primary-orange); font-size: 20px"
            >
              {{ $t("total") }}
            </th>
            <th scope="col">{{ totalAd }}</th>
            <th scope="col">{{ totalAdSale }}</th>
            <th scope="col">{{ totalCommission }}</th>
          </tr>
        </tfoot>
      </table>
    </div>
    <!-- Loading... -->
    <div v-if="loading" class="loading load">
      <div class="text-center">
        <img src="@/assets/images/loading.svg" alt="spinner" />
      </div>
    </div>
  </div>
</template>

<script>
import SearchByTime from "./SearchByTime.vue";
import axios from "axios";
import saveInfoAlert from "@/assets/js/saveInfoAlert";

export default {
  name: "TableStat",
  components: { SearchByTime },
  data() {
    return {
      statistics: [],
      loading: false,
      totalAd: "",
      totalAdSale: "",
      totalCommission: "",
    };
  },
  methods: {
    async fetchStatDuration(duration) {
      this.loading = true;
      await axios
        .post(`dashboard/statistics/${duration}`)
        .then((res) => {
          this.statistics = res.data.data.categories;
          this.totalAd = res.data.data.total_ad;
          this.totalAdSale = res.data.data.total_ad_on_sale;
          this.totalCommission = res.data.data.total_commission_amount;
          this.loading = false;
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
          this.loading = false;
        });
    },
    async fetchStatDate(startDate, endDate) {
      this.loading = true;
      await axios
        .post("dashboard/statistics", {
          from_date: startDate,
          to_date: endDate,
        })
        .then((res) => {
          this.statistics = res.data.data.categories;
          this.totalAd = res.data.data.total_ad;
          this.totalAdSale = res.data.data.total_ad_on_sale;
          this.totalCommission = res.data.data.total_commission_amount;
          this.loading = false;
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
          this.loading = false;
        });
    },
    searchStat(time) {
      if (time[0] == "custom") {
        this.fetchStatDate(time[1], time[2]);
      } else {
        this.fetchStatDuration(time[0]);
      }
    },
  },
  watch: {
    "$i18n.locale"() {
      this.fetchStatDuration("last_30_days");
    },
  },
  async mounted() {
    this.fetchStatDuration("last_30_days");
  },
};
</script>

<style scoped></style>
