<template>
  <div>
    <SearchingComplaining @search="searchComplaining" />
    <div class="responsive-table">
      <table class="custom-table table-bordered">
        <thead>
          <tr>
            <th scope="col">
              {{ $t("userName") }}
            </th>
            <th scope="col">
              {{ $t("phone") }}
            </th>
            <th scope="col" class="content">
              {{ $t("content") }}
            </th>
            <th scope="col">
              {{ $t("options") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="admin in admins" :key="admin.id">
            <td>{{ admin.user_id.name }}</td>
            <td class="phone-ltr">
              {{ admin.phone_number }}
            </td>
            <td class="content-complaint">
              <p>{{ admin.content }}</p>
            </td>
            <td class="process">
              <button
                type="button"
                v-if="admin.status == $t('pending')"
                @click="checkProcess(admin.id)"
              >
                <i class="bi bi-check-lg"></i>
              </button>
              <span v-else>{{ $t("processingDone") }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <PaginationBox
      :pages="pages"
      :current_page="current_page"
      :next_page="next_page"
      :prev_page="prev_page"
      @chooseRec="chooseRecord"
      @thisPage="thisPage"
      @nextPage="nextPage"
      @prevPage="prevPage"
    />
    <!-- For Loading -->
    <div v-if="loading" class="loading load">
      <div class="text-center">
        <img src="@/assets/images/loading.svg" alt="spinner" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PaginationBox from "../global/PaginationBox.vue";
import saveInfoAlert from "@/assets/js/saveInfoAlert";
import SearchingComplaining from "./SearchingComplaining.vue";

export default {
  name: "TableComplaining",
  components: { PaginationBox, SearchingComplaining },
  data() {
    return {
      admins: [],
      loading: false,
      records: "10",
      pages: "",
      current_page: "",
      next_page: "",
      prev_page: "",
      search: [],

      url: "",
    };
  },
  methods: {
    async fetchComplaining(record, page, name, status) {
      this.loading = true;
      if (name && status) {
        this.url = `dashboard/complaints?records=${record}&page=${page}&name=${name}&status=${status}`;
      } else if (status) {
        this.url = `dashboard/complaints?records=${record}&page=${page}&status=${status}`;
      } else if (name) {
        this.url = `dashboard/complaints?records=${record}&page=${page}&name=${name}`;
      } else {
        this.url = `dashboard/complaints?records=${record}&page=${page}`;
      }

      await axios
        .get(this.url)
        .then((res) => {
          this.admins = res.data.data;

          this.pages = res.data.pagination.last_page;
          this.current_page = res.data.pagination.current_page;
          this.next_page = res.data.pagination.next_page_url;
          this.prev_page = res.data.pagination.prev_page_url;
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async checkProcess(id) {
      await axios
        .post(`dashboard/complaints/${id}`)
        .then(() => {
          this.fetchComplaining(this.records, this.current_page);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        });
    },
    async searchComplaining(payload) {
      console.log(payload);

      this.fetchComplaining(this.records, 1, payload[0], payload[1]);
      this.search = payload;
    },
    chooseRecord(records) {
      this.records = records;
      this.fetchComplaining(records, 1, this.search[0], this.search[1]);
    },
    thisPage(p) {
      this.fetchComplaining(this.records, p, this.search[0], this.search[1]);
    },
    nextPage() {
      this.thisPage(this.current_page + 1);
    },
    prevPage() {
      this.thisPage(this.current_page - 1);
    },
  },
  mounted() {
    this.fetchComplaining(this.records, 1, "", "pending");
  },
  watch: {
    "$i18n.locale"() {
      this.fetchComplaining(this.records, 1, "", "pending");
    },
  },
};
</script>

<style scoped>
table {
  min-width: 1200px;
}
table th.content {
  width: 60%;
}
table td.process,
table td.process button {
  color: #3d9635;
  background-color: transparent;
}
table td.process i {
  cursor: pointer;
  font-size: 40px;
}
table td.content-complaint p {
  padding: 12px 0;
  word-break: break-word;
}
</style>
