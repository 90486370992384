<template>
  <div class="category-commission">
    <h4 class="title">{{ $t("listCommission[0].title") }}</h4>
    <div class="responsive-table">
      <table class="custom-table table-bordered">
        <thead>
          <tr>
            <th scope="col" v-for="head in 3" :key="head">
              {{ $t(`listCommission[0].table[${head - 1}]`) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="cat in categories" :key="cat.id">
            <td>{{ cat.id }}</td>
            <td>{{ cat.name }}</td>
            <td class="position-relative">
              {{ cat.commission_value }}
              <span
                v-if="cat.commission_type == 'fixed'"
                class="d-inline-block"
                >{{ $t("sr") }}</span
              >
              <span v-else class="d-inline-block">%</span>
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#chooseType"
                class="position-absolute"
                :class="{ rtl: $i18n.locale === 'ar' }"
                @click="getCategoryId(cat)"
              >
                <img src="@/assets/images/setting.svg" alt="gear" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Modal -->
    <ModalPopup
      ident="chooseType"
      width="600px"
      @send="confirm"
      @cancelModal="cancelRate"
      ref="modalUpdateRate"
    >
      <div class="modal-body">
        <div>
          <label>{{ $t("listCommission[0].type") }}</label>
          <div class="dropdown custom-select w-100">
            <button
              type="button"
              class="dropdown-toggle d-flex align-items-center justify-content-between w-100"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>{{ typeComm }}</span>
              <i class="bi bi-chevron-down"></i>
            </button>
            <ul class="dropdown-menu">
              <li>
                <button
                  type="button"
                  class="dropdown-item type"
                  id="percentage"
                  :class="{ rtl: $i18n.locale === 'ar' }"
                  @click="changeType($event)"
                >
                  {{ $t("listCommission[0].percentage") }}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  class="dropdown-item type"
                  id="fixed"
                  :class="{ rtl: $i18n.locale === 'ar' }"
                  @click="changeType($event)"
                >
                  {{ $t("listCommission[0].fixedComm") }}
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <label>{{ type }}</label>
          <div class="position-relative">
            <input
              type="text"
              class="form-control input-text font-poppins"
              @keypress="onlyNumber"
              @keyup="valideRate"
              v-model="rate"
              required
            />
            <span
              class="value position-absolute font-poppins"
              :class="{ rtl: $i18n.locale === 'ar' }"
              >{{ value }}</span
            >
          </div>
          <span v-if="rangeRate && value == '%'" class="range-rate">{{
            $t("listCommission[2].enterCorrectRate")
          }}</span>
        </div>
      </div>
    </ModalPopup>

    <h5>
      {{ $t("listCommission[0].operation") }}
    </h5>
    <ul class="definitions">
      <li>
        {{ $t("listCommission[0].defenitions[0]") }}
      </li>
      <li>
        {{ $t("listCommission[0].defenitions[1]") }}
      </li>
      <li>
        {{ $t("listCommission[0].defenitions[2]") }}
      </li>
      <li>
        {{ $t("listCommission[0].defenitions[3]") }}
      </li>
    </ul>

    <!-- For Loading -->
    <div v-if="loading" class="loading load">
      <div class="text-center">
        <img src="@/assets/images/loading.svg" alt="spinner" />
      </div>
    </div>
  </div>
</template>

<script>
import ModalPopup from "@/components/global/ModalPopup.vue";
import onlyNumber from "@/assets/js/onlyNumber";
// import chooseItem from "@/assets/js/chooseItem";
import saveInfoAlert from "@/assets/js/saveInfoAlert";
import axios from "axios";

export default {
  name: "CategoryCommission",
  components: {
    ModalPopup,
  },
  data() {
    return {
      categories: [],
      catId: "",
      type: this.$t("listCommission[0].rate"),
      typeComm: this.$t("listCommission[0].percentage"),
      value: "%",
      commission_type: "percentage",
      rate: "",
      rangeRate: false,
      loading: true,
    };
  },
  watch: {
    "$i18n.locale"() {
      this.type = this.$t("listCommission[0].rate");
      this.typeComm = this.$t("listCommission[0].percentage");
      this.value = "%";
      this.fetchCategoryCommission();
    },
  },
  methods: {
    onlyNumber,
    async fetchCategoryCommission() {
      await axios
        .get("dashboard/commissions/category-commission?records=10000")
        .then((res) => {
          this.categories = res.data.data;
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCategoryId(category) {
      this.rate = category.commission_value;
      this.catId = category.id;
      if (category.commission_type === "fixed") {
        this.value = this.$t("sr");
        this.type = this.$t("amount");
        this.commission_type = "fixed";
        this.typeComm = this.$t("listCommission[0].fixedComm");
      } else {
        this.value = "%";
        this.type = this.$t("listCommission[0].rate");
        this.commission_type = "percentage";
        this.typeComm = this.$t("listCommission[0].percentage");
      }
    },
    changeType($event) {
      // chooseItem(".active", $event);
      this.typeComm = $event.target.textContent;
      this.rangeRate = false;
      if ($event.target.id === "fixed") {
        this.value = this.$t("sr");
        this.type = this.$t("amount");
        this.commission_type = "fixed";
      } else {
        this.value = "%";
        this.type = this.$t("listCommission[0].rate");
        this.commission_type = "percentage";
      }
    },
    valideRate() {
      if (this.rate > 100 && this.commission_type == "percentage") {
        this.rangeRate = true;
      } else {
        this.rangeRate = false;
      }
    },
    async confirm() {
      if (!this.rangeRate) {
        await axios
          .post("dashboard/commissions/update-commission-value", {
            category_id: this.catId,
            commission_type: this.commission_type,
            commission_value: this.rate,
          })
          .then((res) => {
            saveInfoAlert(this.$i18n.locale, "success", res.data.message);
            this.$refs.modalUpdateRate.closeModalButton.click();
            this.fetchCategoryCommission();
            this.rate = "";
            this.value = "%";
            this.type = this.$t("listCommission[0].rate");
            this.commission_type = "percentage";
            this.typeComm = this.$t("listCommission[0].percentage");
          })
          .catch((err) => {
            saveInfoAlert(
              this.$i18n.locale,
              "error",
              err.response.data.message
            );
          });
      }
    },
    cancelRate() {
      this.rate = "";
    },
  },
  async mounted() {
    this.fetchCategoryCommission();
  },
};
</script>

<style scoped>
@import "@/assets/css/comminsion/categoryCommission.css";
</style>
