<template>
  <div class="ad-list">
    <h4 class="title">{{ $t("linksList[3].title") }}</h4>
    <TableAdList />
  </div>
</template>

<script>
import TableAdList from "@/components/adList/TableAdList.vue";
export default {
  name: "AdList",
  components: {
    TableAdList,
  },
};
</script>

<style scoped></style>
