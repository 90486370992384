<template>
  <div class="modal-body">
    <div>
      <label for="sort" class="form-label">{{
        $t("listBannerTable[4]")
      }}</label>
      <input
        type="number"
        min="1"
        class="form-control input-text font-poppins"
        id="sort"
        v-model="sort"
        required
      />
    </div>
    <div>
      <label for="id" class="form-label">Product Id</label>
      <input
        type="text"
        class="form-control input-text font-poppins"
        id="id"
        v-model="productId"
        dir="auto"
        required
      />
    </div>
    <div>
      <label class="form-label">{{ $t("bannerApp") }}</label>
      <div>
        <span>{{ $t("uploadImg") }}</span>
        <span v-if="appSizeLong" class="size-long">{{
          $t("imageSizeLargerThan6MB")
        }}</span>
        <span v-if="appErrorDimension" class="size-long">{{
          $t("imageDimensionsAreSmallerThan300200")
        }}</span>
        <label for="uploadImageApp" class="form-control input-text upload">
          <img src="@/assets/images/uploadImage.png" alt="upload" />
          <span>{{ $t("UploadYourPicture") }}</span>
        </label>
      </div>
      <!-- <img v-else :src="appImage" class="banner-img" /> -->
      <input
        type="file"
        id="uploadImageApp"
        accept="image/png, image/jpeg"
        @change="uploadBannerApp"
      />
    </div>
    <div>
      <label class="form-label">{{ $t("bannerWeb") }}</label>
      <div>
        <span>{{ $t("uploadImgWeb") }}</span>
        <span v-if="webSizeLong" class="size-long">
          {{ $t("imageSizeLargerThan6MB") }}
        </span>
        <span v-if="webErrorDimension" class="size-long">
          {{ $t("imageDimensionsAreSmallerThan1800900") }}
        </span>
        <label for="uploadImageWeb" class="form-control input-text upload">
          <img src="@/assets/images/uploadImage.png" />
          <span>{{ $t("UploadYourPicture") }}</span>
        </label>
      </div>
      <!-- <img v-else :src="webImage" class="banner-img" /> -->
      <input
        type="file"
        id="uploadImageWeb"
        accept="image/png, image/jpeg"
        @change="uploadBannerWeb"
      />
    </div>
    <div
      v-if="appImage.length"
      class="progress-image d-flex align-items-center gap-2 font-poppins"
    >
      <div class="picture">
        <img :src="appImage[0]" />
      </div>
      <div class="flex-grow-1">
        <p>{{ appImage[1] }}</p>
      </div>
    </div>
    <div
      v-if="webImage.length"
      class="progress-image d-flex align-items-center gap-2 font-poppins"
    >
      <div class="picture">
        <img :src="webImage[0]" />
      </div>
      <div class="flex-grow-1">
        <p>{{ webImage[1] }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddBanner",
  data() {
    return {
      sort: "",
      productId: "",
      appImage: [],
      file1: null,
      appSizeLong: false,
      appErrorDimension: false,
      file2: null,
      webImage: [],
      webSizeLong: false,
      webErrorDimension: false,
    };
  },
  methods: {
    uploadBannerApp($event) {
      const reader = new FileReader();
      const image = new Image();

      if (!$event.target.files[0]) return;
      else {
        if ($event.target.files[0].size > 6300000) {
          this.appSizeLong = true;
          this.appErrorDimension = false;
        } else {
          this.file1 = $event.target.files[0];
          reader.onload = (e) => {
            image.onload = () => {
              if (image.width >= 300 && image.height >= 200) {
                this.appErrorDimension = false;
                this.appImage[0] = e.target.result;
                this.appImage[1] = this.file1.name;
              } else {
                this.appErrorDimension = true;
              }
            };
            image.src = e.target.result;
          };
          reader.readAsDataURL($event.target.files[0]);
          this.appSizeLong = false;
        }
      }

      $event.target.value = "";
    },

    uploadBannerWeb($event) {
      const reader = new FileReader();
      const image = new Image();

      if (!$event.target.files[0]) return;
      else {
        this.file2 = $event.target.files[0];
        if ($event.target.files[0].size > 6300000) {
          this.webSizeLong = true;
          this.webErrorDimension = false;
        } else {
          reader.onload = (e) => {
            image.onload = () => {
              if (image.width >= 1800 && image.height >= 900) {
                this.webErrorDimension = false;
                this.webImage[0] = e.target.result;
                this.webImage[1] = this.file2.name;
              } else {
                this.webErrorDimension = true;
              }
            };
            image.src = e.target.result;
          };
          reader.readAsDataURL($event.target.files[0]);
          this.webSizeLong = false;
        }
      }

      $event.target.value = "";
    },
  },
};
</script>

<style scoped>
.size-long {
  color: #f73d17 !important;
  margin-top: 5px;
}
.banner-img {
  width: 100%;
  border-radius: 5px;
  max-height: 300px;
  object-fit: cover;
}
.modal .modal-body span {
  font-size: 12px;
  color: var(--black-alt);
}
.modal .modal-body label.upload {
  border-style: dashed;
  border-width: 2px;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
  max-height: 300px;
}
.modal .modal-body label.upload span {
  font-weight: 500;
  color: var(--black);
  margin-top: 10px;
}

.progress-image .picture {
  width: 55px;
  height: 55px;
}
.progress-image .picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.progress-image p {
  font-size: 14px;
  /* margin-bottom: 20px; */
}

.progress-image .progress {
  height: 6px;
}
.progress-image .progress .progress-bar {
  background-color: var(--primary-orange);
  border-radius: 14px;
}
.progress-image button {
  color: #f73d17;
  font-size: 20px;
  background-color: transparent;
}
.progress-image span {
  color: var(--black) !important;
  font-weight: 500;
  margin-top: 13px;
}

@media (max-width: 575px) {
  .modal .modal-body span {
    font-size: 10px;
  }
  .modal .modal-body label.upload {
    border-width: 1px;
  }
  .modal .modal-body label.upload img {
    width: 30px;
  }
  .progress-image {
    margin-bottom: 12px;
  }
  .progress-image .picture {
    width: 45px;
    height: 45px;
  }
  .progress-image p {
    font-size: 12px;
    /* margin-bottom: 10px; */
  }
  .progress-image .progress {
    height: 4px;
  }
  .progress-image button {
    font-size: 16px;
  }
  .progress-image span {
    margin-top: 8px;
  }
}
</style>
