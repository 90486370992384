<template>
  <div class="options">
    <!-- Home - Category - Ad - Ads List -->
    <label v-for="item in 4" :key="item" class="mb-4">
      <input
        type="checkbox"
        :class="{ rtl: $i18n.locale === 'ar' }"
        class="custom-checkbox"
        :value="item"
        :checked="item == 1"
      />
      {{ $t(`linksList[${item - 1}].title`) }}</label
    >

    <!-- Publisher List -->
    <label class="mb-4">
      <input
        type="checkbox"
        class="custom-checkbox"
        :class="{ rtl: $i18n.locale === 'ar' }"
        :value="17"
      />
      {{ $t("linksList[11].title") }}</label
    >

    <!-- Commission -->
    <label class="mb-4">
      <input
        type="checkbox"
        class="custom-checkbox check-all"
        :class="{ rtl: $i18n.locale === 'ar' }"
        @input="checkAll($event, 'commission')"
      />
      {{ $t(`linksList[4].title`) }}</label
    >
    <div class="ms-5 me-5">
      <label v-for="item in 4" :key="item" class="mb-4">
        <input
          class="commission custom-checkbox"
          type="checkbox"
          :class="{ rtl: $i18n.locale === 'ar' }"
          :value="item + 4"
        />
        {{ $t(`listCommission[${item - 1}].title`) }}</label
      >
    </div>

    <!-- Statistics - User Setting -->
    <label v-for="item in 2" :key="item" class="mb-4">
      <input
        type="checkbox"
        class="custom-checkbox"
        :class="{ rtl: $i18n.locale === 'ar' }"
        :value="item + 8"
      />
      {{ $t(`linksList[${item + 4}].title`) }}</label
    >

    <!-- Help Center -->
    <label class="mb-4">
      <input
        type="checkbox"
        class="custom-checkbox check-all"
        :class="{ rtl: $i18n.locale === 'ar' }"
        @input="checkAll($event, 'help')"
      />
      {{ $t(`linksList[7].title`) }}</label
    >
    <div class="ms-5 me-5">
      <label v-for="item in 2" :key="item" class="mb-4">
        <input
          type="checkbox"
          class="help custom-checkbox"
          :class="{ rtl: $i18n.locale === 'ar' }"
          :value="item + 10"
        />
        {{ $t(`listHelp[${item - 1}].title`) }}</label
      >
    </div>

    <!-- Complaining - Banner Setting -->
    <label v-for="item in 2" :key="item" class="mb-4">
      <input
        type="checkbox"
        class="custom-checkbox"
        :class="{ rtl: $i18n.locale === 'ar' }"
        :value="item + 12"
      />
      {{ $t(`linksList[${item + 7}].title`) }}</label
    >

    <!-- System Setting -->
    <label class="mb-4">
      <input
        type="checkbox"
        class="custom-checkbox check-all"
        :class="{ rtl: $i18n.locale === 'ar' }"
        @input="checkAll($event, 'system')"
      />
      {{ $t(`linksList[10].title`) }}</label
    >
    <div class="ms-5 me-5">
      <label v-for="item in 2" :key="item" class="mb-4">
        <input
          type="checkbox"
          class="system custom-checkbox"
          :class="{ rtl: $i18n.locale === 'ar' }"
          :value="item + 14"
        />
        {{ $t(`listSystem[${item - 1}].title`) }}</label
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "CheckboxOptions",
  data() {
    return {
      // allList: [1, 2],
    };
  },
  methods: {
    checkAll($event, className) {
      const items = document.querySelectorAll("." + className);

      if ($event.target.checked == true) {
        items.forEach((el) => {
          el.checked = true;
        });
      } else {
        items.forEach((el) => {
          el.checked = false;
        });
      }
    },
  },
};
</script>

<style scoped>
.options label {
  color: var(--black);
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}
</style>
