<template>
  <div>
    <!-- Searching -->
    <SearchingBox
      :selectStatus="$t('selectSaleStatus')"
      type1="1"
      type2="0"
      @searchPublisher="searchPublisher"
      @refresh="refreshSearching"
    />

    <!-- Products List -->
    <div class="responsive-table">
      <table class="custom-table table-bordered">
        <thead>
          <tr>
            <th scope="col" v-for="head in 11" :key="head">
              {{ $t(`tableAdList[${head - 1}]`) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="ad in products" :key="ad.id">
            <td>
              {{ ad.id }}
            </td>
            <td
              data-bs-toggle="modal"
              data-bs-target="#adInfo"
              @click="showInfo(ad.id)"
              class="clickable"
            >
              {{ ad.title }}
            </td>
            <td>
              <button
                type="button"
                class="btn image-popup"
                data-bs-toggle="modal"
                :data-bs-target="'#' + ad.id"
              >
                <!-- <img src="@/assets/images/car4.jpg" /> -->
                <img :src="ad.photo" />
              </button>
              <PopupImage :img="ad.photo" :ident="ad.id" />
            </td>
            <td v-if="ad.category && ad.category.parent" class="category">
              <span v-if="ad.category.parent?.parent" class="me-1 ms-1">
                {{ ad.category.parent.parent?.name }}
                <i class="fa-solid fa-chevron-right"></i>
              </span>
              <span v-if="ad.category.parent" class="me-1 ms-1">
                {{ ad.category.parent.name }}
                <i class="fa-solid fa-chevron-right"></i>
              </span>
              <span class="me-1 ms-1">
                {{ ad.category.name }}
              </span>
            </td>
            <td v-else class="category">
              <span v-if="ad.category" class="me-1 ms-1">
                {{ ad.category.name }}
              </span>
              <span v-else class="me-1 ms-1"> {{ $t("no") }} </span>
            </td>
            <td>{{ ad.publisher.name }}</td>
            <td>
              <input
                type="checkbox"
                class="custom-toggle"
                :defaultChecked="ad.recommended"
                @change="changeRecommended(ad.id)"
              />
            </td>
            <td>
              <input
                type="checkbox"
                class="custom-toggle"
                :defaultChecked="ad.is_visibility"
                @change="changeStatus(ad.id)"
              />
            </td>
            <!-- <td>{{ ad.publisher.country }}, {{ ad.city.name }}</td> -->
            <td>{{ ad.country.name }}, {{ ad.city.name }}</td>
            <td
              data-bs-toggle="modal"
              data-bs-target="#comments"
              @click="showComments(ad.id)"
              class="clickable"
            >
              {{ ad.comments_count }}
            </td>
            <td>{{ ad.complaints_count }}</td>
            <td>{{ ad.publish_time }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Pagination -->
    <PaginationBox
      :pages="pages"
      :current_page="current_page"
      :next_page="next_page"
      :prev_page="prev_page"
      @chooseRec="chooseRecord"
      @thisPage="thisPage"
      @nextPage="nextPage"
      @prevPage="prevPage"
    />

    <!-- First Popup Of All User Info -->
    <AdListPopup :ident="'adInfo'" :comments="comments">
      <AdInfo v-if="listInfo" :listInfo="listInfo" />
      <div v-if="loadingAd" class="loading loadAds">
        <div class="text-center">
          <img src="@/assets/images/Pulse.svg" alt="spinner" />
        </div>
      </div>
    </AdListPopup>

    <!-- Second Popup Of Comments Only -->
    <AdListPopup
      :ident="'comments'"
      :title="$t('tableAdList[8]')"
      :comments="comments"
    >
      <div v-if="loadingComments" class="loading loadAds">
        <div class="text-center">
          <img src="@/assets/images/Pulse.svg" alt="spinner" />
        </div>
      </div>
    </AdListPopup>

    <!-- For Loading -->
    <div v-if="loading" class="loading load">
      <div class="text-center">
        <img src="@/assets/images/loading.svg" alt="spinner" />
      </div>
    </div>
  </div>
</template>

<script>
import SearchingBox from "../global/SearchingBox.vue";
import PaginationBox from "@/components/global/PaginationBox.vue";
import AdListPopup from "./AdListPopup.vue";
import AdInfo from "./AdInfo.vue";
import PopupImage from "@/components/global/PopupImage.vue";

import axios from "axios";
import saveInfoAlert from "@/assets/js/saveInfoAlert";

export default {
  name: "TableAdList",
  components: {
    PaginationBox,
    AdListPopup,
    AdInfo,
    PopupImage,
    SearchingBox,
  },
  data() {
    return {
      products: [],
      loading: false,
      listInfo: null,
      loadingAd: false,
      comments: [],
      loadingComments: false,

      records: 10,
      pages: "",
      current_page: "",
      next_page: "",
      prev_page: "",
      is_search: false,

      name: "",
      country: "",
      status: "",
    };
  },
  watch: {
    "$i18n.locale"() {
      this.fetchProducts(this.records, this.current_page);
    },
  },
  methods: {
    async fetchProducts(record, page) {
      this.loading = true;
      await axios
        .get(`dashboard/products?records=${record}&page=${page}`)
        .then((res) => {
          this.products = res.data.data;
          this.pages = res.data.pagination.last_page;
          this.current_page = res.data.pagination.current_page;
          this.next_page = res.data.pagination.next_page_url;
          this.prev_page = res.data.pagination.prev_page_url;
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async showInfo(id) {
      this.loadingAd = true;
      await axios
        .get(`dashboard/products/${id}/show`)
        .then((res) => {
          this.listInfo = res.data.data;
          this.comments = res.data.data.comments;
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        })
        .finally(() => {
          this.loadingAd = false;
        });
    },
    async changeRecommended(id) {
      await axios
        .post(`dashboard/products/${id}/change-recommended`)
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        });
    },
    async changeStatus(id) {
      await axios
        .post(`dashboard/products/${id}/change-status`)
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
          // this.fetchProducts(this.records, this.current_page);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        });
    },
    async showComments(id) {
      this.loadingComments = true;
      await axios
        .get(`dashboard/products/${id}/show`)
        .then((res) => {
          this.comments = res.data.data.comments;
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        })
        .finally(() => {
          this.loadingComments = false;
        });
    },
    chooseRecord(record) {
      this.records = record;
      if (this.is_search) {
        this.fetchProductsAfterSearch(
          this.name,
          this.country,
          this.status,
          record,
          1
        );
      } else {
        this.fetchProducts(record, 1);
      }
    },
    thisPage(p) {
      if (this.is_search) {
        this.fetchProductsAfterSearch(
          this.name,
          this.country,
          this.status,
          this.records,
          p
        );
      } else {
        this.fetchProducts(this.records, p);
      }
    },
    nextPage() {
      if (this.is_search) {
        this.fetchProductsAfterSearch(
          this.name,
          this.country,
          this.status,
          this.records,
          this.current_page + 1
        );
      } else {
        this.fetchProducts(this.records, this.current_page + 1);
      }
    },
    prevPage() {
      if (this.is_search) {
        this.fetchProductsAfterSearch(
          this.name,
          this.country,
          this.status,
          this.records,
          this.current_page - 1
        );
      } else {
        this.fetchProducts(this.records, this.current_page - 1);
      }
    },
    async searchPublisher(payload) {
      const name = payload[0] ? `publisher_name=${payload[0]}` : "";
      this.name = name;
      const country = payload[1] ? `country_id=${payload[1]}` : "";
      this.country = country;
      const status = payload[2] ? `visible=${payload[2]}` : "";
      this.status = status;
      let url = `dashboard/products?${name}&${country}&${status}&records=${this.records}&page=1`;

      this.loading = true;
      await axios
        .get(url)
        .then((res) => {
          this.products = res.data.data;
          this.pages = res.data.pagination.last_page;
          this.current_page = res.data.pagination.current_page;
          this.next_page = res.data.pagination.next_page_url;
          this.prev_page = res.data.pagination.prev_page_url;
          this.loading = false;
          this.is_search = true;
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
          this.loading = false;
          this.is_search = false;
        });
    },
    async fetchProductsAfterSearch(name, country, status, records, page) {
      this.loading = true;
      await axios
        .get(
          `dashboard/products?${name}&${country}&${status}&records=${records}&page=${page}`
        )
        .then((res) => {
          this.products = res.data.data;
          this.pages = res.data.pagination.last_page;
          this.current_page = res.data.pagination.current_page;
          this.next_page = res.data.pagination.next_page_url;
          this.prev_page = res.data.pagination.prev_page_url;
          this.loading = false;
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
          this.loading = false;
        });
    },
    refreshSearching() {
      this.fetchProducts(this.records, 1);
      this.is_search = false;
    },
  },
  mounted() {
    this.fetchProducts(10, 1);
  },
};
</script>

<style scoped>
.custom-table {
  min-width: 1550px;
}
.custom-table td.clickable {
  cursor: pointer;
}
.custom-table td.category {
  width: 200px;
}
.custom-table td.category span {
  display: inline-block;
}
.custom-table td.category i {
  font-size: 12px;
}

@media (max-width: 767px) {
  .custom-table td.category i {
    font-size: 10px;
  }
}
</style>
