<template>
  <div
    class="d-flex gap-3 rad-10 semi-box"
    :style="{ backgroundColor: listBox.color }"
  >
    <div class="image">
      <img :src="listBox.src" />
    </div>
    <div class="d-flex flex-column justify-content-between">
      <span class="title">{{ title }}</span>
      <h2 ref="number">
        <vue3-autocounter
          ref="counter"
          :endAmount="+number"
          :duration="1"
          separator=","
          :autoinit="true"
        />
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatBox",
  props: ["title", "listBox", "number"],
};
</script>

<style scoped>
.semi-box {
  padding: 30px 20px;
  height: 175px;
}
.semi-box .image {
  width: 50px;
  height: 50px;
  background-color: var(--white);
  border-radius: 15px;
  text-align: center;
  line-height: 50px;
  flex-shrink: 0;
}
.semi-box span.title {
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
}
@media (max-width: 767px) {
  .semi-box {
    height: 117px;
    padding: 16px 12px;
  }
  .semi-box span.title {
    font-size: 14px;
    margin-top: 14px;
  }
  .semi-box h2 {
    font-size: 19px;
  }
}
</style>
