<template>
  <div>
    <!-- Searching -->
    <SearchingByStatus
      :ident="'addCategory'"
      :add="$t('addNewCategory')"
      :width="'600px'"
      :placeholder="$t('enterCategoryName')"
      @search="search"
      @send="addNewCategory"
      @cancelModal="cancelAddCategory"
      @refresh="refreshSearching"
      ref="modalAddCategory"
    >
      <AddCategory ref="addcategory" />
    </SearchingByStatus>

    <!-- This is categories table -->
    <GridTable />

    <PaginationBox
      :pages="pages"
      :current_page="current_page"
      :next_page="next_page"
      :prev_page="prev_page"
      @chooseRec="chooseRecord"
      @thisPage="thisPage"
      @nextPage="nextPage"
      @prevPage="prevPage"
    />

    <!-- Popup to change Cateogry Name only -->
    <!-- <ModalPopup
      :title="$t('listCategoryTable[1]')"
      :ident="'editCategoryName'"
      :width="'600px'"
    >
      <EditCategoryName />
    </ModalPopup> -->

    <!-- Popup to change Sort only -->
    <ModalPopup
      :title="$t('listCategoryTable[2]')"
      ident="editSort"
      :width="'600px'"
      @send="editSort"
      @cancelModal="cancelEditSort"
      ref="modalEditSort"
    >
      <EditSort ref="editSort" />
    </ModalPopup>

    <!-- Popup To Add Mid Category -->
    <ModalPopup
      :title="$t('addMinCategory')"
      ident="addMinCategory"
      width="600px"
      @send="addMidCategory"
      @cancelModal="cancelAddMidCat"
      ref="modalAddMidCategory"
    >
      <AddMidCategory ref="addMidCategory" />
    </ModalPopup>

    <!-- Popup To Add Final Category -->
    <ModalPopup
      :title="$t('addFinalCategory')"
      ident="addfinalCategory"
      width="600px"
      @send="addFinalCategory"
      @cancelModal="cancelAddFinalCat"
      ref="modalAddFinalCategory"
    >
      <AddMidCategory ref="addFinalCategory" />
    </ModalPopup>

    <!-- Popup to change Category -->
    <ModalPopup
      :title="$t('editCategory')"
      ident="editCategory"
      :width="'600px'"
      @send="editCategory"
      @cancelModal="cancelEditCategory"
      ref="modalEditCategory"
    >
      <EditCategory ref="editCategory" />
    </ModalPopup>

    <!-- Popup to change Mid Category -->
    <ModalPopup
      :title="$t('editCategory')"
      ident="editMidCategory"
      :width="'600px'"
      @send="editMinCategory"
      @cancelModal="cancelEditCategory"
      ref="modalEditMidCategory"
    >
      <EditMidCategory ref="editMidCategory" />
    </ModalPopup>

    <!-- Popup to change Final Category -->
    <ModalPopup
      :title="$t('editCategory')"
      ident="editFinalCategory"
      :width="'600px'"
      @send="editFinalCategory"
      @cancelModal="cancelEditCategory"
      ref="modalEditFinalCategory"
    >
      <EditMidCategory ref="editFinalCategory" />
    </ModalPopup>

    <!-- Popup to Delete Category -->
    <ModalPopup
      :title="$t('deleteCategory')"
      ident="deleteCategory"
      :width="'500px'"
      @send="deleteCategory"
      ref="modalDeleteCategory"
    >
      <deleteCategory />
    </ModalPopup>

    <!-- Loading For Add Category -->
    <div v-if="loadingCategory" class="loading load" :style="{ zIndex: 2000 }">
      <div class="text-center">
        <img src="@/assets/images/Pulse.svg" :style="{ width: '120px' }" />
      </div>
    </div>
  </div>
</template>

<script>
import SearchingByStatus from "../global/SearchingByStatus.vue";
import GridTable from "@/components/categories/GridTable.vue";
import ModalPopup from "../global/ModalPopup.vue";
import AddCategory from "./AddCategory.vue";
import EditSort from "./EditSort.vue";
import EditCategory from "./EditCategory.vue";
import EditMidCategory from "./EditMidCategory.vue";
import DeleteCategory from "./DeleteCategory.vue";
import AddMidCategory from "./AddMidCategory.vue";
import saveInfoAlert from "@/assets/js/saveInfoAlert";
import axios from "axios";
import { mapActions, mapState } from "pinia";
import { useFetchAll } from "@/stores/FetchAll";
import PaginationBox from "../global/PaginationBox.vue";

export default {
  name: "TableSpecification",
  components: {
    GridTable,
    ModalPopup,
    AddCategory,
    EditSort,
    EditCategory,
    EditMidCategory,
    AddMidCategory,
    SearchingByStatus,
    DeleteCategory,
    PaginationBox,
  },
  data() {
    return {
      loadingCategory: false,
      records: 10,
      searchInfo: [],
    };
  },
  computed: {
    ...mapState(useFetchAll, [
      "category",
      "categoriesLength",
      "parentId",
      "catId",
      "levelCat",
      "catSort",
      "lastSort",
      "pages",
      "current_page",
      "next_page",
      "prev_page",
    ]),
  },
  methods: {
    ...mapActions(useFetchAll, [
      "fechCategory",
      "emptyCategoryInfo",
      "emptyCategory",
    ]),
    search(payload) {
      this.fechCategory(this.records, 1, payload[0], payload[1]);
      this.searchInfo = payload;
    },
    refreshSearching() {
      this.fechCategory(this.records, 1);
    },
    async addNewCategory() {
      const data = new FormData();
      if (this.$refs.addcategory.sort == "") {
        this.$refs.addcategory.sort = this.categoriesLength;
      }
      if (this.$refs.addcategory.icon.length) {
        data.append("name_ar", this.$refs.addcategory.titleAR);
        data.append("name_en", this.$refs.addcategory.titleEN);
        data.append("order_number", 1);
        data.append("sort", this.$refs.addcategory.sort);
        data.append("icon", this.$refs.addcategory.file);
        data.append("commission_type", "fixed");
        data.append("status", "1");
        data.append("recommended", "1");
        this.loadingCategory = true;
        await axios
          .post("dashboard/categories", data)
          .then((res) => {
            saveInfoAlert(this.$i18n.locale, "success", res.data.message);
            this.$refs.modalAddCategory.categoryModal.closeModalButton.click();
            this.fechCategory(this.records, this.current_page);
            // window.location.reload();
          })
          .catch((err) => {
            saveInfoAlert(
              this.$i18n.locale,
              "error",
              err.response.data.message
            );
          })
          .finally(() => {
            this.loadingCategory = false;
          });
      } else {
        saveInfoAlert(this.$i18n.locale, "error", this.$t("pleaseUploadIcon"));
      }
    },
    cancelAddCategory() {
      this.$refs.addcategory.titleAR = "";
      this.$refs.addcategory.titleEN = "";
      this.$refs.addcategory.icon = [];
      this.$refs.addcategory.iconSize = false;
      this.$refs.addcategory.iconDimension = false;
      this.$refs.addcategory.file = null;
    },
    async addMidCategory() {
      this.loadingCategory = true;
      if (this.$refs.addMidCategory.sort == "") {
        this.$refs.addMidCategory.sort = this.lastSort;
      }
      await axios
        .post("dashboard/categories", {
          name_ar: this.$refs.addMidCategory.nameAR,
          name_en: this.$refs.addMidCategory.nameEN,
          order_number: +this.levelCat + 1,
          sort: this.$refs.addMidCategory.sort,
          commission_type: "fixed",
          status: 1,
          recommended: 1,
          parent_id: this.catId,
        })
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
          this.$refs.modalAddMidCategory.closeModalButton.click();
          this.fechCategory(this.records, this.current_page);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        })
        .finally(() => {
          this.loadingCategory = false;
        });
    },
    cancelAddMidCat() {
      this.$refs.addMidCategory.nameAR = "";
      this.$refs.addMidCategory.nameEN = "";
      this.$refs.addMidCategory.sort = "";
    },
    async addFinalCategory() {
      this.loadingCategory = true;
      if (this.$refs.addFinalCategory.sort == "") {
        this.$refs.addFinalCategory.sort = this.lastSort;
      }
      await axios
        .post("dashboard/categories", {
          name_ar: this.$refs.addFinalCategory.nameAR,
          name_en: this.$refs.addFinalCategory.nameEN,
          order_number: +this.levelCat + 1,
          sort: this.$refs.addFinalCategory.sort,
          commission_type: "fixed",
          status: 1,
          recommended: 1,
          parent_id: this.catId,
        })
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
          this.$refs.modalAddFinalCategory.closeModalButton.click();
          this.fechCategory(this.records, this.current_page);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        })
        .finally(() => {
          this.loadingCategory = false;
        });
    },
    cancelAddFinalCat() {
      this.$refs.addFinalCategory.nameAR = "";
      this.$refs.addFinalCategory.nameEN = "";
      this.$refs.addFinalCategory.sort = "";
    },
    async editSort() {
      if (this.$refs.editSort.sort == "") {
        this.$refs.editSort.sort = this.catSort;
      }
      await axios
        .post(`dashboard/categories/${this.catId}/update-sort`, {
          sort: this.$refs.editSort.sort,
        })
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
          this.$refs.modalEditSort.closeModalButton.click();
          this.fechCategory(this.records, this.current_page);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        });
    },
    cancelEditSort() {
      this.emptyCategory();
    },
    async editCategory() {
      const data = new FormData();
      if (this.$refs.editCategory.titleAR == "") {
        this.$refs.editCategory.titleAR = this.category.name_ar;
      }
      if (this.$refs.editCategory.titleEN == "") {
        this.$refs.editCategory.titleEN = this.category.name_en;
      }
      if (this.$refs.editCategory.sort == "") {
        this.$refs.editCategory.sort = this.category.sort;
      }
      data.append("name_ar", this.$refs.editCategory.titleAR);
      data.append("name_en", this.$refs.editCategory.titleEN);
      data.append("order_number", 1);
      data.append("sort", this.$refs.editCategory.sort);
      if (this.$refs.editCategory.file) {
        data.append("icon", this.$refs.editCategory.file);
      }
      this.loadingCategory = true;
      await axios
        .post(`dashboard/categories/${this.category.id}/update`, data)
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
          this.$refs.modalEditCategory.closeModalButton.click();
          this.fechCategory(this.records, this.current_page);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        })
        .finally(() => {
          this.loadingCategory = false;
        });
    },
    async editMinCategory() {
      if (this.$refs.editMidCategory.titleAR == "") {
        this.$refs.editMidCategory.titleAR = this.category.name_ar;
      }
      if (this.$refs.editMidCategory.titleEN == "") {
        this.$refs.editMidCategory.titleEN = this.category.name_en;
      }
      if (this.$refs.editMidCategory.sort == "") {
        this.$refs.editMidCategory.sort = this.category.sort;
      }
      this.loadingCategory = true;
      await axios
        .post(`dashboard/categories/${this.category.id}/update`, {
          name_ar: this.$refs.editMidCategory.titleAR,
          name_en: this.$refs.editMidCategory.titleEN,
          order_number: 2,
          sort: this.$refs.editMidCategory.sort,
          parent_id: this.parentId,
        })
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
          this.$refs.modalEditMidCategory.closeModalButton.click();
          this.fechCategory(this.records, this.current_page);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        })
        .finally(() => {
          this.loadingCategory = false;
        });
    },
    async editFinalCategory() {
      if (this.$refs.editFinalCategory.titleAR == "") {
        this.$refs.editFinalCategory.titleAR = this.category.name_ar;
      }
      if (this.$refs.editFinalCategory.titleEN == "") {
        this.$refs.editFinalCategory.titleEN = this.category.name_en;
      }
      if (this.$refs.editFinalCategory.sort == "") {
        this.$refs.editFinalCategory.sort = this.category.sort;
      }
      this.loadingCategory = true;
      await axios
        .post(`dashboard/categories/${this.category.id}/update`, {
          name_ar: this.$refs.editFinalCategory.titleAR,
          name_en: this.$refs.editFinalCategory.titleEN,
          order_number: 3,
          sort: this.$refs.editFinalCategory.sort,
          parent_id: this.parentId,
        })
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
          this.$refs.modalEditFinalCategory.closeModalButton.click();
          this.fechCategory(this.records, this.current_page);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        })
        .finally(() => {
          this.loadingCategory = false;
        });
    },
    cancelEditCategory() {
      this.emptyCategoryInfo();
    },
    async deleteCategory() {
      await axios
        .delete(`dashboard/categories/${this.catId}`)
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
          this.fechCategory(this.records, this.current_page);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        })
        .finally(() => {
          this.$refs.modalDeleteCategory.closeModalButton.click();
        });
    },
    chooseRecord(records) {
      this.records = records;
      this.fechCategory(records, 1, this.searchInfo[0], this.searchInfo[1]);
    },
    thisPage(p) {
      this.fechCategory(
        this.records,
        p,
        this.searchInfo[0],
        this.searchInfo[1]
      );
    },
    nextPage() {
      this.thisPage(this.current_page + 1);
    },
    prevPage() {
      this.thisPage(this.current_page - 1);
    },
  },
  watch: {
    "$i18n.locale"() {
      this.fechCategory(10, 1);
    },
  },
  mounted() {
    this.fechCategory(10, 1);
  },
};
</script>

<style scoped></style>
