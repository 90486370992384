import Swal from "sweetalert2";
export default function saveInfoAlert(locale, icon, message) {
  const Toast = Swal.mixin({
    toast: true,
    position: locale == "ar" ? "top-start" : "top-end",
    showConfirmButton: false,
    timer: icon == "error" ? 3200 : 1500,
    timerProgressBar: false,
  });
  Toast.fire({
    icon,
    title: message,
  });
}
