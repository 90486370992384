<template>
  <div class="nav-bar" :class="{ rtl: $i18n.locale == 'ar' }">
    <div class="d-flex gap-4 align-items-center">
      <!-- Show Date Today -->
      <div class="date font-poppins d-sm-block d-none">{{ homeData.time }}</div>
      <!-- <div>
        <div class="date">{{ formatDate }}</div>
        <div class="date">{{ formatTime }}</div>
      </div> -->

      <div class="d-flex align-items-center gap-md-4 gap-3">
        <!-- Notification -->
        <!-- <div class="bell position-relative">
          <i class="fa-solid fa-bell"></i>
          <span>{{ thereIsNotification }}</span>
        </div> -->

        <!-- Languages -->
        <div
          class="languages d-flex align-items-center gap-sm-2 gap-1"
          @click="changeLang"
        >
          <p v-if="$i18n.locale == 'en'" class="font-tajawal">اللغة العربية</p>
          <p v-else class="font-poppins">English</p>
          <span class="material-icons"> language </span>
        </div>

        <!-- User And Change Password -->
        <div class="d-flex align-items-center gap-3">
          <div class="image d-sm-block d-none">
            <img src="@/assets/images/user.png" alt="admin" />
          </div>
          <div class="admin position-relative">
            <button
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              class="text-capitalize font-poppins"
            >
              {{ adminName }}
            </button>
            <div class="dropdown-menu">
              <router-link
                :to="`/${$i18n.locale}/change-password`"
                :style="[$i18n.locale == 'en' ? 'right : 0' : 'left : 0']"
              >
                {{ $t("changePassword") }}
              </router-link>
              <button type="button" @click="logOut">{{ $t("logout") }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <teleport to="body">
      <div v-if="loading" class="loading">
        <div class="text-center">
          <img src="@/assets/images/double.svg" alt="spinner" />
          <h3>Loading...</h3>
          <p>Please, Wait...</p>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import saveInfoAlert from "@/assets/js/saveInfoAlert";
import { mapState } from "pinia";
import { useHomePage } from "@/stores/FetchHomePage";
// import { usePusherStore } from "@/stores/PusherNotification";
import axios from "axios";

export default {
  name: "NavBar",
  data() {
    return {
      loading: false,
      adminName: "",
    };
  },
  computed: {
    ...mapState(useHomePage, ["homeData"]),
    // ...mapState(usePusherStore, ["thereIsNotification"]),
    formatDate() {
      let d = new Date(),
        year = d.getFullYear(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [day, month, year].join(".");
    },
    formatTime() {
      let d = new Date(),
        hour = "" + d.getHours(),
        minute = "" + d.getMinutes(),
        pmAm = "";
      if (hour > 12) {
        if (this.$i18n.locale == "en") {
          pmAm = "Pm";
        } else {
          pmAm = "مساءً";
        }
      } else {
        if (minute.length < 2) minute = "0" + minute;
        if (this.$i18n.locale == "en") {
          pmAm = "Am";
        } else {
          pmAm = "صباحاً";
        }
      }
      const time = [hour, minute].join(":");
      return time + " " + pmAm;
    },
  },
  methods: {
    // A function to convert from one language to another
    changeLang() {
      if (this.$i18n.locale == "en") {
        this.$i18n.locale = "ar";
        this.$router.push({
          params: { lang: "ar" },
        });
      } else {
        this.$i18n.locale = "en";
        this.$router.push({
          params: { lang: "en" },
        });
      }
    },
    async logOut() {
      this.loading = true;
      await axios
        .delete("dashboard/logout")
        .then(() => {
          localStorage.removeItem("topz_admin_token");
          localStorage.removeItem("topz_admin_permissions");
          this.$router.push(`/${this.$i18n.locale}/login`);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
          // if (err.response.status == 401) {
          //   localStorage.removeItem("topz_admin_token");
          //   localStorage.removeItem("topz_admin_permissions");
          //   this.$router.push(`/${this.$i18n.locale}/login`);
          // } else {
          //   saveInfoAlert(
          //     this.$i18n.locale,
          //     "error",
          //     err.response.data.message
          //   );
          // }
          this.loading = false;
        });
    },
  },
  mounted() {
    this.adminName = localStorage.getItem("topz_admin_name");
  },
};
</script>

<style scoped>
@import "@/assets/css/navBar.css";
</style>
