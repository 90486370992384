<template>
  <div
    class="modal fade"
    id="removeVideo"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content popup-box">
        <!-- Header -->
        <div class="modal-header justify-content-center">
          <h1 class="modal-title mb-sm-5 mb-4" id="staticBackdropLabel">
            {{ $t("areYouSuredeleteVideo") }}
          </h1>
        </div>
        <!-- Footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-blue" data-bs-dismiss="modal">
            {{ $t("cancel") }}
          </button>
          <button
            type="button"
            class="btn btn-blue remove"
            data-bs-dismiss="modal"
            @click="$emit('deleteVideo')"
          >
            {{ $t("yes") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeleteVideo",
};
</script>

<style scoped>
.modal .modal-dialog {
  max-width: 600px;
}
.modal .modal-dialog h1 {
  font-size: 20px;
}
.modal .modal-footer button.remove {
  background-color: #f73d17;
}

@media (max-width: 575px) {
  .modal .modal-dialog h1 {
    font-size: 16px;
  }
}
</style>
