// import axios from "axios";
import axios from "axios";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useHomePage = defineStore("home", () => {
  const homeData = ref([]);
  const error = ref(null);

  const fetchData = async () => {
    await axios
      .post("dashboard/home")
      .then((res) => {
        homeData.value = res.data.data;
      })
      .catch((err) => {
        error.value = err.response.data.message;
      });
  };

  return { homeData, fetchData, error };
});
