<template>
  <div
    v-for="ad in adsList"
    :key="ad.id"
    class="ad d-flex justify-content-between align-items-center mb-4 gap-1 flex-md-row flex-column"
  >
    <div class="d-flex align-items-center gap-3 flex-md-row flex-column">
      <button
        type="button"
        class="btn image-popup"
        data-bs-toggle="modal"
        :data-bs-target="'#' + ad.id"
      >
        <img :src="ad.cover_image" />
      </button>
      <PopupImage :img="ad.cover_image" :ident="ad.id" />

      <p>{{ ad.title }}</p>
    </div>
    <div>
      {{ ad.created_at }}
    </div>
    <div class="text-end">{{ ad.user.name }}</div>
  </div>
</template>

<script>
import PopupImage from "@/components/global/PopupImage.vue";
export default {
  name: "AdBox",
  props: ["adsList"],
  components: {
    PopupImage,
  },
};
</script>

<style scoped>
.ad {
  font-family: "Poppins", sans-serif;
}
.ad > div:first-child {
  flex: 2;
}
.ad > div:last-child,
.ad > div:nth-child(2) {
  flex: 1;
}
@media (max-width: 767px) {
  .ad {
    border: 1px solid #eee;
    font-family: "Poppins", sans-serif;
    padding: 17px;
    border-radius: 10px;
    font-size: 14px;
    box-shadow: 4px 4px 10px #efefef;
    transition: var(--transition);
  }
  .ad:hover {
    box-shadow: 4px 4px 15px #ccc;
  }
}
button.image-popup {
  width: 65px;
  height: 65px;
}
button.image-popup img {
  border-radius: 10px;
}
</style>
