<template>
  <div class="custom-box chart">
    <div
      class="d-flex align-items-xxl-center justify-content-between flex-column flex-xxl-row align-items-start"
    >
      <h4 v-text="$t('onSaleOnStop')"></h4>
      <div
        ref="time"
        class="time flex-column flex-lg-row flex-wrap"
        :class="{ 'hide-time': loading }"
      >
        <button
          class="btn btn-orange active"
          @click="choose"
          v-text="$t('thisYear')"
          id="this_year"
        ></button>
        <button
          class="btn btn-orange"
          @click="choose"
          v-text="$t('lastYear')"
          id="last_year"
        ></button>
        <button
          class="btn btn-orange"
          @click="choose"
          v-text="$t('thisWeek')"
          id="this_week"
        ></button>
        <div class="date d-flex align-items-center gap-md-3 gap-2 rad-10">
          <VueDatePicker
            class="date-picker"
            v-model="start_date"
            :enable-time-picker="false"
            format="yyyy.MM.dd"
            selectText="OK"
            @update:model-value="getStartDate"
          />
          <i
            class="bi arrow"
            :class="
              this.$i18n.locale == 'en' ? 'bi-arrow-right' : 'bi-arrow-left'
            "
          ></i>
          <VueDatePicker
            class="date-picker"
            v-model="end_date"
            :enable-time-picker="false"
            format="yyyy.MM.dd"
            selectText="OK"
            @update:model-value="getEndDate"
          />
        </div>
      </div>
    </div>
    <div class="legend d-flex mb-4">
      <span class="sale position-relative" v-text="$t('sale')"></span>
      <span class="stop position-relative" v-text="$t('stop')"></span>
    </div>
    <div class="chartBox">
      <canvas ref="canvas"></canvas>
    </div>
  </div>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Chart from "chart.js/auto";
import chooseItem from "@/assets/js/chooseItem";
import axios from "axios";
import saveInfoAlert from "@/assets/js/saveInfoAlert";

export default {
  name: "SaleChart",
  components: { VueDatePicker },
  data() {
    return {
      start_date: null,
      date1: null,
      end_date: null,
      date2: null,
      myChart: null,
      chartData: [],
      loading: false,
    };
  },
  methods: {
    getStartDate(date) {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      this.date1 = `${day}-${month}-${year}`;
    },
    async getEndDate(date) {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      this.date2 = `${day}-${month}-${year}`;

      this.loading = true;
      await axios
        .post("dashboard/home", {
          start_date: this.start_date,
          end_date: this.end_date,
        })
        .then((res) => {
          this.chartData = res.data.data.chart;
          this.showChart();
          setTimeout(() => (this.loading = false), 1200);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
          this.loading = false;
        });
    },
    async choose($event) {
      chooseItem(".active", $event);
      this.fetchChartData($event.target.id);
    },
    async fetchChartData(groupBy) {
      this.loading = true;
      await axios
        .post("dashboard/home", {
          groupBy,
        })
        .then((res) => {
          this.chartData = res.data.data.chart;
          this.showChart();

          setTimeout(() => (this.loading = false), 1200);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
          this.loading = false;
        });
    },
    showChart() {
      const labels = [];
      const onSale = [];
      const onStop = [];
      for (const value in this.chartData) {
        labels.push(value);
        onSale.push(this.chartData[value].on_sale);
        onStop.push(this.chartData[value].on_stop);
      }

      Chart.defaults.color = "#717171";
      // Chart.defaults.font.size = 16;
      if (this.myChart !== null) {
        this.myChart.destroy();
      }
      this.myChart = new Chart(this.$refs.canvas, {
        type: "bar",
        data: {
          labels,
          datasets: [
            {
              label: "On Sale",
              data: onSale,
              backgroundColor: "#F7881F",
              borderRadius: 10,
            },
            {
              label: "On Stop",
              data: onStop,
              backgroundColor: "#FEF3E9",
              borderRadius: 10,
            },
          ],
        },

        options: {
          scales: {
            y: {
              beginAtZero: true,
              border: {
                dash: [7],
                width: 1,
              },
            },
            x: {
              border: {
                dash: [7],
                width: 1,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
              // align: "start",
              // labels: {
              //   usePointStyle: true,
              //   pointStyle: "circle",
              //   color: "#353535",
              //   boxWidth: 15,
              //   boxHeight: 15,
              //   font: {
              //     family: "Poppins",
              //   },
              // },
            },
          },
          maintainAspectRatio: false,
        },
      });
    },
  },
  mounted() {
    this.fetchChartData("this_year");
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    this.start_date = `${year}-${month}-${day}`;
    this.end_date = `${year}-${month}-${day}`;
  },
};
</script>

<style scoped>
@import "@/assets/css/chartBox.css";
</style>
