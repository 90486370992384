<template>
  <div class="protocols">
    <h4 class="title">{{ $t("listHelp[0].title") }}</h4>
    <div class="d-flex flex-md-row flex-column gap-4">
      <button
        type="button"
        data-constant="commission"
        class="btn rad-10 active"
        @click="choose($event, 'commission')"
      >
        {{ $t("commission") }}
      </button>
      <button
        type="button"
        data-constant="return_policy"
        class="btn rad-10"
        @click="choose($event, 'return_policy')"
      >
        {{ $t("returnPolicy") }}
      </button>
      <button
        type="button"
        data-constant="use_of_terms"
        class="btn rad-10"
        @click="choose($event, 'use_of_terms')"
      >
        {{ $t("useOfTerms") }}
      </button>
      <button
        type="button"
        data-constant="about_us"
        class="btn rad-10"
        @click="choose($event, 'about_us')"
      >
        {{ $t("aboutUs") }}
      </button>
    </div>
    <form @submit.prevent="save">
      <div class="mt-5 mb-5">
        <span>{{ $t("ar") }}</span>
        <NotePad
          :placeholder="'من فضلك اكتب محتوى الاتفاقية هنا باللغة العربية'"
          :align="'right'"
          :font="'Tajawal, sans-serif'"
          :content="contentAr"
          ref="agreementAr"
        />
      </div>
      <div>
        <span>{{ $t("en") }}</span>
        <NotePad
          :placeholder="'Please Write The Agreement Contents In English Here'"
          :align="'left'"
          :font="'Poppins, sans-serif'"
          :content="contentEn"
          ref="agreementEn"
        />
      </div>
      <button
        class="btn btn-orange mt-sm-5 mt-4"
        :class="{ rtl: $i18n.locale == 'ar' }"
      >
        {{ $t("save") }}
      </button>
    </form>
    <div v-if="loading" class="loading load">
      <div class="text-center">
        <img src="@/assets/images/loading.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import NotePad from "@/components/helpCenter/NotePad.vue";
import chooseItem from "@/assets/js/chooseItem";
import saveInfoAlert from "@/assets/js/saveInfoAlert";
import axios from "axios";

export default {
  name: "AgreementsManag",
  components: {
    NotePad,
  },
  data() {
    return {
      btnId: "commission",
      loading: false,
      commissionContent: "",
      policyContent: "",
      termsContent: "",
      aboutContent: "",
      contentEn: "",
      contentAr: "",
    };
  },
  methods: {
    async getAgreement(type, lang) {
      this.loading = true;
      await axios
        .get(`dashboard/constant/${type}`, {
          headers: {
            "Accept-Language": lang,
          },
        })
        .then((res) => {
          if (lang == "en") {
            this.contentEn = res.data.data.content;
          } else {
            this.contentAr = res.data.data.content;
          }
        })
        .catch((err) => {
          console.log(err);

          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async choose($event, type) {
      chooseItem(".active", $event);
      this.btnId = $event.target.dataset.constant;
      this.getAgreement(type, "en");
      this.getAgreement(type, "ar");
    },
    async save() {
      this.loading = true;
      await axios
        .post(`dashboard/constant/${this.btnId}`, {
          content_ar: this.$refs.agreementAr.text,
          content_en: this.$refs.agreementEn.text,
        })
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  async mounted() {
    this.getAgreement("commission", "en");
    this.getAgreement("commission", "ar");
  },
};
</script>

<style scoped>
.protocols > div button {
  font-size: 20px;
  font-weight: 500;
  color: var(--black);
  background-color: #fff;
  box-shadow: 0 4px 4px rgb(0 0 0 / 10%);
  transition: var(--transition);
  width: 200px;
  padding: 12px;
}
@media (max-width: 767px) {
  .protocols > div button {
    width: 100%;
    font-size: 15px;
  }
}
.protocols > div button:active,
.protocols > div button.active {
  background-color: var(--primary-orange);
  box-shadow: none;
  color: var(--white);
  border-color: #fff;
}
.protocols > div button.active {
  pointer-events: none;
}
.protocols span {
  font-weight: 500;
  color: var(--black-alt);
  margin-block: 10px;
}
.protocols form button {
  width: 230px;
  margin-left: auto;
  margin-right: 65px;
  margin-bottom: 65px;
}
.protocols form button.rtl {
  width: 230px;
  margin-right: auto;
  margin-left: 65px;
}
@media (max-width: 991px) {
  .protocols form button {
    margin-right: 0;
  }
  .protocols form button.rtl {
    margin-left: 0;
  }
}
@media (max-width: 575px) {
  .protocols form button {
    width: 100%;
  }
}
</style>
