<template>
  <div>
    <!-- Searching -->
    <SearchingByStatus
      :ident="'addSpecifications'"
      :add="$t('addNewSpecification')"
      :width="'800px'"
      :placeholder="$t('enterSpecificationName')"
      @search="searchSpecification"
      @send="addNewSpec"
      @cancelModal="cancelSpec"
      @refresh="refreshSearching"
      ref="modalAddSpec"
    >
      <AddSpecification
        ref="addSpec"
        id="#addChooseClass"
        @chooseClass="chooseClass"
      />
    </SearchingByStatus>
    <ChooseClass id="addChooseClass" ref="addChooseClass" />

    <!-- Specification Table -->
    <div class="responsive-table">
      <table class="custom-table table-bordered">
        <thead>
          <tr>
            <th scope="col" v-for="head in 7" :key="head">
              {{ $t(`listSpecificationTable[${head - 1}]`) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="spec in specifications" :key="spec.id">
            <td>{{ spec.name }}</td>
            <td>{{ spec.description }}</td>
            <td>{{ spec.type }}</td>
            <td>
              <input
                type="checkbox"
                class="custom-toggle"
                :defaultChecked="spec.visible"
                @change="
                  changeStatus(
                    `dashboard/specifications/${spec.id}/change-status`
                  )
                "
              />
            </td>
            <td>
              <input
                type="checkbox"
                class="custom-toggle"
                :defaultChecked="spec.is_required"
                @change="
                  changeStatus(
                    `dashboard/specifications/${spec.id}/change-mandatory`
                  )
                "
              />
            </td>
            <td>
              <span
                class="d-inline-block"
                v-for="def in spec.default"
                :key="def"
              >
                {{ def }}&nbsp;,&nbsp;&nbsp;
              </span>
            </td>
            <td>
              <div class="d-flex justify-content-evenly gap-3">
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#editSpec"
                  class="bg-transparent"
                  @click="editSpecification(spec.id)"
                >
                  <img src="@/assets/images/edit.svg" alt="edit" />
                </button>
                <button
                  type="button"
                  class="bg-transparent"
                  @click="removeSpec(spec.id)"
                >
                  <img src="@/assets/images/trash.svg" alt="remove" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Pagination -->
    <PaginationBox
      :pages="pages"
      :current_page="current_page"
      :next_page="next_page"
      :prev_page="prev_page"
      @chooseRec="chooseRecord"
      @thisPage="thisPage"
      @nextPage="nextPage"
      @prevPage="prevPage"
    />

    <!-- Edit Specification -->
    <ModalPopup
      :title="$t('editSpecification')"
      :ident="'editSpec'"
      :width="'800px'"
      @send="confirmEditSpec"
      @cancelModal="cancelEditSpec"
      ref="modalEditSpec"
    >
      <AddSpecification ref="editSpec" id="#editChooseClass" :idSpec="idSpec" />
    </ModalPopup>

    <!-- Choose Classes From Table (Modal-Popup) -->
    <ChooseClass id="editChooseClass" ref="editChooseClass" />

    <!-- Loading For Refresh Table -->
    <Teleport to="body">
      <div v-if="loading" class="loading load">
        <div class="text-center">
          <img src="@/assets/images/loading.svg" alt="spinner" />
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapState } from "pinia";
import { useFetchAll } from "@/stores/FetchAll";
import { useSpecClasses } from "@/stores/SpecClasses";
import saveInfoAlert from "@/assets/js/saveInfoAlert";

import ModalPopup from "../global/ModalPopup.vue";
import AddSpecification from "@/components/specifications/AddSpecification.vue";
import ChooseClass from "./ChooseClass.vue";
import SearchingByStatus from "../global/SearchingByStatus.vue";
import PaginationBox from "../global/PaginationBox.vue";

export default {
  name: "TableSpecification",
  components: {
    ModalPopup,
    AddSpecification,
    ChooseClass,
    SearchingByStatus,
    PaginationBox,
  },
  data() {
    return {
      specifications: [],
      idSpec: "",
      itemSpec: {},
      loading: false,

      url: "",
      search: [],

      records: 10,
      pages: "",
      current_page: "",
      next_page: "",
      prev_page: "",

      clickChooseClass: false,
    };
  },
  computed: {
    ...mapState(useSpecClasses, ["ids"]),
    // ...mapState(useFetchAll, ["specifications", "loading"]),
  },
  methods: {
    ...mapActions(useFetchAll, ["fechCategory"]),
    ...mapActions(useSpecClasses, ["addIds", "reset"]),
    async fechSpecifications(record, page, name, status) {
      this.loading = true;
      if (name && status) {
        this.url = `dashboard/specifications?records=${record}&page=${page}&name=${name}&visible=${status}`;
      } else if (name) {
        this.url = `dashboard/specifications?records=${record}&page=${page}&name=${name}`;
      } else if (status) {
        this.url = `dashboard/specifications?records=${record}&page=${page}&visible=${status}`;
      } else {
        this.url = `dashboard/specifications?records=${record}&page=${page}`;
      }
      await axios
        .get(this.url)
        .then((res) => {
          this.specifications = res.data.data;
          this.pages = res.data.pagination.last_page;
          this.current_page = res.data.pagination.current_page;
          this.next_page = res.data.pagination.next_page_url;
          this.prev_page = res.data.pagination.prev_page_url;
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async searchSpecification(payload) {
      this.fechSpecifications(this.records, 1, payload[0], payload[1]);
      this.search = payload;
    },
    refreshSearching() {
      this.fechSpecifications(this.records, 1);
    },
    async addNewSpec() {
      const defaultEn = this.$refs.addSpec.defEn.split("\n");
      const defaultAr = this.$refs.addSpec.defAr.split("\n");
      await axios
        .post("dashboard/specifications", {
          name_ar: this.$refs.addSpec.nameAr,
          name_en: this.$refs.addSpec.nameEn,
          description_ar: this.$refs.addSpec.descAr,
          description_en: this.$refs.addSpec.descEn,
          type: this.$refs.addSpec.type,
          default_ar: defaultAr,
          default_en: defaultEn,
          is_required: this.$refs.addSpec.required ? 1 : 0,
          visible: this.$refs.addSpec.visible ? 1 : 0,
          categories: this.$refs.addChooseClass.ids,
        })
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
          this.$refs.modalAddSpec.categoryModal.closeModalButton.click();
          this.fechSpecifications(this.records, this.current_page);
        })
        .catch(() => {
          saveInfoAlert(
            this.$i18n.locale,
            "error",
            this.$t("pleaseChooseClasses")
          );
        });
    },
    chooseClass() {
      this.clickChooseClass = true;
    },
    cancelSpec() {
      setTimeout(() => {
        if (!this.clickChooseClass) {
          this.$refs.addChooseClass.ids = [];
          this.$refs.addSpec.nameEn = "";
          this.$refs.addSpec.nameAr = "";
          this.$refs.addSpec.descEn = "";
          this.$refs.addSpec.descAr = "";
          this.$refs.addSpec.defEn = "";
          this.$refs.addSpec.defAr = "";
          this.$refs.addSpec.type = "checkbox";
          this.$refs.addSpec.required = true;
          this.$refs.addSpec.visible = true;
          const items = document.querySelectorAll(".table-classes input");
          items.forEach((el) => {
            el.checked = false;
          });
        }
      }, 100);
      setTimeout(() => {
        this.clickChooseClass = false;
      }, 200);
    },

    async editSpecification(id) {
      this.idSpec = id;
      let defaultEn = "";
      let defaultAr = "";
      await axios
        .get("dashboard/specifications/" + id)
        .then((res) => {
          this.itemSpec = res.data.data;
          if (this.itemSpec.default_en) {
            defaultEn = this.itemSpec.default_en.join("\n");
            defaultAr = this.itemSpec.default_ar.join("\n");
          }
          this.$refs.editSpec.nameEn = this.itemSpec.name_en;
          this.$refs.editSpec.nameAr = this.itemSpec.name_ar;
          this.$refs.editSpec.descEn = this.itemSpec.description_en;
          this.$refs.editSpec.descAr = this.itemSpec.description_ar;
          this.$refs.editSpec.type = this.itemSpec.type;
          this.$refs.editSpec.defEn = defaultEn;
          this.$refs.editSpec.defAr = defaultAr;
          this.$refs.editSpec.required = this.itemSpec.is_required;
          this.$refs.editSpec.visible = this.itemSpec.visible;
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        });
    },
    async confirmEditSpec() {
      let ids = [];
      let defaultEn = null;
      let defaultAr = null;
      if (this.$refs.editSpec.defEn) {
        defaultEn = this.$refs.editSpec.defEn.split("\n");
        defaultAr = this.$refs.editSpec.defAr.split("\n");
      }
      if (this.$refs.editChooseClass.ids.length) {
        ids = this.$refs.editChooseClass.ids;
      } else {
        this.itemSpec.categories.forEach((el) => {
          ids.push(el.id);
        });
      }
      await axios
        .post(`dashboard/specifications/${this.idSpec}/update`, {
          name_ar: this.$refs.editSpec.nameAr,
          name_en: this.$refs.editSpec.nameEn,
          description_ar: this.$refs.editSpec.descAr,
          description_en: this.$refs.editSpec.descEn,
          type: this.$refs.editSpec.type,
          default_ar: defaultAr,
          default_en: defaultEn,
          is_required: this.$refs.editSpec.required ? "1" : "0",
          visible: this.$refs.editSpec.visible ? "1" : "0",
          categories: ids,
        })
        .then(() => {
          saveInfoAlert(
            this.$i18n.locale,
            "success",
            this.$t("addedSuccessfully")
          );
          this.$refs.modalEditSpec.closeModalButton.click();
          this.fechSpecifications(this.records, this.current_page);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        });
    },
    cancelEditSpec() {
      this.$refs.editChooseClass.ids = [];
      const items = document.querySelectorAll(".edit-table-classes input");
      items.forEach((el) => {
        el.checked = false;
      });
    },
    async changeStatus(url) {
      await axios
        .post(url)
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        });
    },
    async removeSpec(id) {
      await axios
        .delete(`dashboard/specifications/${id}`)
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
          this.fechSpecifications(this.records, this.current_page);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        });
    },
    chooseRecord(records) {
      this.records = records;
      this.fechSpecifications(records, 1, this.search[0], this.search[1]);
    },
    thisPage(p) {
      this.fechSpecifications(this.records, p, this.search[0], this.search[1]);
    },
    nextPage() {
      this.thisPage(this.current_page + 1);
    },
    prevPage() {
      this.thisPage(this.current_page - 1);
    },
  },

  mounted() {
    this.fechSpecifications(10, 1);
    this.fechCategory(10000, 1);
  },
  watch: {
    "$i18n.locale"() {
      this.fechSpecifications(this.records, this.current_page);
      this.fechCategory(10000, 1);
    },
  },
};
</script>

<style scoped>
table {
  min-width: 1200px;
}
.custom-table th,
.custom-table td {
  text-transform: none;
}
</style>
