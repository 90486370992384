<template>
  <router-link :to="link" class="card card-body" @click="closeSidebar">
    <!-- {{ $t(link.title) }} -->
    <slot />
  </router-link>
</template>

<script>
export default {
  name: "SubLink",
  props: ["link"],
  methods: {
    closeSidebar() {
      document.getElementById("sidebar").classList.remove("show");
    },
  },
};
</script>

<style scoped>
a {
  border: none;
  font-size: 16px;
  padding: 0;
  margin-bottom: 25px;
  font-weight: 500;
  transition: var(--transition);
  color: var(--black);
}
a.router-link-exact-active,
a:hover {
  color: var(--primary-orange);
}
@media (max-width: 767px) {
  a {
    font-size: 14px;
    margin-bottom: 20px;
  }
}
</style>
