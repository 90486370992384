<template>
  <div class="online-pay box-info position-relative">
    <input
      type="checkbox"
      class="custom-toggle position-absolute"
      :class="{
        rtl: $i18n.locale === 'ar',
      }"
      v-model="isOnlinePayment"
    />

    <h5 class="sub-title">{{ $t("onlinePay[0]") }}</h5>
    <div>
      <label for="profileID" class="form-label">{{ $t("onlinePay[1]") }}</label>
      <input
        type="text"
        class="form-control input-text"
        id="profileID"
        v-model="profileID"
        :disabled="!isOnlinePayment"
        autocomplete="off"
      />
    </div>
    <div>
      <label for="APIkey" class="form-label">{{ $t("onlinePay[2]") }}</label>
      <input
        type="text"
        class="form-control input-text"
        id="APIkey"
        v-model="apiKey"
        :disabled="!isOnlinePayment"
        autocomplete="off"
      />
    </div>
  </div>
</template>

<script>
import { useOtherSettingsInfo } from "@/stores/OtherSettingsInfo";
import { mapState } from "pinia";

export default {
  name: "OnlinePayment",
  props: ["onlinePaymentSett"],
  data() {
    return {
      isOnlinePayment: null,
      profileID: "",
      apiKey: "",
    };
  },
  computed: {
    ...mapState(useOtherSettingsInfo, ["onlinePayment"]),
  },
  mounted() {
    if (this.onlinePayment) {
      setTimeout(() => {
        this.isOnlinePayment = this.onlinePayment[0].value == "1";
        this.profileID = this.onlinePayment[1].value;
        this.apiKey = this.onlinePayment[2].value;
      }, 1500);
    }
  },
};
</script>

<style scoped>
.online-pay input.custom-toggle {
  top: 15px;
  right: 22px;
}
.online-pay input.rtl.custom-toggle {
  left: 22px;
  right: auto;
}
</style>
