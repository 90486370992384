<template>
  <div class="custom-box">
    <h4 v-text="$t('newAds')"></h4>
    <div class="cars-box">
      <AdBox :adsList="products" />
    </div>
  </div>
</template>

<script>
import AdBox from "./AdBox.vue";

export default {
  name: "AdsBoxes",
  props: ["products"],
  components: {
    AdBox,
  },
};
</script>

<style scoped>
.custom-box {
  font-weight: 500;
}
.cars-box {
  overflow: auto;
  max-height: 420px;
  padding: 0 15px;
  margin: 0 -20px;
}
@media (max-width: 767px) {
  .cars-box {
    max-height: 735px;
  }
}
</style>
