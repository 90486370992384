<template>
  <div
    class="dashboard d-flex"
    :dir="$i18n.locale == 'en' ? 'ltr' : 'rtl'"
    :class="$i18n.locale == 'en' ? 'font-poppins' : 'font-tajawal rtl'"
  >
    <!-- <SideBar ref="sidebar" /> -->
    <SideBar />
    <div class="flex-grow-1 content">
      <div class="d-flex align-items-center gap-2 justify-content-between">
        <!-- Bar For Show SideBar In Mobile -->
        <button
          class="d-block d-lg-none btn-show-sidebar"
          type="button"
          @click="showSidebar"
        >
          <i class="bi bi-list list-icon"></i>
        </button>
        <NavBar />
      </div>
      <router-view class="router-view"></router-view>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/navbar/NavBar.vue";
import SideBar from "@/components/sidebar/SideBar.vue";
// import axios from "axios";

export default {
  name: "DashboardHome",
  components: {
    NavBar,
    SideBar,
  },
  data() {
    return {};
  },
  methods: {
    showSidebar() {
      document.getElementById("sidebar").classList.add("show");
    },
  },
};
</script>

<style>
.dashboard {
  background-color: var(--white);
  min-height: 100vh;
}
.dashboard .content {
  padding: 25px;
  padding-right: 35px;
  overflow: hidden;
}
.dashboard .content button.btn-show-sidebar {
  background-color: transparent;
}
.dashboard .content > div:first-of-type > button {
  height: 45px;
}
.dashboard.rtl .content > div:first-of-type > button {
  height: 35px;
}
.dashboard .content button .list-icon {
  color: var(--primary-orange);
  font-size: 35px;
}
@media (max-width: 767px) {
  .dashboard .content {
    padding: 25px 20px;
  }
}
.router-view {
  position: relative;
  top: 20px;
  margin-bottom: 50px;
}
@media (max-width: 1299px) {
  .router-view {
    top: 70px;
    margin-bottom: 100px;
  }
}
@media (max-width: 767px) {
  .router-view {
    top: 40px;
  }
}
</style>
