<template>
  <form
    class="d-flex flex-column flex-md-row flex-wrap gap-md-4 gap-3"
    @submit.prevent="$emit('search', [durationId, start_date, end_date])"
  >
    <!-- Select Date -->
    <div class="dropdown custom-select">
      <button
        class="dropdown-toggle d-flex align-items-center justify-content-between"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span>{{ duration }}</span>
        <i class="bi bi-chevron-down"></i>
      </button>
      <ul class="dropdown-menu">
        <li>
          <button
            type="button"
            data-duration="all"
            class="dropdown-item active"
            :class="{ rtl: $i18n.locale === 'ar' }"
            @click="chooseDate($event)"
          >
            {{ $t("all") }}
          </button>
        </li>
        <li>
          <button
            type="button"
            data-duration="last_30_days"
            class="dropdown-item"
            :class="{ rtl: $i18n.locale === 'ar' }"
            @click="chooseDate($event)"
          >
            {{ $t(`lastMonth`) }}
          </button>
        </li>
        <li>
          <button
            type="button"
            data-duration="last_three_months"
            class="dropdown-item"
            :class="{ rtl: $i18n.locale === 'ar' }"
            @click="chooseDate($event)"
          >
            {{ $t(`last3Months`) }}
          </button>
        </li>
        <li>
          <button
            type="button"
            data-duration="last_year"
            class="dropdown-item"
            :class="{ rtl: $i18n.locale === 'ar' }"
            @click="chooseDate($event)"
          >
            {{ $t(`lastYear`) }}
          </button>
        </li>
        <li>
          <button
            type="button"
            data-duration="custom"
            class="dropdown-item"
            :class="{ rtl: $i18n.locale === 'ar' }"
            @click="chooseDate($event)"
          >
            {{ $t(`custom`) }}
          </button>
        </li>
      </ul>
    </div>

    <!-- Start Date -->
    <div
      v-if="durationId == 'custom'"
      class="d-flex align-items-md-center flex-md-row flex-column align-items-start gap-2 gap-md-3"
    >
      <span class="from">{{ $t("from") }}</span>
      <div class="date rad-10">
        <VueDatePicker
          class="date-picker"
          v-model="start_date"
          :enable-duration-picker="false"
          :format="'dd.MM.yyyy'"
          selectText="OK"
          @update:model-value="getStartDate"
        />
      </div>
    </div>

    <!-- End Date -->
    <div
      v-if="durationId == 'custom'"
      class="d-flex align-items-md-center flex-md-row flex-column align-items-start gap-2 gap-md-3"
    >
      <span class="from">{{ $t("to") }}</span>
      <div class="date rad-10">
        <VueDatePicker
          class="date-picker"
          v-model="end_date"
          :enable-duration-picker="false"
          :format="'dd.MM.yyyy'"
          selectText="OK"
          @update:model-value="getEndDate"
        />
      </div>
    </div>

    <!-- Buttons -->
    <input type="submit" class="btn btn-orange" :value="$t('search')" />
    <input
      type="button"
      class="btn btn-blue"
      :value="$t('export')"
      @click="exportData"
    />
  </form>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import chooseItem from "@/assets/js/chooseItem";
import axios from "axios";
import saveInfoAlert from "@/assets/js/saveInfoAlert";

export default {
  name: "SearchByduration",
  components: {
    VueDatePicker,
  },
  data() {
    return {
      start_date: new Date(),
      end_date: new Date(),
      duration: this.$t("all"),
      durationId: "all",
    };
  },
  watch: {
    "$i18n.locale"() {
      this.start_date = new Date();
      this.end_date = new Date();
      this.duration = this.$t("all");
      this.durationId = "all";
    },
  },
  methods: {
    chooseDate($event) {
      chooseItem(".active", $event);
      this.duration = $event.target.textContent;
      this.durationId = $event.target.dataset.duration;
    },
    getStartDate(date) {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      this.start_date = `${year}-${month}-${day}`;
    },
    getEndDate(date) {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      this.end_date = `${year}-${month}-${day}`;
    },
    async exportData() {
      if (this.durationId == "custom") {
        await axios
          .post(
            `dashboard/statistics`,
            {
              from_date: this.start_date,
              to_date: this.end_date,
              export_excel: "",
            },
            {
              responseType: "blob",
            }
          )
          .then((res) => {
            const filename = res.headers.export_file_name;
            const url = URL.createObjectURL(
              new Blob([res.data], {
                type: "application/vnd.ms-excel",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            saveInfoAlert(
              this.$i18n.locale,
              "error",
              err.response.data.message
            );
          });
      } else {
        await axios
          .post(
            `dashboard/statistics/${this.durationId}`,
            {
              export_excel: "",
            },
            {
              responseType: "blob",
            }
          )
          .then((res) => {
            const filename = res.headers.export_file_name;
            const url = URL.createObjectURL(
              new Blob([res.data], {
                type: "application/vnd.ms-excel",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            saveInfoAlert(
              this.$i18n.locale,
              "error",
              err.response.data.message
            );
          });
      }
    },
  },
};
</script>

<style scoped>
form {
  font-weight: 500;
  color: var(--black);
}
.custom-select {
  width: 190px;
}
.custom-select ul button {
  padding: 15px;
}
.custom-select ul li:not(:last-of-type) button {
  border-bottom: 1px solid var(--border);
}
.custom-select ul button.active,
.custom-select ul button:hover {
  background-color: var(--border);
}
form .date {
  border: 1px solid var(--border);
  padding: 15px;
  cursor: pointer;
}

input[type="button"] {
  padding: 15px 40px;
}

@media (max-width: 767px) {
  form .date {
    width: 100%;
    padding: 12px;
  }
  form span.from,
  form span.to {
    font-size: 12px;
  }
  input[type="button"] {
    padding: 10px;
  }
}
</style>
