<template>
  <div class="modal-body">
    <!-- Category Name  -->
    <div>
      <label class="form-label">{{ $t("listCategoryTable[1]") }}</label>
      <input
        type="text"
        class="form-control input-text"
        :value="catName"
        disabled
      />
    </div>

    <!-- Mid/Final Category Name In Arabic -->
    <div>
      <label for="nameAR" class="form-label">{{
        $t("name") + "(" + $t("ar") + ")"
      }}</label>
      <input
        type="text"
        class="form-control input-text font-poppins"
        id="nameAR"
        v-model="nameAR"
        dir="auto"
        required
      />
    </div>

    <!-- Mid/Final Category Name In English -->
    <div>
      <label for="nameEN" class="form-label">{{
        $t("name") + "(" + $t("en") + ")"
      }}</label>
      <input
        type="text"
        class="form-control input-text font-poppins"
        id="nameEn"
        v-model="nameEN"
        dir="auto"
        required
      />
    </div>

    <!-- Sort -->
    <div>
      <label for="sort" class="form-label">{{
        $t("listCategoryTable[2]")
      }}</label>
      <input
        type="number"
        min="1"
        class="form-control input-text font-poppins"
        id="sort"
        :value="lastSort"
        @input="getSort"
        required
      />
    </div>
  </div>
</template>

<script>
import { useFetchAll } from "@/stores/FetchAll";
import { mapState } from "pinia";

export default {
  name: "AddMidCategory",
  data() {
    return {
      nameAR: "",
      nameEN: "",
      sort: "",
    };
  },
  methods: {
    getSort($event) {
      this.sort = $event.target.value;
    },
  },
  computed: {
    ...mapState(useFetchAll, ["catName", "lastSort"]),
  },
};
</script>
