<template>
  <div class="modal-body">
    <div>
      <label class="form-label"
        >{{ $t("tableUserSetting[2]") }}
        <input
          type="text"
          class="form-control input-text font-poppins"
          v-model="name"
          required
        />
      </label>
    </div>
    <div>
      <label class="form-label"
        >{{ $t("userName") }}
        <input
          type="text"
          class="form-control input-text font-poppins"
          v-model="username"
          required
        />
      </label>
    </div>
    <div>
      <label class="form-label"
        >{{ $t("password") }}
        <div
          class="password position-relative"
          :class="{ rtl: $i18n.locale === 'ar' }"
        >
          <input
            :type="showPassword ? 'text' : 'password'"
            class="form-control input-text font-poppins"
            v-model="password"
            required
          />
          <button type="button" @click="showPassword = !showPassword">
            <i v-if="showPassword" class="bi bi-eye"></i>
            <i v-if="!showPassword" class="bi bi-eye-slash"></i>
          </button>
        </div>
      </label>
    </div>
  </div>
  <CheckboxOptions />
</template>

<script>
import CheckboxOptions from "./CheckboxOptions.vue";
export default {
  name: "AddNewUser",
  components: {
    CheckboxOptions,
  },
  data() {
    return {
      showPassword: false,
      name: "",
      username: "",
      password: "",
      // listOptions: [1, 2],
    };
  },
  methods: {
    // getList(list) {
    //   this.listOptions = list;
    // },
  },
};
</script>

<style scoped>
.modal .modal-body input {
  margin-top: 5px;
}
.modal .modal-body div.password input {
  padding-right: 65px;
}
.modal .modal-body div.password.rtl input {
  padding-right: 12px;
  padding-left: 65px;
}

.modal .modal-body div.password button {
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--black-alt);
  background: transparent;
  transition: var(--transition);
  font-size: 23px;
  line-height: 0;
}
.modal .modal-body div.password.rtl button {
  right: auto;
  left: 22px;
}

@media (max-width: 767px) {
  .modal .modal-body div.password input {
    padding-right: 42px;
  }
  .modal .modal-body div.password button {
    right: 13px;
    font-size: 18px;
  }
}
</style>
