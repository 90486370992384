import saveInfoAlert from "@/assets/js/saveInfoAlert";
import i18n from "@/i18n";
import axios from "axios";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useFetchAll = defineStore("all", () => {
  // const specifications = ref([]);
  // const errorSpec = ref(null);
  const categories = ref([]);
  const category = ref({});
  const parentId = ref([]);
  const catName = ref("");
  const catId = ref("");
  const levelCat = ref("");
  const catSort = ref("");
  const lastSort = ref("");
  const loading = ref(false);
  const pages = ref("");
  const current_page = ref("");
  const next_page = ref("");
  const prev_page = ref("");

  async function fechCategory(record, page, name, status) {
    loading.value = true;
    let url;
    if (name && status) {
      url = `dashboard/categories?records=${record}&page=${page}&name=${name}&status=${status}`;
    } else if (name) {
      url = `dashboard/categories?records=${record}&page=${page}&name=${name}`;
    } else if (status) {
      url = `dashboard/categories?records=${record}&page=${page}&status=${status}`;
    } else {
      url = `dashboard/categories?records=${record}&page=${page}`;
    }
    await axios
      .get(url)
      .then((res) => {
        categories.value = res.data.data;
        pages.value = res.data.pagination.last_page;
        current_page.value = res.data.pagination.current_page;
        next_page.value = res.data.pagination.next_page_url;
        prev_page.value = res.data.pagination.prev_page_url;
      })
      .catch((err) => {
        saveInfoAlert(i18n.global.locale, "error", err.response.data.message);
      })
      .finally(() => {
        loading.value = false;
      });
  }

  function getCategory(category) {
    catId.value = category.id;
    levelCat.value = category.level;
    catName.value = category.name;
    catSort.value = category.sort;
    if (category.children) {
      lastSort.value = category.children.length + 1;
    }
  }

  const categoriesLength = ref("");
  function getCategoriesLength() {
    categoriesLength.value = categories.value.length + 1;
  }

  function emptyCategory() {
    catId.value = "";
    levelCat.value = "";
    catName.value = "";
    catSort.value = "";
    // lastSort.value = "";
  }

  function getCategoryInfo(cat, parentID) {
    category.value = cat;
    parentId.value = parentID;
  }

  function emptyCategoryInfo() {
    category.value = {};
  }

  // async function fechSpecifications(record, page) {
  //   loading.value = true;
  //   await axios
  //     .get(`dashboard/specifications?records=${record}&page=${page}`)
  //     .then((res) => {
  //       specifications.value = res.data.data;
  //       loading.value = false;
  //     })
  //     .catch((err) => {
  //       saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
  //       loading.value = false;
  //     });
  // }

  return {
    categories,
    category,
    parentId,
    catId,
    levelCat,
    catName,
    catSort,
    lastSort,
    fechCategory,
    getCategory,
    emptyCategory,
    getCategoryInfo,
    emptyCategoryInfo,
    // specifications,
    // fechSpecifications,
    // errorSpec,
    loading,
    pages,
    current_page,
    next_page,
    prev_page,
    getCategoriesLength,
    categoriesLength,
  };
});
