<template>
  <div>
    <h4 class="title">{{ $t("listHelp[1].title") }}</h4>
    <TableVideos />
  </div>
</template>

<script>
import TableVideos from "@/components/helpCenter/TableVideos.vue";

export default {
  name: "BannerSetting",
  components: {
    TableVideos,
  },
};
</script>
