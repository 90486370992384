<template>
  <div class="email-setting box-info">
    <h5 class="sub-title">{{ $t("emailSetting[0]") }}</h5>
    <!-- Host -->
    <div>
      <label for="host" class="form-label">{{ $t("emailSetting[1]") }} </label>
      <input
        type="text"
        class="form-control input-text"
        :placeholder="$t('emailSetting[2]')"
        id="host"
        v-model="host"
      />
    </div>

    <!-- port -->
    <div>
      <label for="port" class="form-label">{{ $t("emailSetting[3]") }} </label>
      <input
        type="text"
        class="form-control input-text"
        :placeholder="$t('emailSetting[4]')"
        id="port"
        v-model="port"
      />
    </div>

    <!-- Email -->
    <div>
      <label for="email" class="form-label">{{ $t("emailSetting[5]") }} </label>
      <input
        type="text"
        class="form-control input-text"
        :placeholder="$t('emailSetting[6]')"
        id="email"
        v-model="email"
      />
    </div>

    <!-- password -->
    <div>
      <label for="password" class="form-label"
        >{{ $t("emailSetting[7]") }}
      </label>
      <input
        type="text"
        class="form-control input-text"
        :placeholder="$t('emailSetting[8]')"
        id="password"
        v-model="password"
      />
    </div>

    <!-- From Email -->
    <div>
      <label for="from-email" class="form-label"
        >{{ $t("emailSetting[9]") }}
      </label>
      <input
        type="text"
        class="form-control input-text"
        :placeholder="$t('emailSetting[10]')"
        id="from-email"
        v-model="fromEmail"
      />
    </div>

    <!-- Email Sender -->
    <div>
      <label for="email-sender" class="form-label"
        >{{ $t("emailSetting[11]") }}
      </label>
      <input
        type="text"
        class="form-control input-text"
        :placeholder="$t('emailSetting[12]')"
        id="email-sender"
        v-model="emailSender"
      />
    </div>

    <!-- Bcc Email -->
    <div>
      <label for="bcc-email" class="form-label"
        >{{ $t("emailSetting[13]") }}
      </label>
      <input
        type="text"
        class="form-control input-text"
        :placeholder="$t('emailSetting[14]')"
        id="bcc-email"
        v-model="bccEmail"
      />
    </div>
  </div>
</template>

<script>
import { useOtherSettingsInfo } from "@/stores/OtherSettingsInfo";
import { mapState } from "pinia";

export default {
  name: "EmailSetting",
  data() {
    return {
      host: "",
      port: "",
      email: "",
      password: "",
      fromEmail: "",
      emailSender: "",
      bccEmail: "",
    };
  },
  computed: {
    ...mapState(useOtherSettingsInfo, ["emailSetting"]),
  },
  mounted() {
    if (this.emailSetting) {
      setTimeout(() => {
        this.host = this.emailSetting[0].value;
        this.port = this.emailSetting[1].value;
        this.email = this.emailSetting[2].value;
        this.password = this.emailSetting[3].value;
        this.fromEmail = this.emailSetting[4].value;
        this.emailSender = this.emailSetting[5].value;
        this.bccEmail = this.emailSetting[6].value;
      }, 1500);
    }
  },
};
</script>

<style scoped>
.email-setting input::placeholder {
  color: #bdbdbd !important;
}
</style>
