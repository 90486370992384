<template>
  <div
    class="log-in row"
    :dir="$i18n.locale == 'en' ? 'ltr' : 'rtl'"
    :class="$i18n.locale == 'en' ? 'font-poppins' : 'font-tajawal rtl'"
  >
    <div
      class="logo col-12 col-lg-6 d-lg-flex justify-content-center align-items-center d-none"
    >
      <img src="@/assets/images/logo-white.png" alt="logo" />
    </div>
    <div
      class="login-form col-12 col-lg-6 d-flex justify-content-center align-items-center"
    >
      <div class="d-flex flex-column align-items-center">
        <img src="@/assets/images/logo.png" alt="logo" class="mb-5" />
        <LoginForm />
        <div
          class="languages d-flex align-items-center gap-sm-2 gap-1"
          @click="changeLang"
        >
          <p v-if="$i18n.locale == 'en'" class="font-tajawal">اللغة العربية</p>
          <p v-else class="font-poppins">English</p>
          <span class="material-icons"> language </span>
        </div>
        <p class="position-absolute bottom-0 mb-2 copyright">
          <bdi>{{ $t("copyright") }}</bdi>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "@/components/loginPage/LoginForm.vue";

export default {
  name: "LoginPage",
  components: {
    LoginForm,
  },
  data() {
    return {};
  },
  methods: {
    // A function to convert from one language to another
    changeLang() {
      if (this.$i18n.locale == "en") {
        this.$i18n.locale = "ar";
        this.$router.push({
          params: { lang: "ar" },
        });
      } else {
        this.$i18n.locale = "en";
        this.$router.push({
          params: { lang: "en" },
        });
      }
    },
  },
};
</script>

<style scoped>
.log-in {
  height: 100vh;
  margin: 0;
}
.log-in .logo {
  background-color: var(--primary-orange);
}
.log-in .login-form {
  padding: 0 30px;
}
.log-in .login-form > div {
  flex-basis: 80%;
}
@media (max-width: 767px) {
  .log-in .login-form > div {
    flex-basis: 100%;
  }
}
@media (max-width: 575px) {
  .log-in .login-form img {
    width: 100px;
  }
}
.languages {
  color: var(--primary-blue);
  cursor: pointer;
  margin-top: 15px;
}
.languages p {
  font-size: 20px;
  font-weight: 500;
}
.languages:hover p {
  text-decoration: underline;
}
.copyright {
  font-size: 14px;
  color: var(--black-alt);
}
@media (max-width: 575px) {
  .languages {
    margin-top: 0px;
  }
  .languages p {
    font-size: 14px;
  }
  .languages span {
    font-size: 18px;
  }
  .copyright {
    font-size: 12px;
  }
}
</style>
