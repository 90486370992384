<template>
  <div class="modal-body">
    <p>{{ $t("listCommission[3].commissionTotal") }}</p>
    <div class="position-relative">
      <label>{{ $t("amount") }}</label>
      <input
        type="text"
        class="form-control input-text"
        @keypress="onlyNumber"
        v-model="amount"
        required
      />
    </div>
  </div>
</template>

<script>
import onlyNumber from "@/assets/js/onlyNumber";
export default {
  name: "ConfirmAmount",
  data() {
    return {
      amount: "",
    };
  },
  methods: {
    onlyNumber,
  },
};
</script>

<style scoped>
p {
  font-size: 20px;
  font-weight: 500;
  color: rgba(28, 197, 85, 1);
}
@media (max-width: 767px) {
  p {
    font-size: 16px;
  }
}
</style>
