<template>
  <div>
    <!-- Search Users -->
    <SearchingByStatus
      ident="addUser"
      :add="$t('addNewUser')"
      width="600px"
      :placeholder="$t('enterUserName')"
      @send="addNewUser"
      @search="searchAdmins"
      @cancelModal="cancelUser"
      @refresh="refresh"
    >
      <AddNewUser ref="adduser" />
    </SearchingByStatus>

    <!-- Users Table -->
    <div class="responsive-table">
      <table class="custom-table table-bordered">
        <thead>
          <tr>
            <th scope="col" v-for="head in 7" :key="head">
              {{ $t(`tableUserSetting[${head - 1}]`) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="admin in admins" :key="admin.id">
            <td>{{ admin.id }}</td>
            <td>{{ admin.username }}</td>
            <td>{{ admin.true_name }}</td>
            <td>{{ admin.add_date }}</td>
            <td>
              <input
                type="checkbox"
                class="custom-toggle"
                :defaultChecked="admin.status"
                @change="changeStatus(admin.id)"
              />
            </td>
            <td>{{ admin.login_time }}</td>
            <td>
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#editUser"
                class="bg-transparent"
                @click="getAdminId(admin)"
              >
                <img src="@/assets/images/edit.svg" alt="edit" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <ModalPopup
        :title="$t('editUser')"
        :ident="'editUser'"
        :width="'600px'"
        @send="editUser"
        @cancelModal="cancelEditUser"
      >
        <EditUser ref="edituser" />
      </ModalPopup>
    </div>

    <!-- Pagination -->
    <PaginationBox
      :pages="pages"
      :current_page="current_page"
      :next_page="next_page"
      :prev_page="prev_page"
      @chooseRec="chooseRecord"
      @thisPage="thisPage"
      @nextPage="nextPage"
      @prevPage="prevPage"
    />

    <!-- Loading For refresh Table -->
    <div v-if="loading" class="loading load">
      <div class="text-center">
        <img src="@/assets/images/loading.svg" alt="spinner" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ModalPopup from "../global/ModalPopup.vue";
import EditUser from "@/components/userSetting/EditUser.vue";
import PaginationBox from "../global/PaginationBox.vue";
import SearchingByStatus from "../global/SearchingByStatus.vue";
import AddNewUser from "./AddNewUser.vue";
import saveInfoAlert from "@/assets/js/saveInfoAlert";
export default {
  name: "TableUser",
  components: {
    SearchingByStatus,
    AddNewUser,
    EditUser,
    PaginationBox,
    ModalPopup,
  },
  data() {
    return {
      admins: [],
      adminId: "",
      loading: false,

      records: 10,
      pages: "",
      current_page: "",
      next_page: "",
      prev_page: "",
    };
  },
  methods: {
    async fetchUsers(record, page) {
      this.loading = true;
      await axios
        .get(`dashboard/admins?records=${record}&page=${page}`)
        .then((res) => {
          this.admins = res.data.data;
          this.loading = false;
          this.pages = res.data.pagination.last_page;
          this.current_page = res.data.pagination.current_page;
          this.next_page = res.data.pagination.next_page_url;
          this.prev_page = res.data.pagination.prev_page_url;
        })
        .catch((err) => {
          this.loading = false;
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        });
    },
    async searchAdmins(payload) {
      const name = payload[0] ? `username=${payload[0]}` : "";
      const status = payload[1] ? `status=${payload[1]}` : "";
      this.loading = true;
      await axios
        .get(`dashboard/admins?${name}&${status}&records=1000`)
        .then((res) => {
          this.admins = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        });
    },
    refresh() {
      this.fetchUsers(this.records, this.current_page);
    },
    async addNewUser() {
      const permissionsOpts = [];
      document
        .querySelectorAll(".options label input:not(.check-all)")
        .forEach((el) => {
          if (el.checked) {
            permissionsOpts.push(el.value);
          }
        });

      if (!permissionsOpts.length) {
        saveInfoAlert(
          this.$i18n.locale,
          "error",
          this.$t("pleaseSelectPermissions")
        );
      } else {
        await axios
          .post("dashboard/admins", {
            name: this.$refs.adduser.name,
            username: this.$refs.adduser.username,
            password: this.$refs.adduser.password,
            permissions: permissionsOpts,
          })
          .then((res) => {
            saveInfoAlert(this.$i18n.locale, "success", res.data.message);
            this.$refs.adduser.name = "";
            this.$refs.adduser.username = "";
            this.$refs.adduser.password = "";
            document.querySelectorAll(".options label input").forEach((el) => {
              el.checked = false;
            });
            this.fetchUsers(this.records, this.current_page);
          })
          .catch((err) => {
            saveInfoAlert(
              this.$i18n.locale,
              "error",
              err.response.data.message
            );
          });
      }
    },
    cancelUser() {
      this.$refs.adduser.name = "";
      this.$refs.adduser.username = "";
      this.$refs.adduser.password = "";
      document.querySelectorAll(".options label input").forEach((el) => {
        if (el.value != 1) {
          el.checked = false;
        }
      });
    },
    getAdminId(admin) {
      this.adminId = admin.id;
      this.$refs.edituser.name = admin.true_name;
      this.$refs.edituser.username = admin.username;
      this.$refs.edituser.password = "";
      document.querySelectorAll(".edit-options label input").forEach((el) => {
        admin.permissions.forEach((e) => {
          if (e == el.value) {
            el.checked = true;
          }
        });
      });
    },
    async editUser() {
      const permissionsOpts = [];
      document
        .querySelectorAll(".edit-options label input:not(.check-all)")
        .forEach((el) => {
          if (el.checked) {
            permissionsOpts.push(el.id);
          }
        });
      if (!permissionsOpts.length) {
        saveInfoAlert(
          this.$i18n.locale,
          "error",
          this.$t("pleaseSelectPermissions")
        );
      } else {
        await axios
          .post(`dashboard/admins/${this.adminId}`, {
            name: this.$refs.edituser.name,
            username: this.$refs.edituser.username,
            password: this.$refs.edituser.password,
            permissions: permissionsOpts,
          })
          .then((res) => {
            if (res.data.data.id == localStorage.getItem("topz_admin_id")) {
              localStorage.setItem(
                "topz_admin_permissions",
                JSON.stringify(res.data.data.permissions)
              );
            }
            saveInfoAlert(this.$i18n.locale, "success", res.data.message);
            this.fetchUsers(this.records, this.current_page);
            window.location.reload();
          })
          .catch((err) => {
            saveInfoAlert(
              this.$i18n.locale,
              "error",
              err.response.data.message
            );
          });
      }
    },
    cancelEditUser() {
      document.querySelectorAll(".edit-options input").forEach((el) => {
        el.checked = false;
      });
    },
    async changeStatus(id) {
      await axios
        .post(`dashboard/admins/${id}/change-status`)
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        });
    },
    chooseRecord(payload) {
      this.records = payload;
      this.fetchUsers(payload, 1);
    },
    thisPage(p) {
      this.fetchUsers(this.records, p);
    },
    nextPage() {
      this.fetchUsers(this.records, this.current_page + 1);
    },
    prevPage() {
      this.fetchUsers(this.records, this.current_page - 1);
    },
  },
  async mounted() {
    this.fetchUsers(10, 1);
  },
};
</script>

<style scoped>
table {
  min-width: 1000px;
}
table td {
  text-transform: none;
}
table td img {
  cursor: pointer;
}
</style>
