<template>
  <div class="edit-options">
    <!-- Home - Category - Ad - Ads List -->
    <label v-for="item in items1" :key="item.id" class="mb-4">
      <input
        type="checkbox"
        :id="item.id"
        :class="{ rtl: $i18n.locale === 'ar' }"
        class="custom-checkbox"
        :value="item.name"
      />
      {{ $t(`linksList[${item.id - 1}].title`) }}</label
    >

    <!-- Publisher List -->
    <label class="mb-4">
      <input
        type="checkbox"
        id="17"
        class="custom-checkbox"
        :class="{ rtl: $i18n.locale === 'ar' }"
        value="publisher_list"
      />
      {{ $t("linksList[11].title") }}</label
    >

    <!-- Commission -->
    <label class="mb-4">
      <input
        type="checkbox"
        class="custom-checkbox check-all"
        :class="{ rtl: $i18n.locale === 'ar' }"
        @input="checkAll($event, 'edit-commission')"
      />
      {{ $t(`linksList[4].title`) }}</label
    >
    <div class="ms-5 me-5">
      <label v-for="item in items2" :key="item.id" class="mb-4">
        <input
          :id="item.id"
          class="edit-commission custom-checkbox"
          type="checkbox"
          :class="{ rtl: $i18n.locale === 'ar' }"
          :value="item.name"
        />
        {{ $t(`listCommission[${item.id - 5}].title`) }}</label
      >
    </div>

    <!-- Statistics -->
    <label class="mb-4">
      <input
        type="checkbox"
        id="9"
        class="custom-checkbox"
        :class="{ rtl: $i18n.locale === 'ar' }"
        value="statistic"
      />
      {{ $t(`linksList[5].title`) }}</label
    >

    <!-- User Setting -->
    <label class="mb-4">
      <input
        type="checkbox"
        id="10"
        class="custom-checkbox"
        :class="{ rtl: $i18n.locale === 'ar' }"
        value="user_setting"
      />
      {{ $t(`linksList[6].title`) }}</label
    >

    <!-- Help Center -->
    <label class="mb-4">
      <input
        type="checkbox"
        class="custom-checkbox check-all"
        :class="{ rtl: $i18n.locale === 'ar' }"
        @input="checkAll($event, 'edit-help')"
      />
      {{ $t(`linksList[7].title`) }}</label
    >
    <div class="ms-5 me-5">
      <label v-for="(item, ind) in items3" :key="item.id" class="mb-4">
        <input
          type="checkbox"
          :id="item.id"
          class="edit-help custom-checkbox"
          :class="{ rtl: $i18n.locale === 'ar' }"
          :value="item.name"
        />
        {{ $t(`listHelp[${ind}].title`) }}</label
      >
    </div>

    <!-- Complaining -->
    <label class="mb-4">
      <input
        type="checkbox"
        id="13"
        class="custom-checkbox"
        :class="{ rtl: $i18n.locale === 'ar' }"
        value="complaining"
      />
      {{ $t(`linksList[8].title`) }}</label
    >

    <!-- Banner Setting -->
    <label class="mb-4">
      <input
        type="checkbox"
        id="14"
        class="custom-checkbox"
        :class="{ rtl: $i18n.locale === 'ar' }"
        value="banner_setting"
      />
      {{ $t(`linksList[9].title`) }}</label
    >

    <!-- System Setting -->
    <label class="mb-4">
      <input
        type="checkbox"
        class="custom-checkbox check-all"
        :class="{ rtl: $i18n.locale === 'ar' }"
        @input="checkAll($event, 'edit-system')"
      />
      {{ $t(`linksList[10].title`) }}</label
    >
    <div class="ms-5 me-5">
      <label v-for="(item, ind) in items4" :key="item.id" class="mb-4">
        <input
          type="checkbox"
          :id="item.id"
          class="edit-system custom-checkbox"
          :class="{ rtl: $i18n.locale === 'ar' }"
          :value="item.name"
        />
        {{ $t(`listSystem[${ind}].title`) }}</label
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "EditCheckboxOptions",
  data() {
    return {
      // allList: [],
      items1: [
        {
          id: 1,
          name: "home_page",
        },
        {
          id: 2,
          name: "category_management",
        },
        {
          id: 3,
          name: "specification_management",
        },
        {
          id: 4,
          name: "ad_list",
        },
      ],
      items2: [
        {
          id: 5,
          name: "category_commission_setting",
        },
        {
          id: 6,
          name: "free_commission_setting",
        },
        {
          id: 7,
          name: "user_commission",
        },
        {
          id: 8,
          name: "commission_record",
        },
      ],
      items3: [
        {
          id: 11,
          name: "protocols_management",
        },
        {
          id: 12,
          name: "video_management",
        },
      ],
      items4: [
        {
          id: 15,
          name: "bank_account",
        },
        {
          id: 16,
          name: "other_setting",
        },
      ],
    };
  },
  methods: {
    checkAll($event, className) {
      const items = document.querySelectorAll("." + className);
      if ($event.target.checked == true) {
        items.forEach((el) => {
          el.checked = true;
        });
      } else {
        items.forEach((el) => {
          el.checked = false;
        });
      }
    },
  },
};
</script>

<style scoped>
.edit-options label {
  color: var(--black);
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}
</style>
