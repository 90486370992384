<template>
  <div class="complaining">
    <h4 class="title">{{ $t("linksList[8].title") }}</h4>
    <TableComplaining />
  </div>
</template>

<script>
import TableComplaining from "@/components/complainingPage/TableComplaining.vue";
import { usePusherStore } from "@/stores/PusherNotification";

export default {
  name: "ComplainingPage",
  components: {
    TableComplaining,
  },
  setup() {
    const pusherStore = usePusherStore();

    return {
      pusherStore,
    };
  },
  mounted() {
    this.pusherStore.complaintNotification = 0;
  },
};
</script>

<style scoped>
.user-setting button {
  min-width: 200px;
}
@media (max-width: 767px) {
  .user-setting button {
    width: 100%;
  }
}
</style>
