<template>
  <div>
    <div class="add-video" :class="{ rtl: $i18n.locale === 'ar' }">
      <!-- Button trigger modal For Add New Video -->
      <button
        type="button"
        class="btn btn-blue"
        data-bs-toggle="modal"
        data-bs-target="#addVideo"
      >
        {{ $t("addNewVideo") }}
      </button>
      <ModalPopup
        :title="$t('addNewVideo')"
        :ident="'addVideo'"
        :width="'600px'"
        @send="addVideo"
        @cancelModal="cancelVideo"
        ref="addVideoPopup"
      >
        <AddEditVideo ref="addModalVideo" />
      </ModalPopup>
    </div>

    <!-- Videos Table -->
    <div class="responsive-table">
      <table class="custom-table table-bordered">
        <thead>
          <tr>
            <th scope="col" v-for="head in 5" :key="head">
              {{ $t(`videoTable[${head - 1}]`) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="video in videos" :key="video.id">
            <td>{{ video.id }}</td>
            <td v-if="this.$i18n.locale == 'en'">{{ video.title_en }}</td>
            <td v-else>{{ video.title_ar }}</td>
            <td class="user-select-all text-lowercase">{{ video.url }}</td>
            <td class="sort">
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#editSort"
                class="bg-transparent"
                @click="getSort(video.id, video.sort)"
              >
                {{ video.sort }}
              </button>
            </td>
            <td>
              <div class="d-flex justify-content-evenly">
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#editVideo"
                  class="bg-transparent"
                  @click="editVideo(video)"
                >
                  <img src="@/assets/images/edit.svg" alt="edit" />
                </button>
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#removeVideo"
                  class="bg-transparent"
                  @click="getVideoId(video.id)"
                >
                  <img src="@/assets/images/trash.svg" alt="remove" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Popup For Confirm Delete Video -->
    <DeleteVideo @deleteVideo="deleteVideo" />

    <!-- Popup -Modal- For Edit Video -->
    <ModalPopup
      :title="$t('editVideo')"
      :ident="'editVideo'"
      :width="'600px'"
      @send="confirmEditVideo"
      ref="editVideoPopup"
    >
      <AddEditVideo ref="editModalVideo" />
    </ModalPopup>

    <!-- Popup to change Sort only -->
    <ModalPopup
      :title="$t('listCategoryTable[2]')"
      :ident="'editSort'"
      :width="'600px'"
      @send="editSort"
      @cancelModal="cancelEditSort"
      ref="sortPopup"
    >
      <EditSortVideo ref="editModalSort" />
    </ModalPopup>

    <PaginationBox
      :pages="pages"
      :current_page="current_page"
      :next_page="next_page"
      :prev_page="prev_page"
      @chooseRec="chooseRecord"
      @thisPage="thisPage"
      @nextPage="nextPage"
      @prevPage="prevPage"
    />

    <!-- Loading For Add and Edit Video -->
    <div v-if="loadingVideo" class="loading load" :style="{ zIndex: 2000 }">
      <div class="text-center">
        <img src="@/assets/images/Pulse.svg" :style="{ width: '120px' }" />
      </div>
    </div>
    <!-- Loading For refresh Table -->
    <div v-if="loading" class="loading load">
      <div class="text-center">
        <img src="@/assets/images/loading.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import DeleteVideo from "./DeleteVideo.vue";
import ModalPopup from "../global/ModalPopup.vue";
import AddEditVideo from "./AddEditVideo.vue";
import EditSortVideo from "./EditSortVideo.vue";
import PaginationBox from "../global/PaginationBox.vue";

import axios from "axios";
import saveInfoAlert from "@/assets/js/saveInfoAlert";

export default {
  name: "TableUser",
  components: {
    DeleteVideo,
    ModalPopup,
    AddEditVideo,
    EditSortVideo,
    PaginationBox,
  },
  data() {
    return {
      videos: [],
      loading: false,
      loadingVideo: false,
      videoId: "",
      sort: "",

      records: 10,
      pages: "",
      current_page: "",
      next_page: "",
      prev_page: "",
    };
  },
  methods: {
    async fetchVideos(record, page) {
      this.loading = true;
      await axios
        .get(`dashboard/videos?records=${record}&page=${page}`)
        .then((res) => {
          this.videos = res.data.data;
          this.loading = false;
          this.pages = res.data.pagination.last_page;
          this.current_page = res.data.pagination.current_page;
          this.next_page = res.data.pagination.next_page_url;
          this.prev_page = res.data.pagination.prev_page_url;
        })
        .catch((err) => {
          this.loading = false;
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        });
    },
    async addVideo() {
      var data = new FormData();
      if (this.$refs.addModalVideo.coverPhoto.length) {
        data.append("title_en", this.$refs.addModalVideo.titleEn);
        data.append("title_ar", this.$refs.addModalVideo.titleAr);
        data.append("sort", this.$refs.addModalVideo.sort);
        data.append("cover_image", this.$refs.addModalVideo.file);
        data.append("url", this.$refs.addModalVideo.link);
        this.loadingVideo = true;
        await axios
          .post("dashboard/videos", data)
          .then((res) => {
            saveInfoAlert(this.$i18n.locale, "success", res.data.message);
            this.$refs.addVideoPopup.closeModalButton.click();
            this.fetchVideos(this.records, this.current_page);
          })
          .catch((err) => {
            saveInfoAlert(
              this.$i18n.locale,
              "error",
              err.response.data.message
            );
          })
          .finally(() => {
            this.loadingVideo = false;
          });
      } else {
        saveInfoAlert(
          this.$i18n.locale,
          "error",
          this.$t("pleaseUploadCoverPhoto")
        );
      }
    },
    cancelVideo() {
      this.$refs.addModalVideo.titleEn = "";
      this.$refs.addModalVideo.titleAr = "";
      this.$refs.addModalVideo.sort = "";
      this.$refs.addModalVideo.coverPhoto = [];
      this.$refs.addModalVideo.file = "";
      this.$refs.addModalVideo.photoSizeLong = false;
      this.$refs.addModalVideo.photoErrorDimension = false;
      this.$refs.addModalVideo.link = [];
    },
    editVideo(video) {
      this.videoId = video.id;
      this.$refs.editModalVideo.titleEn = video.title_en;
      this.$refs.editModalVideo.titleAr = video.title_ar;
      this.$refs.editModalVideo.sort = video.sort;
      this.$refs.editModalVideo.link = video.url;
      this.$refs.editModalVideo.coverPhotoEdit = video.cover_image;
    },
    async confirmEditVideo() {
      var data = new FormData();
      data.append("title_en", this.$refs.editModalVideo.titleEn);
      data.append("title_ar", this.$refs.editModalVideo.titleAr);
      if (this.$refs.editModalVideo.file) {
        data.append("cover_image", this.$refs.editModalVideo.file);
      }
      data.append("sort", this.$refs.editModalVideo.sort);
      data.append("url", this.$refs.editModalVideo.link);
      this.loadingVideo = true;
      await axios
        .post(`dashboard/videos/${this.videoId}`, data)
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
          this.$refs.editVideoPopup.closeModalButton.click();
          this.fetchVideos(this.records, this.current_page);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        })
        .finally(() => {
          this.loadingVideo = false;
        });
    },
    getSort(id, sort) {
      this.videoId = id;
      this.sort = sort;
      this.$refs.editModalSort.sort = sort;
    },
    async editSort() {
      await axios
        .post(`dashboard/videos/${this.videoId}/change-sort`, {
          sort: this.$refs.editModalSort.sort,
        })
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
          this.$refs.sortPopup.closeModalButton.click();
          this.fetchVideos(this.records, this.current_page);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        });
    },
    cancelEditSort() {
      this.$refs.editModalSort.sort = "";
    },
    getVideoId(id) {
      this.videoId = id;
    },
    async deleteVideo() {
      await axios
        .delete(`dashboard/videos/${this.videoId}`)
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        });
      this.fetchVideos(this.records, this.current_page);
    },
    chooseRecord(payload) {
      this.records = payload;
      this.fetchVideos(payload, 1);
    },
    thisPage(p) {
      this.fetchVideos(this.records, p);
    },
    nextPage() {
      this.fetchVideos(this.records, this.current_page + 1);
    },
    prevPage() {
      this.fetchVideos(this.records, this.current_page - 1);
    },
  },
  async mounted() {
    this.fetchVideos(10, 1);
  },
};
</script>

<style scoped>
.add-video button {
  min-width: 200px;
  margin-left: auto;
}
.add-video.rtl button {
  margin-left: 0;
  margin-right: auto;
}
@media (max-width: 767px) {
  .add-video button {
    width: 100%;
  }
}
table {
  min-width: 1000px;
}
table td.sort button {
  width: calc(100% + 40px);
  height: 100%;
  margin: -20px;
}
</style>
