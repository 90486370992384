<template>
  <nav
    aria-label="Page navigation"
    class="d-flex align-items-center justify-content-end flex-column flex-md-row gap-4"
  >
    <div class="dropdown custom-select select-record">
      <button
        class="dropdown-toggle d-flex align-items-center justify-content-between"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span>{{ record }} {{ $t("record") }}</span>
        <i class="bi bi-chevron-down"></i>
      </button>
      <ul class="dropdown-menu">
        <li>
          <button
            type="button"
            class="dropdown-item record"
            :class="[{ rtl: $i18n.locale === 'ar' }, { active: record == 10 }]"
            @click="chooseRecord($event, 10)"
          >
            10 {{ $t("record") }}
          </button>
        </li>
        <li>
          <button
            type="button"
            class="dropdown-item record"
            :class="[{ rtl: $i18n.locale === 'ar' }, { active: record == 15 }]"
            @click="chooseRecord($event, 15)"
          >
            15 {{ $t("record") }}
          </button>
        </li>
        <li>
          <button
            type="button"
            class="dropdown-item record"
            :class="[{ rtl: $i18n.locale === 'ar' }, { active: record == 20 }]"
            @click="chooseRecord($event, 20)"
          >
            20 {{ $t("record") }}
          </button>
        </li>
      </ul>
    </div>

    <ul class="pagination gap-2">
      <li class="page-item">
        <span
          class="page-link d-flex chevron"
          aria-label="Previous"
          :class="[{ rtl: $i18n.locale === 'ar' }, { disabled: !prev_page }]"
          @click="prev"
        >
        </span>
      </li>
      <li v-for="page in visible_page_range" :key="page" class="page-item">
        <a
          type="button"
          to="#"
          class="page-link"
          :class="{ active: page == current_page }"
          @click="addActive(page)"
        >
          {{ page }}
        </a>
      </li>
      <li class="page-item">
        <span
          class="page-link d-flex chevron"
          aria-label="Next"
          :class="[{ rtl: $i18n.locale === 'ar' }, { disabled: !next_page }]"
          @click="next"
        ></span>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "PaginationBox",
  props: ["pages", "current_page", "next_page", "prev_page"],
  data() {
    return {
      record: 10,
      allPages: [],
    };
  },
  computed: {
    visible_page_range() {
      const startPage = Math.max(1, this.current_page - 3);
      const endPage = Math.min(this.pages, startPage + 6);

      return Array.from(
        { length: endPage - startPage + 1 },
        (_, index) => startPage + index
      );
    },
  },
  methods: {
    chooseRecord($event, id) {
      const records = document.querySelectorAll(".record");
      records.forEach((e) => {
        e.classList.remove("active");
      });
      $event.target.classList.add("active");
      this.record = id;
      this.$emit("chooseRec", id);
    },
    addActive(p) {
      this.$emit("thisPage", p);
    },
    next() {
      this.$emit("nextPage");
    },
    prev() {
      this.$emit("prevPage");
    },
  },
};
</script>

<style scoped>
@import "@/assets/css/pagination.css";
</style>
