<template>
  <div class="box-info font-poppins">
    <h5 class="sub-title">Mapbox Information</h5>
    <div>
      <label for="tokenAccess" class="form-label">Mapbox Token Access</label>
      <input
        type="text"
        class="form-control input-text"
        id="tokenAccess"
        v-model="tokenAccess"
        autocomplete="off"
      />
    </div>
  </div>
</template>

<script>
import { useOtherSettingsInfo } from "@/stores/OtherSettingsInfo";
import { mapState } from "pinia";
export default {
  name: "StripeInfo",
  data() {
    return {
      tokenAccess: "",
    };
  },
  computed: {
    ...mapState(useOtherSettingsInfo, ["mapboxToken"]),
  },
  mounted() {
    if (this.mapboxToken) {
      setTimeout(() => {
        this.tokenAccess = this.mapboxToken;
      }, 1500);
    }
  },
};
</script>

<style scoped>
label {
  font-family: "Poppins", sans-serif;
}
</style>
