<template>
  <div
    class="modal fade"
    :id="ident"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    ref="myModal"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content popup-box">
        <!-- Header -->
        <div
          class="modal-header d-flex align-items-center justify-content-between flex-column flex-md-row gap-4 mb-3"
        >
          <h1 class="modal-title fs-4" id="staticBackdropLabel">
            {{ title }}
          </h1>
          <div class="choices d-flex gap-sm-4 gap-3">
            <div class="d-flex align-items-center gap-sm-3 gap-2">
              <input
                type="radio"
                value=""
                :id="title"
                v-model="choisePublish"
                @input="changeChoice"
              />
              <label :for="title">{{ $t("all") }}</label>
            </div>
            <div class="d-flex align-items-center gap-sm-3 gap-2">
              <input
                type="radio"
                :value="valTwo"
                :id="two"
                v-model="choisePublish"
                @input="changeChoice"
              />
              <label :for="two">{{ two }}</label>
            </div>
            <div class="d-flex align-items-center gap-sm-3 gap-2">
              <input
                type="radio"
                :value="valThree"
                :id="three"
                v-model="choisePublish"
                @input="changeChoice"
              />
              <label :for="three">{{ three }}</label>
            </div>
          </div>
        </div>
        <!-- Body -->
        <div class="modal-body">
          <div class="responsive-table">
            <table class="custom-table table-bordered">
              <thead>
                <tr v-if="titlesLength === 5">
                  <th scope="col" v-for="t in titlesLength" :key="t">
                    {{ $t(`title1publish[${t - 1}]`) }}
                  </th>
                </tr>
                <tr v-if="titlesLength === 4">
                  <th scope="col" v-for="t in titlesLength" :key="t">
                    {{ $t(`title2publish[${t - 1}]`) }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <slot />
              </tbody>
            </table>
          </div>
        </div>
        <!-- Footer -->
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-orange"
            data-bs-dismiss="modal"
            @click="choisePublish = ''"
          >
            {{ $t("close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PublishPopup",
  props: [
    "ident",
    "title",
    "titlesLength",
    "two",
    "valTwo",
    "three",
    "valThree",
  ],
  data() {
    return {
      choisePublish: "",
    };
  },
  methods: {
    changeChoice($event) {
      this.$emit("changeChoice", $event.target.value);
    },
  },
  mounted() {
    this.$refs.myModal.addEventListener("hide.bs.modal", () => {
      this.choisePublish = "";
      this.$emit("changeChoice", "");
    });
  },
};
</script>

<style scoped>
.responsive-table {
  max-height: 330px;
}
.modal .modal-dialog {
  max-width: 1010px;
}
.modal .modal-body .custom-table {
  min-width: 880px;
}
.popup-box h4 {
  margin: 0 !important;
}
.popup-box .choices input {
  accent-color: #e07814;
  cursor: pointer;
  width: 30px;
  height: 30px;
}
.popup-box .choices label {
  color: var(--black);
  font-weight: 500;
  cursor: pointer;
}
@media (max-width: 767px) {
  .popup-box .choices input {
    width: 20px;
    height: 20px;
  }
  .popup-box .choices label {
    font-size: 14px;
  }
}
/* .modal .modal-footer {
  justify-content: center;
} */
.modal .modal-footer button {
  min-width: 230px;
  flex: 0;
  margin: auto;
}
</style>
