<template>
  <div class="modal-body">
    <div>
      <label for="sort" class="form-label">{{
        $t("listBannerTable[3]")
      }}</label>
      <input
        type="number"
        min="1"
        class="form-control input-text font-poppins"
        id="sort"
        v-model="sort"
        required
      />
    </div>
    <div>
      <label for="id" class="form-label">Product Id</label>
      <input
        type="text"
        class="form-control input-text font-poppins"
        id="id"
        v-model="productId"
        required
      />
    </div>
    <div>
      <label class="form-label">{{ $t("ImageApp") }}</label>
      <span v-if="appSizeLong" class="size-long">{{
        $t("imageSizeLargerThan6MB")
      }}</span>
      <span v-if="appErrorDimension" class="size-long">{{
        $t("imageDimensionsAreSmallerThan300200")
      }}</span>
      <img :src="appImage" />
      <label
        for="editImageApp"
        class="upload"
        :class="{ rtl: $i18n.locale == 'ar' }"
      >
        <span>{{ $t("editImage") }}</span>
      </label>
      <input
        type="file"
        id="editImageApp"
        accept="image/png, image/jpeg"
        @change="uploadBannerApp"
      />
    </div>
    <div>
      <label class="form-label">{{ $t("ImageWeb") }}</label>
      <span v-if="webSizeLong" class="size-long">{{
        $t("imageSizeLargerThan6MB")
      }}</span>
      <span v-if="webErrorDimension" class="size-long">{{
        $t("imageDimensionsAreSmallerThan1800900")
      }}</span>
      <img :src="webImage" />
      <label
        for="editImageWeb"
        class="upload"
        :class="{ rtl: $i18n.locale == 'ar' }"
      >
        <span>{{ $t("editImage") }}</span>
      </label>
      <input
        type="file"
        id="editImageWeb"
        accept="image/png, image/jpeg"
        @change="uploadBannerWeb"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "AddBanner",
  data() {
    return {
      sort: "",
      productId: "",
      appImage: "",
      file1: null,
      appSizeLong: false,
      appErrorDimension: false,
      file2: null,
      webImage: "",
      webSizeLong: false,
      webErrorDimension: false,
    };
  },
  methods: {
    uploadBannerApp($event) {
      const reader = new FileReader();
      const image = new Image();

      if (!$event.target.files[0]) return;
      else {
        if ($event.target.files[0].size > 6300000) {
          this.appSizeLong = true;
          this.appErrorDimension = false;
        } else {
          this.file1 = $event.target.files[0];
          reader.onload = (e) => {
            image.onload = () => {
              if (image.width >= 300 && image.height >= 200) {
                this.appErrorDimension = false;
                this.appImage = e.target.result;
              } else {
                this.appErrorDimension = true;
              }
            };
            image.src = e.target.result;
          };
          reader.readAsDataURL($event.target.files[0]);
          this.appSizeLong = false;
        }
      }

      $event.target.value = "";
    },
    uploadBannerWeb($event) {
      const reader = new FileReader();
      const image = new Image();

      if (!$event.target.files[0]) return;
      else {
        this.file2 = $event.target.files[0];
        if ($event.target.files[0].size > 6300000) {
          this.webSizeLong = true;
          this.webErrorDimension = false;
        } else {
          reader.onload = (e) => {
            image.onload = () => {
              if (image.width >= 1800 && image.height >= 900) {
                this.webErrorDimension = false;
                this.webImage = e.target.result;
              } else {
                this.webErrorDimension = true;
              }
            };
            image.src = e.target.result;
          };
          reader.readAsDataURL($event.target.files[0]);
          this.webSizeLong = false;
        }
      }

      $event.target.value = "";
    },
  },
};
</script>

<style scoped>
.modal .modal-body img {
  width: 100%;
  border-radius: 5px;
  max-height: 300px;
  object-fit: cover;
}
.modal .modal-body label.upload {
  width: 100%;
  font-size: 16px;
  text-align: right;
  margin-top: 10px;
  color: var(--primary-orange);
  cursor: pointer;
}
.modal .modal-body label.upload.rtl {
  text-align: left;
}

.size-long {
  color: #f73d17 !important;
  margin-bottom: 5px;
  font-size: 12px;
}
</style>
