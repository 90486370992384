<template>
  <div class="modal-body">
    <!-- Add Category Name With Arabic -->
    <div>
      <label for="titleAR" class="form-label">{{
        $t("categoryTitle") + "(" + $t("ar") + ")"
      }}</label>
      <input
        type="text"
        class="form-control input-text"
        id="titleAR"
        v-model="titleAR"
        dir="auto"
        required
      />
    </div>

    <!-- Add Category Name With English -->
    <div>
      <label for="titleEN" class="form-label">{{
        $t("categoryTitle") + "(" + $t("en") + ")"
      }}</label>
      <input
        type="text"
        class="form-control input-text font-poppins"
        id="titleEN"
        v-model="titleEN"
        dir="auto"
        required
      />
    </div>

    <!-- Add Sort -->
    <div>
      <label for="sort" class="form-label">{{
        $t("listCategoryTable[2]")
      }}</label>
      <input
        type="number"
        min="1"
        class="form-control input-text font-poppins"
        id="sort"
        :value="categoriesLength"
        @input="getSort"
        required
      />
    </div>

    <!-- Upload Icon -->
    <div class="upload-icon">
      <label class="form-label">{{ $t("icon") }}</label>
      <div v-if="!icon.length">
        <span>{{ $t("uploadPng") }}</span>
        <span v-if="iconSize" class="size-long">{{
          $t("thisIconLargerThan")
        }}</span>
        <span v-if="iconDimension" class="size-long">{{
          $t("iconDimensions")
        }}</span>
        <label for="uploadIcon" class="form-control input-text upload-new">
          <img src="@/assets/images/uploadImage.png" alt="upload" />
          <span>{{ $t("uploadYourIcon") }}</span>
        </label>
      </div>
      <div v-else class="edit">
        <span v-if="iconSize" class="size-long">{{
          $t("thisIconLargerThan")
        }}</span>
        <span v-if="iconDimension" class="size-long">{{
          $t("iconDimensions")
        }}</span>
        <div class="d-flex align-items-center gap-3">
          <div class="image d-flex align-items-center justify-content-center">
            <img :src="icon[0]" alt="icon" class="my-icon" ref="myIcon" />
          </div>
          <div>
            <p class="icon-name iconn">{{ icon[1] }}</p>
            <label for="uploadIcon" class="upload">
              <span>{{ $t("editIcon") }}</span>
            </label>
          </div>
        </div>
      </div>

      <input type="file" id="uploadIcon" accept="image/*" @change="getImage" />
    </div>
  </div>
</template>

<script>
import { useFetchAll } from "@/stores/FetchAll";
import { mapState } from "pinia";

export default {
  name: "AddCategory",
  data() {
    return {
      titleAR: "",
      titleEN: "",
      sort: "",
      icon: [],
      iconSize: false,
      iconDimension: false,
      file: null,
    };
  },
  computed: {
    ...mapState(useFetchAll, ["categoriesLength"]),
  },
  methods: {
    getSort($event) {
      this.sort = $event.target.value;
    },
    getImage($event) {
      const reader = new FileReader();
      const image = new Image();

      this.file = $event.target.files[0];
      if (!this.file) return;
      else {
        if (this.file.size > 55000) {
          this.iconSize = true;
        } else {
          reader.onload = (e) => {
            image.src = e.target.result;
            image.onload = () => {
              if (image.width < 85 && image.height < 85) {
                this.iconDimension = false;
                this.icon[0] = e.target.result;
                this.icon[1] = this.file.name;
              } else {
                this.iconDimension = true;
              }
            };
          };
          reader.readAsDataURL(this.file);
          this.iconSize = false;
        }
      }

      $event.target.value = "";
    },
  },
  // updated() {
  //   let time = setInterval(() => {
  //     if (this.categories.length) {
  //       clearInterval(time);
  //       this.sort = this.categories.length + 1;
  //     }
  //   }, 500);
  // },
};
</script>

<style scoped>
.modal-body span {
  font-size: 12px;
  color: var(--black-alt);
}

.modal-body .upload-icon .size-long {
  color: #f73d17 !important;
  margin-top: 5px;
}
.modal-body .upload-icon label.upload-new {
  border-style: dashed;
  border-width: 2px;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
  max-height: 300px;
}
.modal-body .upload-icon label.upload-new span {
  font-weight: 500;
  color: var(--black);
  margin-top: 10px;
}

.modal-body .upload-icon .edit .size-long {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 14px;
}
.modal-body .upload-icon .edit .image {
  background: var(--bg-color);
  width: 60px;
  height: 60px;
  border-radius: 5px;
  flex-shrink: 0;
}
.modal-body .upload-icon .edit .image img {
  width: 36px;
  height: 36px;
  border-radius: 10px;
  object-fit: contain;
}
.modal-body .upload-icon .edit p.icon-name {
  font-size: 14px;
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--black-alt);
  font-family: Poppins, sans-serif;
}
.modal-body .upload-icon .edit p.icon-name + label {
  margin-bottom: 0;
}

.modal-body .upload-icon .edit label.upload span {
  width: 100%;
  font-size: 16px;
  color: var(--primary-orange);
  cursor: pointer;
}
@media (max-width: 575px) {
  .modal-body .upload-icon .edit p.icon-name {
    max-width: 280px;
    font-size: 12px;
  }
  .modal-body .upload-icon .edit label.upload span {
    font-size: 12px;
  }
}
@media (max-width: 420px) {
  .modal-body .upload-icon .edit p.icon-name {
    max-width: 180px;
  }
}
</style>
