import { defineStore } from "pinia";
import { ref } from "vue";
import axios from "axios";
import saveInfoAlert from "@/assets/js/saveInfoAlert";

export const useOtherSettingsInfo = defineStore("settings", () => {
  const times = ref([]);
  const emailSetting = ref([]);
  const twilio = ref([]);
  const onlinePayment = ref([]);
  const shareMessage = ref([]);
  const mapboxToken = ref([]);
  const apiKey = ref([]);
  const publisher = ref([]);
  const stripe = ref([]);

  async function getOtherSettings() {
    await axios
      .get("dashboard/settings")
      .then((res) => {
        const data = res.data.data;
        times.value = data.slice(0, 6);
        onlinePayment.value = data.slice(7, 10);
        apiKey.value = data[10].value;
        shareMessage.value = data.slice(18, 21);
        mapboxToken.value = data[22].value;
        emailSetting.value = data.slice(23, 30);
        twilio.value = data.slice(30, 32);
        publisher.value = data.slice(33, 36);
        stripe.value = data.slice(36, 38);
      })
      .catch((err) => {
        saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
      });
  }
  return {
    times,
    emailSetting,
    twilio,
    onlinePayment,
    apiKey,
    publisher,
    stripe,
    shareMessage,
    mapboxToken,
    getOtherSettings,
  };
});
