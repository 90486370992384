<template>
  <router-view></router-view>
</template>

<script setup>
import { onMounted } from "vue";
import { usePusherStore } from "@/stores/PusherNotification.js";

const pusherStore = usePusherStore();
onMounted(() => {
  pusherStore.initEcho();
});
</script>

<style>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
@import "@/assets/css/bootstrap.min.css";
@import "@/assets/css/all.min.css";
@import "@/assets/css/globalTops.css";
@import "@/assets/css/ourFramework.css";
</style>
