<template>
  <div class="statistics">
    <h4 class="title">{{ $t("linksList[5].title") }}</h4>
    <TableStat />
  </div>
</template>

<script>
import TableStat from "@/components/statistics/TableStat.vue";
export default {
  name: "StatisticsAnalysis",
  components: {
    TableStat,
  },
};
</script>
