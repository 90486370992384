<template>
  <div class="custom-box">
    <div class="title d-flex justify-content-between align-items-center gap-2">
      <h4 v-text="$t('systemStat')"></h4>
      <!-- <div class="dates d-flex gap-md-3 gap-1">
        <div class="date">11.02.2023</div>
        <div class="date">5.30 Am</div>
      </div> -->
    </div>
    <div class="mini-boxes">
      <StatBox
        :title="$t('pageView')"
        :listBox="listBox[0]"
        :number="statistic.views_count"
      />
      <StatBox
        :title="$t('newAd')"
        :listBox="listBox[1]"
        :number="statistic.new_products_count"
      />
      <StatBox
        :title="$t('users')"
        :listBox="listBox[2]"
        :number="statistic.users_count"
      />
      <StatBox
        :title="$t('totalAd')"
        :listBox="listBox[3]"
        :number="statistic.total_products"
      />
    </div>
  </div>
</template>
<script>
import StatBox from "./StatBox.vue";
/** Importing Images **/
import eye from "@/assets/images/eye.png";
import ad from "@/assets/images/ad.png";
import users from "@/assets/images/users.png";
import group from "@/assets/images/group.png";

export default {
  name: "StatsBoxes",
  props: ["statistic"],
  components: {
    StatBox,
  },
  data() {
    return {
      listBox: [
        {
          src: eye,
          color: "rgba(255, 233, 207, 0.3)",
        },
        {
          src: ad,
          color: "rgba(195, 252, 241, 0.3)",
        },
        {
          src: users,
          color: "rgba(247, 232, 228, 0.3)",
        },
        {
          src: group,
          color: "rgba(99, 186, 171, 0.1)",
        },
      ],
    };
  },
};
</script>

<style scoped>
.title {
  margin-bottom: 30px;
}
.title h4 {
  margin: 0;
}
@media (max-width: 767px) {
  .title {
    margin-bottom: 15px;
  }
  .title h4 {
    font-size: 16px;
  }
  .title .dates {
    font-size: 13px;
    flex-direction: column;
    align-items: flex-end;
  }
}
.title .date {
  color: var(--black-alt);
}
.mini-boxes {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 30px;
}
@media (max-width: 767px) {
  .mini-boxes {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
</style>
