<template>
  <div class="our-grid">
    <StatsBoxes :statistic="homeData" />
    <AdsBoxes :products="homeData.last_products" />
  </div>
  <div class="our-grid amount">
    <TotalRevenue :revenue="homeData" />
    <CommRecord :commission="homeData" />
  </div>
  <!-- <SaleChart :chart="homeData.chart" :chartKeys="chartKeys" /> -->
  <SaleChart />
</template>

<script>
import { useHomePage } from "@/stores/FetchHomePage";
import { mapActions, mapState } from "pinia";

import StatsBoxes from "./StatsBoxes.vue";
import AdsBoxes from "./AdsBoxes.vue";
import TotalRevenue from "./TotalRevenue.vue";
import CommRecord from "./CommRecord.vue";
import SaleChart from "./SaleChart.vue";

export default {
  name: "InfoPage",
  components: {
    StatsBoxes,
    AdsBoxes,
    TotalRevenue,
    CommRecord,
    SaleChart,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(useHomePage, ["homeData"]),
  },
  methods: {
    ...mapActions(useHomePage, ["fetchData"]),
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style>
.box > div {
  padding: 30px 20px;
}
.box p {
  display: flex;
  gap: 10px;
  font-weight: 500;
  align-items: center;
}
.box p span:first-child {
  font-size: 24px;
  font-weight: 500;
}
.box p span:last-child {
  font-size: 32px;
  font-weight: 600;
}
@media (max-width: 767px) {
  .box {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  .box > div {
    padding: 15px 12px;
  }
  .box p span:first-child {
    font-size: 14px;
  }
  .box p span:last-child {
    font-size: 17px;
  }
}

.amount {
  margin: 40px 0;
}
</style>
