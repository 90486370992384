<template>
  <div>
    <h4 class="title">{{ $t("linksList[2].title") }}</h4>
    <TableSpecification />
  </div>
</template>

<script>
import TableSpecification from "@/components/specifications/TableSpecification.vue";

export default {
  name: "AdSpecification",
  components: {
    TableSpecification,
  },
};
</script>
