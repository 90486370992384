<template>
  <form
    class="searching d-flex flex-column flex-md-row flex-wrap gap-4"
    @submit.prevent="search"
  >
    <!-- Publisher Name -->
    <div class="publisher-name">
      <input
        type="text"
        class="form-control input-text bg-transparent"
        :placeholder="$t('enterPublisherName')"
        v-model="publisherName"
      />
    </div>

    <!-- Select Country -->
    <div class="dropdown custom-select countries">
      <button
        class="dropdown-toggle d-flex align-items-center justify-content-between"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span v-if="country.length === 0">{{ $t("selectCountry") }}</span>
        <span v-if="country.length !== 0">{{ country }}</span>
        <i class="bi bi-chevron-down"></i>
      </button>
      <ul class="dropdown-menu">
        <li>
          <button
            type="button"
            class="dropdown-item disabled"
            :class="{ rtl: $i18n.locale === 'ar' }"
          >
            {{ $t("selectCountry") }}
          </button>
        </li>
        <li v-for="country in countries" :key="country.id">
          <button
            type="button"
            class="dropdown-item country"
            :class="{ rtl: $i18n.locale === 'ar' }"
            @click="choose($event, '.country', country.id)"
          >
            <img :src="country.src" :alt="country.alt" />
            {{ $t(`countryName[${country.id - 1}]`) }}
          </button>
        </li>
      </ul>
    </div>

    <!-- Select Status -->
    <div class="dropdown custom-select">
      <button
        class="dropdown-toggle d-flex align-items-center justify-content-between"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span v-if="type.length === 0">{{ selectStatus }}</span>
        <span v-if="type.length !== 0">{{ type }}</span>
        <i class="bi bi-chevron-down"></i>
      </button>
      <ul class="dropdown-menu">
        <li>
          <button
            class="dropdown-item disabled"
            :class="{ rtl: $i18n.locale === 'ar' }"
          >
            {{ selectStatus }}
          </button>
        </li>
        <li>
          <button
            class="dropdown-item status"
            type="button"
            :class="{ rtl: $i18n.locale === 'ar' }"
            :data-type="type1"
            @click="choose($event, '.status')"
          >
            {{ $t("yes") }}
          </button>
        </li>
        <li>
          <button
            class="dropdown-item status"
            type="button"
            :class="{ rtl: $i18n.locale === 'ar' }"
            :data-type="type2"
            @click="choose($event, '.status')"
          >
            {{ $t("no") }}
          </button>
        </li>
      </ul>
    </div>

    <!-- Search Button -->
    <button type="submit" class="btn btn-orange">{{ $t("search") }}</button>

    <!-- Refresh Searching -->
    <button type="button" @click="refreshSearching">
      <img src="@/assets/images/refresh-search.png" alt="" />
    </button>
  </form>
</template>

<script>
import chooseItem from "@/assets/js/chooseItem";

import src1 from "@/assets/images/saudi.png";
import src2 from "@/assets/images/UAE.png";
import src3 from "@/assets/images/kuwait.png";
import src4 from "@/assets/images/qatar.png";
import src5 from "@/assets/images/bahrain.png";
import src6 from "@/assets/images/Oman.png";

export default {
  name: "SearchingBox",
  props: ["selectStatus", "type1", "type2"],
  data() {
    return {
      publisherName: "",
      type: "",
      dataType: "",
      country: "",
      countryId: "",
      countries: [
        {
          id: 1,
          src: src1,
          alt: "saudi-arabia",
        },
        {
          id: 2,
          src: src2,
          alt: "uae",
        },
        {
          id: 3,
          src: src3,
          alt: "kuwait",
        },
        {
          id: 4,
          src: src4,
          alt: "qatar",
        },
        {
          id: 5,
          src: src5,
          alt: "bahrain",
        },
        {
          id: 6,
          src: src6,
          alt: "oman",
        },
      ],
    };
  },
  methods: {
    choose($event, className, id) {
      chooseItem(className, $event);
      if (className === ".status") {
        this.type = $event.target.textContent;
        this.dataType = $event.target.dataset.type;
      } else {
        this.country = $event.target.textContent;
        this.countryId = id;
      }
    },
    search() {
      this.$emit("searchPublisher", [
        this.publisherName,
        this.countryId,
        this.dataType,
      ]);
    },
    refreshSearching() {
      this.publisherName = "";
      this.country = "";
      this.countryId = "";
      this.type = "";
      this.dataType = "";
      this.$emit("refresh");
      const status = document.querySelectorAll(".searching .status");
      const countries = document.querySelectorAll(".searching .country");
      status.forEach((state) => {
        state.classList.remove("active");
      });
      countries.forEach((country) => {
        country.classList.remove("active");
      });
    },
  },
  watch: {
    "$i18n.locale"() {
      this.publisherName = "";
      this.country = "";
      this.countryId = "";
      this.type = "";
      this.dataType = "";
    },
  },
};
</script>

<style scoped>
.publisher-name input {
  width: 330px;
}
@media (max-width: 767px) {
  .publisher-name input {
    width: 100%;
  }
}
.custom-select > button {
  color: var(--black-alt);
}
.custom-select ul {
  background-color: var(--white-color);
  border-radius: 5px;
}
.custom-select ul button.disabled {
  font-size: 12px;
}
.custom-select.countries ul button {
  font-size: 12px;
}
.custom-select.countries ul button img {
  margin: 0 5px;
}
form > button:first-of-type {
  padding: 10px 40px;
}
form > button:last-of-type {
  background-color: transparent;
}
form > button:last-of-type img {
  width: 45px;
}
</style>
