<template>
  <div class="time-period box-info">
    <h5 class="sub-title">{{ $t("timePeriod[0]") }}</h5>
    <TimeBlock
      :label="$t('timePeriod[1].label')"
      :desc="$t('timePeriod[1].desc')"
      :ex="$t('timePeriod[1].ex')"
      :placeholder="30"
      :mark="$t('minutes')"
      :className="'min'"
      v-model="refreshTime"
    />
    <TimeBlock
      :label="$t('timePeriod[2].label')"
      :desc="$t('timePeriod[2].desc')"
      :ex="$t('timePeriod[2].ex')"
      :placeholder="30"
      :mark="$t('days')"
      :className="'day'"
      v-model="stopSaleNot"
    />
    <TimeBlock
      :label="$t('timePeriod[3].label')"
      :desc="$t('timePeriod[3].desc')"
      :ex="$t('timePeriod[3].ex')"
      :placeholder="5"
      :mark="$t('days')"
      :className="'day'"
      v-model="stopSaleAfterNot"
    />
    <TimeBlock
      :label="$t('timePeriod[4].label')"
      :desc="$t('timePeriod[4].desc')"
      :ex="$t('timePeriod[4].ex')"
      :placeholder="15"
      :mark="$t('times')"
      :className="'time'"
      v-model="stopSaleAfterComplaint"
    />
    <TimeBlock
      :label="$t('timePeriod[5]')"
      :placeholder="3"
      :mark="$t('hours')"
      :className="'hour'"
      v-model="publishNewAd"
    />

    <!-- Input Of Hot Search -->
    <div class="time-block">
      <label for="hot-search" class="form-label">{{
        $t("timePeriod[6]")
      }}</label>
      <div class="position-relative" :class="{ rtl: $i18n.locale === 'ar' }">
        <input
          type="text"
          class="form-control input-text"
          id="hot-search"
          v-model="new_model"
          :placeholder="$t('timePeriod[7]')"
          @keyup.ctrl.enter="addModel"
        />
      </div>
      <p v-if="pleaseAddModel" class="please-add-model">
        {{ $t("pleaseEnterModel") }}
      </p>
    </div>

    <div class="models d-flex gap-2 flex-wrap">
      <button
        v-for="model in models"
        :key="model"
        class="btn btn-primary"
        type="button"
      >
        {{ model }}
        <i class="bi bi-x-lg" @click="removeModel(model)"></i>
      </button>
    </div>

    <!-- Loading For refresh Table -->
    <div v-if="loading" class="loading load">
      <div class="text-center">
        <img src="@/assets/images/loading.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import { useOtherSettingsInfo } from "@/stores/OtherSettingsInfo";
import { mapState } from "pinia";
import TimeBlock from "./TimeBlock.vue";
import saveInfoAlert from "@/assets/js/saveInfoAlert";
import axios from "axios";

export default {
  name: "TimePeriod",
  components: {
    TimeBlock,
  },
  data() {
    return {
      pleaseAddModel: false,
      new_model: "",
      models: [],
      refreshTime: "",
      stopSaleNot: "",
      stopSaleAfterNot: "",
      stopSaleAfterComplaint: "",
      publishNewAd: "",
      loading: false,
    };
  },
  computed: {
    ...mapState(useOtherSettingsInfo, ["times"]),
  },
  methods: {
    async addModel() {
      if (this.new_model) {
        await axios
          .post("dashboard/settings/hot-search/add", {
            word: this.new_model,
          })
          .then((res) => {
            this.models = res.data.data.value;
          })
          .catch((err) => {
            saveInfoAlert(
              this.$i18n.locale,
              "error",
              err.response.data.message
            );
          });
        this.new_model = "";
      } else {
        this.pleaseAddModel = true;
      }
    },
    async removeModel(word) {
      await axios
        .delete(`dashboard/settings/hot-search/delete?word=${word}`)
        .then((res) => {
          this.models = res.data.data.value;
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        });
    },
  },
  watch: {
    new_model() {
      this.pleaseAddModel = false;
    },
  },
  mounted() {
    this.loading = true;
    if (this.times) {
      setTimeout(() => {
        this.refreshTime = this.times[0].value;
        this.stopSaleNot = this.times[1].value;
        this.stopSaleAfterNot = this.times[2].value;
        this.stopSaleAfterComplaint = this.times[3].value;
        this.publishNewAd = this.times[4].value;
        this.models = JSON.parse(this.times[5].value);
        this.loading = false;
      }, 1500);
    }
  },
};
</script>

<style scoped>
.time-period .time-block .please-add-model {
  color: #f73d17;
  margin: 7px 0;
}
.time-period .models button {
  background-color: var(--primary-blue);
  font-size: 17px;
  font-weight: 500;
  font-family: Poppins, sans-serif;
}
@media (max-width: 767px) {
  .time-period .time-block .please-add-model {
    font-size: 10px;
  }
  .time-period .models button {
    font-size: 12px;
  }
}
</style>
