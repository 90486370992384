<template>
  <div
    class="modal fade"
    :id="id"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content popup-box">
        <!-- Header -->
        <div class="modal-header justify-content-center">
          <h1 class="modal-title" id="staticBackdropLabel">
            {{ $t("relatedClass") }}
          </h1>
        </div>
        <!-- Body -->
        <form @submit.prevent="confirmClasses">
          <TableClasses v-if="id == 'addChooseClass'" ref="tableClass" />
          <EditTableClasses v-else ref="tableClass" />
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-blue"
              data-bs-toggle="modal"
              @click="cancelClasses"
              :data-bs-target="
                id == 'addChooseClass' ? '#addSpecifications' : '#editSpec'
              "
            >
              {{ $t("cancel") }}
            </button>
            <button
              type="submit"
              class="btn btn-orange"
              data-bs-toggle="modal"
              :data-bs-target="
                id == 'addChooseClass' ? '#addSpecifications' : '#editSpec'
              "
            >
              {{ $t("confirm") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import TableClasses from "./TableClasses.vue";
import EditTableClasses from "./EditTableClasses.vue";
import { mapActions } from "pinia";
import { useSpecClasses } from "@/stores/SpecClasses";

export default {
  name: "ChooseClass",
  props: ["id"],
  components: { TableClasses, EditTableClasses },
  data() {
    return {
      items: [],
      ids: [],
    };
  },
  methods: {
    ...mapActions(useSpecClasses, ["addIds", "reset"]),

    confirmClasses() {
      this.ids = [];
      if (this.id === "addChooseClass") {
        this.items = document.querySelectorAll(".table-classes input");
      } else {
        this.items = document.querySelectorAll(".edit-table-classes input");
      }
      this.items.forEach((el) => {
        if (el.checked) {
          this.ids.push(el.id);
        }
      });
    },

    cancelClasses() {
      this.ids = [];
      if (this.id === "addChooseClass") {
        this.items = document.querySelectorAll(".table-classes input");
      } else {
        this.items = document.querySelectorAll(".edit-table-classes input");
      }
      this.items.forEach((el) => {
        el.checked = false;
      });
    },
  },
};
</script>

<style scoped>
.modal-fullscreen {
  min-height: 100%;
  height: auto;
}
.modal-fullscreen h1 {
  font-size: 24px;
}
@media (max-width: 575px) {
  .modal-fullscreen h1 {
    font-size: 18px;
  }
}
.modal .modal-footer {
  width: 450px;
  margin-left: auto;
  gap: 20px;
}
</style>
