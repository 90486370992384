<template>
  <div class="bank-info box-info">
    <h5 class="sub-title">{{ $t("bankInfo[0]") }}</h5>
    <div>
      <label for="bankNameAr" class="form-label"
        >{{ $t("bankInfo[1]") }}
        <span>({{ $t("ar") }})</span>
      </label>
      <input
        type="text"
        class="form-control input-text text-end"
        id="bankNameAr"
        v-model="bankNameAr"
      />
    </div>
    <div>
      <label for="bankNameEn" class="form-label"
        >{{ $t("bankInfo[2]") }}
        <span>({{ $t("en") }})</span>
      </label>
      <input
        type="text"
        class="form-control input-text"
        id="bankNameEn"
        v-model="bankNameEn"
      />
    </div>
    <div>
      <label for="holderNameAr" class="form-label"
        >{{ $t("bankInfo[3]") }}
        <span>({{ $t("ar") }})</span>
      </label>
      <input
        type="text"
        class="form-control input-text text-end"
        id="holderNameAr"
        v-model="holderNameAr"
      />
    </div>
    <div>
      <label for="holderNameEn" class="form-label"
        >{{ $t("bankInfo[4]") }}
        <span>({{ $t("en") }})</span>
      </label>
      <input
        type="text"
        class="form-control input-text"
        id="holderNameEn"
        v-model="holderNameEn"
      />
    </div>
    <div>
      <label for="account" class="form-label">{{ $t("bankInfo[5]") }} </label>
      <input
        type="text"
        class="form-control input-text"
        id="account"
        v-model="account"
      />
    </div>
    <div>
      <label for="iban" class="form-label">{{ $t("bankInfo[6]") }} </label>
      <input
        type="text"
        class="form-control input-text"
        id="iban"
        v-model="iban"
      />
    </div>

    <!-- Loading For refresh Table -->
    <div v-if="loading" class="loading load">
      <div class="text-center">
        <img src="@/assets/images/loading.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import saveInfoAlert from "@/assets/js/saveInfoAlert";
import axios from "axios";

export default {
  name: "BankInfo",
  data() {
    return {
      values: [],
      bankNameAr: "",
      bankNameEn: "",
      holderNameAr: "",
      holderNameEn: "",
      account: "",
      iban: "",
      loading: true,
    };
  },
  async mounted() {
    this.loading = true;
    await axios
      .get("dashboard/bank_settings")
      .then((res) => {
        this.values = res.data.data;
        this.bankNameAr = this.values[0].value;
        this.bankNameEn = this.values[1].value;
        this.holderNameAr = this.values[2].value;
        this.holderNameEn = this.values[3].value;
        this.account = this.values[4].value;
        this.iban = this.values[5].value;
      })
      .catch((err) => {
        saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style scoped>
.bank-info label span {
  color: var(--black-alt);
  display: inline-block;
  font-size: 14px;
}
@media (max-width: 575px) {
  .bank-info label span {
    font-size: 12px;
  }
}
</style>
