<template>
  <div class="bank-account settings">
    <h4 class="title">{{ $t("listSystem[0].title") }}</h4>
    <form class="d-flex flex-xl-row flex-column" @submit.prevent="save">
      <BankInfo ref="bank" />
      <InvoiceInfo ref="invoice" />
    </form>

    <!-- Loading For refresh Table -->
    <div v-if="loading" class="loading load">
      <div class="text-center">
        <img src="@/assets/images/loading.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import BankInfo from "@/components/systemSetting/bank/BankInfo.vue";
import InvoiceInfo from "@/components/systemSetting/bank/InvoiceInfo.vue";
import saveInfoAlert from "@/assets/js/saveInfoAlert";
import axios from "axios";

export default {
  name: "BankAccount",
  components: {
    BankInfo,
    InvoiceInfo,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async save() {
      const bank = this.$refs.bank;
      const invoice = this.$refs.invoice;
      const data = {
        BANK_NAME_AR: bank.bankNameAr,
        BANK_NAME_EN: bank.bankNameEn,
        HOLDER_NAME_AR: bank.holderNameAr,
        HOLDER_NAME_EN: bank.holderNameEn,
        BANK_ACCOUNT: bank.account,
        IBAN: bank.iban,
        COMPANY_NAME: invoice.companyName,
        EMAIL_ADDRESS: invoice.email,
        PHONE_NUMBER: invoice.phoneKey + invoice.phone,
        VAT_NUMBER: invoice.vat,
        COUNTRY: invoice.country,
        CITY: invoice.city,
        NEIGHBORHOOD: invoice.neighborhood,
        STREET_NAME: invoice.street,
        BUILDING_NO: invoice.buildingNo,
        POST_NUMBER: invoice.post,
      };
      this.loading = true;
      await axios
        .post("dashboard/bank_settings/update", data)
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
