<template>
  <div class="responsive-table">
    <table class="custom-table table-bordered">
      <thead>
        <tr>
          <th scope="col" v-for="head in 7" :key="head">
            {{ $t(`listCommission[2].table[${head - 1}]`) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in usersCommission" :key="user.id">
          <td>{{ user.user.id }}</td>
          <td>{{ user.user.name }}</td>
          <td>{{ user.user.discount_rate }}%</td>
          <td>{{ user.commissions_total_amount }}</td>
          <td>{{ user.confirmed_commissions }}</td>
          <td>{{ user.pending_commissions }}</td>
          <td>
            <button
              type="button"
              class="bg-transparent"
              data-bs-toggle="modal"
              data-bs-target="#setDiscountRate"
              :class="{ rtl: $i18n.locale === 'ar' }"
              @click="getUserId(user)"
            >
              {{ $t("listCommission[2].setDiscountRate") }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <ModalPopup
      id="setDiscountRate"
      width="600px"
      @send="setDiscount"
      @cancelModal="cancelRate"
      ref="discountModal"
    >
      <div class="modal-body">
        <div>
          <label :class="{ rtl: $i18n.locale == 'ar' }">{{
            $t("listCommission[0].rate")
          }}</label>
          <div class="position-relative">
            <input
              type="text"
              class="form-control input-text font-poppins"
              @keypress="onlyNumber"
              @keyup="valideRate"
              v-model="rate"
              required
            />
            <span
              class="value position-absolute"
              :class="{ rtl: $i18n.locale === 'ar' }"
              >%</span
            >
          </div>
          <span v-if="rangeRate" class="range-rate">{{
            $t("listCommission[2].enterCorrectRate")
          }}</span>
          <span class="note">{{
            $t("listCommission[2].ThisRateWillBeStartedFromTheNextPayment")
          }}</span>
        </div>
      </div>
    </ModalPopup>

    <!-- For Loading -->
    <div v-if="loading" class="loading load">
      <div class="text-center">
        <img src="@/assets/images/loading.svg" alt="spinner" />
      </div>
    </div>
  </div>
</template>

<script>
import ModalPopup from "../global/ModalPopup.vue";
import onlyNumber from "@/assets/js/onlyNumber";
import saveInfoAlert from "@/assets/js/saveInfoAlert";

import { useCommission } from "@/stores/Commission";
import axios from "axios";
import { mapActions, mapState } from "pinia";

export default {
  name: "TableUsercomm",
  components: {
    ModalPopup,
  },
  data() {
    return {
      userId: "",
      rate: "",
      rangeRate: false,
      currentDiscount: "",
      loading: false,
    };
  },
  computed: {
    ...mapState(useCommission, ["usersCommission"]),
  },
  methods: {
    ...mapActions(useCommission, ["getUsersCommission", "getDate"]),
    onlyNumber,
    getUserId(user) {
      this.userId = user.user.id;
      if (user.user.discount_rate) {
        this.rate = user.user.discount_rate;
      } else {
        this.rate = 0;
      }
    },
    // This function checks if the percentage written into input is between 0 and 100
    valideRate() {
      if (this.rate > 100) {
        this.rangeRate = true;
      } else {
        this.rangeRate = false;
      }
    },
    async setDiscount() {
      if (!this.rangeRate) {
        this.loading = true;
        await axios
          .post(`dashboard/commissions/${this.userId}/set-discount-rate`, {
            discount: this.rate,
          })
          .then((res) => {
            saveInfoAlert(this.$i18n.locale, "success", res.data.message);
            this.$refs.discountModal.closeModalButton.click();
            this.$emit("refreshUsers");
          })
          .catch((err) => {
            saveInfoAlert(
              this.$i18n.locale,
              "error",
              err.response.data.message
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    cancelRate() {
      this.rate = "";
      this.rangeRate = false;
    },
  },
};
</script>

<style scoped>
.custom-table {
  min-width: 1200px;
}
.custom-table button {
  font-weight: 500;
  color: var(--primary-blue);
}
.modal-body span.range-rate {
  color: #f73d17;
  margin-top: 10px;
  font-weight: 500;
  font-size: 13px;
}
.modal-body span.note {
  font-size: 13px;
  font-weight: 500;
  color: var(--black-alt);
  text-transform: capitalize;
  margin-top: 7px;
}

/* Especially the percentage sign */
.modal-body span.value {
  font-size: 18px;
  font-weight: 600;
  color: var(--black);
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.modal-body span.value.rtl {
  font-size: 20px;
  right: auto;
  left: 20px;
}
</style>
