<template>
  <div class="box-info font-poppins">
    <h5 class="sub-title">Stripe Information</h5>
    <div>
      <label for="publishableKey" class="form-label"
        >Stripe Publishable Key</label
      >
      <input
        type="text"
        class="form-control input-text"
        id="publishableKey"
        v-model="publishableKey"
        autocomplete="off"
      />
    </div>
    <div>
      <label for="secretKey" class="form-label">Stripe Secret Key</label>
      <input
        type="text"
        class="form-control input-text"
        id="secretKey"
        v-model="secretKey"
        autocomplete="off"
      />
    </div>
  </div>
</template>

<script>
import { useOtherSettingsInfo } from "@/stores/OtherSettingsInfo";
import { mapState } from "pinia";
export default {
  name: "StripeInfo",
  data() {
    return {
      publishableKey: "",
      secretKey: "",
    };
  },
  computed: {
    ...mapState(useOtherSettingsInfo, ["stripe"]),
  },
  mounted() {
    if (this.stripe) {
      setTimeout(() => {
        this.publishableKey = this.stripe[0].value;
        this.secretKey = this.stripe[1].value;
      }, 1500);
    }
  },
};
</script>

<style scoped>
label {
  font-family: "Poppins", sans-serif;
}
</style>
