import saveInfoAlert from "@/assets/js/saveInfoAlert";
import axios from "axios";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useCommission = defineStore("commission", () => {
  const usersCommission = ref([]);
  const userOnlineAmount = ref("");
  const userOfflineAmount = ref("");
  const pagesUsers = ref("");
  const current_page = ref("");
  const next_page = ref("");
  const prev_page = ref("");

  const commissionrecords = ref([]);
  const recordOnlineAmount = ref("");
  const recordOfflineAmount = ref("");
  const pagesRecords = ref("");
  const current_page_records = ref("");
  const next_page_records = ref("");
  const prev_page_records = ref("");

  const loading = ref(false);

  async function getUsersCommission(
    startDate,
    endDate,
    search,
    status,
    records,
    page
  ) {
    loading.value = true;
    await axios
      .get("dashboard/commissions/users-commission", {
        params: {
          from_date: startDate,
          to_date: endDate,
          search,
          status,
          records,
          page,
        },
      })
      .then((res) => {
        usersCommission.value = res.data.data.commissions;
        userOnlineAmount.value = res.data.data.online_amount;
        userOfflineAmount.value = res.data.data.offline_amount;
        pagesUsers.value = res.data.pagination.last_page;
        current_page.value = res.data.pagination.current_page;
        next_page.value = res.data.pagination.next_page_url;
        prev_page.value = res.data.pagination.prev_page_url;
      })
      .catch((err) => {
        saveInfoAlert("en", "error", err.response.data.message);
      })
      .finally(() => {
        loading.value = false;
      });
  }

  async function getCommissionRecords(
    startDate,
    endDate,
    search,
    status,
    records,
    page
  ) {
    loading.value = true;
    await axios
      .get("dashboard/commissions/commission-records", {
        params: {
          from_date: startDate,
          to_date: endDate,
          search,
          status,
          records,
          page,
        },
      })
      .then((res) => {
        commissionrecords.value = res.data.data.commissions;
        recordOnlineAmount.value = res.data.data.online_amount;
        recordOfflineAmount.value = res.data.data.offline_amount;
        pagesRecords.value = res.data.pagination.last_page;
        current_page_records.value = res.data.pagination.current_page;
        next_page_records.value = res.data.pagination.next_page_url;
        prev_page_records.value = res.data.pagination.prev_page_url;
      })
      .catch((err) => {
        saveInfoAlert("en", "error", err.response.data.message);
      })
      .finally(() => {
        loading.value = false;
      });
  }

  function getDate() {
    const date = new Date();
    const priorDate = new Date(new Date().setDate(date.getDate() - 30));
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const priorday = priorDate.getDate();
    const priormonth = priorDate.getMonth() + 1;
    const prioryear = priorDate.getFullYear();
    const endDate = `${year}-${month}-${day}`;
    const startDate = `${prioryear}-${priormonth}-${priorday}`;
    return [startDate, endDate];
  }
  return {
    usersCommission,
    userOnlineAmount,
    userOfflineAmount,
    commissionrecords,
    recordOnlineAmount,
    recordOfflineAmount,
    pagesUsers,
    current_page,
    next_page,
    prev_page,
    loading,
    getUsersCommission,
    pagesRecords,
    current_page_records,
    next_page_records,
    prev_page_records,
    getCommissionRecords,
    getDate,
  };
});
