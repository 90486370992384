<template>
  <div class="amounts d-flex flex-wrap gap-md-5 gap-3">
    <p>{{ $t("online") }} {{ recordOnlineAmount }} {{ $t("sr") }}</p>
    <p>{{ $t("offline") }} {{ recordOfflineAmount }} {{ $t("sr") }}</p>
  </div>
  <div class="responsive-table">
    <table class="custom-table table-bordered">
      <thead>
        <tr>
          <th scope="col" v-for="head in 7" :key="head">
            {{ $t(`listCommission[3].table[${head - 1}]`) }}
          </th>
          <th scope="col" class="option">
            {{ $t(`listCommission[3].table[7]`) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="record in commissionrecords" :key="record.id">
          <td>{{ record.name }}</td>
          <td class="img">
            <button
              v-if="record.voucher"
              type="button"
              class="btn image-popup"
              data-bs-toggle="modal"
              :data-bs-target="'#' + record.id"
              @click="getVoucher(record.voucher)"
            >
              <img :src="record.voucher" />
            </button>
            <span v-else>/</span>
            <PopupImage :img="voucher" :ident="record.id" />
          </td>
          <td>{{ record.discount_rate }}%</td>
          <td>{{ record.operation_time }}</td>
          <td>{{ record.amount }} {{ $t("sr") }}</td>
          <!-- <td v-if="record.type == 'online'">{{ $t("onlinePayment") }}</td>
          <td v-else>{{ $t("bankTransferred") }}</td> -->
          <td>{{ record.type }}</td>
          <td>{{ record.commission }} {{ $t("sr") }}</td>
          <td>
            <button
              v-if="record.status == '/'"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#viewInvoice"
              class="invoice"
              :class="{ rtl: $i18n.locale === 'ar' }"
            >
              {{ $t("listCommission[3].viewInvoice") }}
            </button>
            <span v-else-if="record.status == 'ignore'" class="ignored">
              {{ $t("listCommission[3].ignored") }}
            </span>
            <div v-else-if="record.status == 'pending'">
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#confirm"
                class="confirmed"
                :class="{ rtl: $i18n.locale === 'ar' }"
                @click="getId(record.id)"
              >
                {{ $t("listCommission[3].confirmAmount") }}
              </button>
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#ignore"
                class="ignore-btn"
                :class="{ rtl: $i18n.locale === 'ar' }"
                @click="getId(record.id)"
              >
                {{ $t("listCommission[3].ignore") }}
              </button>
            </div>
            <span v-else-if="record.status == 'draft'">
              {{ $t("listCommission[2].draft") }}
            </span>
            <span v-else>/</span>
          </td>
        </tr>
      </tbody>
    </table>
    <ModalPopup
      :id="'confirm'"
      :width="'600px'"
      @send="confirmAmount"
      @cancelModal="cancelConfirmAmount"
      ref="confirmAmount"
    >
      <ConfirmAmount ref="amounDialog" />
    </ModalPopup>
    <ModalPopup
      :id="'ignore'"
      :width="'600px'"
      @submit.prevent="ignoreRecord"
      @cancelModal="cancelIgnoreRecord"
      ref="ignoreRecord"
    >
      <IgnoreRecord ref="ignoreDialog" />
    </ModalPopup>
    <!-- For Loading -->
    <div v-if="loading" class="loading load">
      <div class="text-center">
        <img src="@/assets/images/loading.svg" alt="spinner" />
      </div>
    </div>
  </div>
</template>

<script>
import ModalPopup from "@/components/global/ModalPopup.vue";
import ConfirmAmount from "./ConfirmAmount.vue";
import IgnoreRecord from "./IgnoreRecord.vue";
import PopupImage from "@/components/global/PopupImage.vue";

import { useCommission } from "@/stores/Commission";
import { mapState } from "pinia";
import axios from "axios";
import saveInfoAlert from "@/assets/js/saveInfoAlert";

export default {
  name: "TableCommrecord",
  components: {
    ModalPopup,
    ConfirmAmount,
    IgnoreRecord,
    PopupImage,
  },
  emits: ["refreshRecords"],
  data() {
    return {
      idRecord: "",
      voucher: null,
      loading: false,
    };
  },
  computed: {
    ...mapState(useCommission, [
      "commissionrecords",
      "recordOnlineAmount",
      "recordOfflineAmount",
    ]),
  },
  methods: {
    viewInvoice() {
      console.log("View Invoice");
    },
    getId(id) {
      this.idRecord = id;
    },
    getVoucher(voucher) {
      this.voucher = voucher;
    },
    async ignoreRecord() {
      this.loading = true;
      await axios
        .post(`dashboard/commissions/${this.idRecord}/ignore-commission`, {
          ignore_reason_id: this.$refs.ignoreDialog.reasonId,
        })
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
          this.$refs.ignoreRecord.closeModalButton.click();
          this.$emit("refreshRecords");
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancelIgnoreRecord() {
      this.$refs.ignoreDialog.getReasons();
    },
    async confirmAmount() {
      this.loading = true;
      await axios
        .post(
          `dashboard/commissions/${this.idRecord}/confirm-commission?amount=${this.$refs.amounDialog.amount}`
        )
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
          this.$refs.confirmAmount.closeModalButton.click();
          this.$emit("refreshRecords");
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancelConfirmAmount() {
      this.$refs.amounDialog.amount = "";
    },
  },
};
</script>

<style scoped>
.amounts {
  margin-top: 50px;
}
.amounts p {
  font-size: 24px;
  font-weight: 600;
}
@media (max-width: 767px) {
  .amounts {
    margin-top: 30px;
  }
  .amounts p {
    font-size: 17px;
    font-weight: 500;
  }
}
table {
  min-width: 1500px;
}
table th.option {
  max-width: 300px;
}
table td {
  cursor: auto !important;
}
/* table td.img img {
  width: 50px;
} */
table td button {
  background-color: transparent;
  font-weight: 500;
}
table td button.image-popup img {
  border-radius: 0;
}
table td button.invoice {
  color: var(--black-alt);
}
table td .ignored {
  color: rgba(205, 23, 23, 1);
}
table td button.confirmed {
  color: rgba(28, 197, 85, 1);
}
table td button.ignore-btn {
  color: rgba(205, 23, 23, 1);
  margin-left: 50px;
}
table td button.ignore-btn.rtl {
  margin-left: auto;
  margin-right: 50px;
}
</style>
