<template>
  <div class="modal-body">
    <!-- Title English -->
    <div>
      <label for="titleEn" class="form-label">{{
        $t("videoTable[1]") + "(" + $t("en") + ")"
      }}</label>
      <input
        type="text"
        id="titleEn"
        class="form-control input-text font-poppins"
        v-model="titleEn"
        dir="auto"
        required
      />
    </div>

    <!-- Title Arabic -->
    <div>
      <label for="titleAr" class="form-label">{{
        $t("videoTable[1]") + "(" + $t("ar") + ")"
      }}</label>
      <input
        type="text"
        id="titleAr"
        class="form-control input-text"
        v-model="titleAr"
        dir="auto"
        required
      />
    </div>

    <!-- Sort -->
    <div>
      <label for="sort" class="form-label">{{ $t("videoTable[3]") }}</label>
      <input
        type="number"
        id="sort"
        class="form-control input-text font-poppins"
        v-model="sort"
        min="1"
        required
      />
    </div>

    <!-- Upload Video Cover -->
    <div v-if="!coverPhotoEdit" class="cover-photo">
      <label class="form-label">{{ $t("coverPhoto") }}</label>
      <span class="text-capitalize">{{ $t("uploadPhoto") }}</span>
      <span v-if="photoSizeLong" class="size-long">
        {{ $t("sizeLongMuch") }}
      </span>
      <span v-if="photoErrorDimension" class="size-long">
        {{ $t("imageDimensionsAreSmallerThan300200") }}
      </span>
      <div class="d-flex align-items-center gap-sm-3 gap-2 mt-2">
        <label class="form-control btn-blue input-text upload">
          {{ $t("chooseFile") }}
          <input
            type="file"
            accept="image/png, image/jpeg"
            ref="inputFile"
            @change="uploadcoverPhoto"
          />
        </label>
        <p class="font-poppins">{{ coverPhoto[1] }}</p>
      </div>
      <div v-if="coverPhoto.length" class="cover">
        <img :src="coverPhoto[0]" />
      </div>
    </div>

    <!-- Edit Video Cover -->
    <div v-else class="cover-photo" :class="{ rtl: $i18n.locale == 'ar' }">
      <label class="form-label">{{ $t("coverPhoto") }}</label>
      <span v-if="photoSizeLong" class="size-long">
        {{ $t("sizeLongMuch") }}
      </span>
      <span v-if="photoErrorDimension" class="size-long">
        {{ $t("imageDimensionsAreSmallerThan300200") }}
      </span>
      <div class="cover">
        <img :src="coverPhotoEdit" />
      </div>
      <label class="edit-photo">
        <span>{{ $t("editImage") }}</span>
        <input
          type="file"
          accept="image/png, image/jpeg"
          @change="editCoverPhoto"
        />
      </label>
    </div>

    <!-- Video Link -->
    <div>
      <label for="link" class="form-label">{{ $t("videoTable[2]") }}</label>
      <input
        type="url"
        id="link"
        class="form-control input-text font-poppins"
        v-model="link"
        placeholder="https://www.youtube.com"
        dir="auto"
        required
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "AddEditVideo",
  data() {
    return {
      titleEn: "",
      titleAr: "",
      sort: "",
      coverPhoto: [],
      coverPhotoEdit: "",
      file: null,
      photoSizeLong: false,
      photoErrorDimension: false,
      link: "",
    };
  },
  methods: {
    uploadcoverPhoto($event) {
      const reader = new FileReader();
      const image = new Image();

      if (!$event.target.files[0]) return;
      else {
        if ($event.target.files[0].size > 4500000) {
          this.photoSizeLong = true;
          this.photoErrorDimension = false;
        } else {
          this.file = $event.target.files[0];
          reader.onload = (e) => {
            image.onload = () => {
              if (image.width >= 330 && image.height >= 220) {
                this.photoErrorDimension = false;
                this.coverPhoto[0] = e.target.result;
                this.coverPhoto[1] = $event.target.files[0].name;
              } else {
                this.photoErrorDimension = true;
              }
            };
            image.src = e.target.result;
          };
          reader.readAsDataURL($event.target.files[0]);
          this.photoSizeLong = false;
        }
      }

      $event.target.value = "";
    },
    editCoverPhoto($event) {
      const reader = new FileReader();
      const image = new Image();

      if (!$event.target.files[0]) return;
      else {
        if ($event.target.files[0].size > 4500000) {
          this.photoSizeLong = true;
          this.photoErrorDimension = false;
        } else {
          this.file = $event.target.files[0];
          reader.onload = (e) => {
            image.onload = () => {
              if (image.width >= 330 && image.height >= 220) {
                this.photoErrorDimension = false;
                this.coverPhotoEdit = e.target.result;
              } else {
                this.photoErrorDimension = true;
              }
            };
            image.src = e.target.result;
          };
          reader.readAsDataURL($event.target.files[0]);
          this.photoSizeLong = false;
        }
      }

      $event.target.value = "";
    },
  },
};
</script>

<style scoped>
.cover-photo > span {
  font-size: 12px;
  color: var(--black-alt);
}
.cover-photo .size-long {
  color: #f73d17 !important;
  margin-top: 5px;
}
.cover-photo .upload {
  text-align: center;
  cursor: pointer;
  background-color: #f4f4f4;
  color: var(--black-alt);
  font-size: 14px;
  width: 130px;
  padding: 15px 0;
  margin: 0;
}
.cover-photo .cover {
  height: 200px;
  margin: 10px auto 0;
}
.cover-photo .cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

/******* For Edit Video (Edit Photo) ******/
.cover-photo .edit-photo {
  width: 100%;
  font-size: 16px;
  text-align: right;
  margin-top: 10px;
  color: var(--primary-orange);
  cursor: pointer;
}
.cover-photo.rtl .edit-photo {
  text-align: left;
}

@media (max-width: 575px) {
  .cover-photo span {
    font-size: 10px;
  }
  .cover-photo label.upload {
    width: 90px;
    padding: 7px 0;
    font-size: 12px !important;
  }
  .cover-photo label.upload + p {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
  }
}

@media (max-width: 355px) {
  .cover-photo label.upload + p {
    width: 160px;
  }
}
</style>
