<template>
  <div class="other-setting settings">
    <h4 class="title">{{ $t("listSystem[1].title") }}</h4>
    <form class="d-flex flex-xl-row flex-column" @submit.prevent="save">
      <TimePeriod ref="time" />
      <div class="box-info">
        <EmailSetting ref="email" />
        <TwilioSetting ref="twilio" />
        <OnlinePayment ref="online" />
        <InVoicing ref="invoicing" />
        <PusherInfo ref="pusher" />
        <StripeInfo ref="stripe" />
        <MapboxInfo ref="mapbox" />
        <ShareMessage ref="shareMessage" />
        <button
          class="btn btn-orange mt5 mb-5"
          type="submit"
          :class="{ rtl: $i18n.locale === 'ar' }"
        >
          {{ $t("save") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import TimePeriod from "@/components/systemSetting/other/TimePeriod.vue";
import EmailSetting from "@/components/systemSetting/other/EmailSetting.vue";
import TwilioSetting from "@/components/systemSetting/other/TwilioSetting.vue";
import OnlinePayment from "@/components/systemSetting/other/OnlinePayment.vue";
import InVoicing from "@/components/systemSetting/other/InVoicing.vue";
import PusherInfo from "@/components/systemSetting/other/PusherInfo.vue";
import StripeInfo from "@/components/systemSetting/other/StripeInfo.vue";
import MapboxInfo from "@/components/systemSetting/other/MapboxInfo.vue";
import ShareMessage from "@/components/systemSetting/other/ShareMessage.vue";
import axios from "axios";
import saveInfoAlert from "@/assets/js/saveInfoAlert";

import { mapActions } from "pinia";
import { useOtherSettingsInfo } from "@/stores/OtherSettingsInfo";

export default {
  name: "OtherSetting",
  components: {
    TimePeriod,
    EmailSetting,
    TwilioSetting,
    OnlinePayment,
    InVoicing,
    PusherInfo,
    StripeInfo,
    MapboxInfo,
    ShareMessage,
  },
  methods: {
    ...mapActions(useOtherSettingsInfo, ["getOtherSettings"]),
    async save() {
      const time = this.$refs.time;
      const email = this.$refs.email;
      const twilio = this.$refs.twilio;
      const online = this.$refs.online;
      const invoicing = this.$refs.invoicing;
      const pusher = this.$refs.pusher;
      const stripe = this.$refs.stripe;
      const mapbox = this.$refs.mapbox;
      const shareMessage = this.$refs.shareMessage;

      const data = {
        REFRESH_TIME: time.refreshTime,
        STOP_SALE_NOTIFICATION: time.stopSaleNot,
        STOP_SALE_AFTER_NOTIFICATION: time.stopSaleAfterNot,
        STOP_SALE_AFTER_COMPLAINT: time.stopSaleAfterComplaint,
        PUBLISH_NEW_AD: time.publishNewAd,
        HOST: email.host,
        PORT: email.port,
        EMAIL_USER_NAME: email.email,
        ACCOUNT_PASSWORD_HOST: email.password,
        FROM_EMAIL: email.fromEmail,
        EMAIL_SENDER: email.emailSender,
        BCC_EMAIL: email.bccEmail,
        TWILIO_SID: twilio.sid,
        TWILIO_AUTH_TOKEN: twilio.authToken,
        ONLINE_PAYMENT: online.isOnlinePayment ? 1 : 0,
        PAYTABS_PROFILE_ID: online.profileID,
        PAYTABS_API_KEY: online.apiKey,
        INVOICING_API_KEY: invoicing.api,
        PUSHER_APP_ID: pusher.id,
        PUSHER_APP_KEY: pusher.key,
        PUSHER_APP_SECRET: pusher.secret,
        STRIPE_PUBLISHABLE_KEY: stripe.publishableKey,
        STRIPE_SECRET_KEY: stripe.secretKey,
        MAPBOX_TOKEN: mapbox.tokenAccess,
        SHARE_MESSAGE_AR: shareMessage.share_msg_ar,
        SHARE_MESSAGE_EN: shareMessage.share_msg_en,
        SHARE_MESSAGE_PLATFORM: shareMessage.share_msg_platform,
      };
      await axios
        .post("dashboard/settings/update", data)
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        });
    },
  },
  async mounted() {
    this.getOtherSettings();
  },
};
</script>

<style>
.other-setting input {
  font-family: "Poppins", sans-serif;
}
.other-setting button[type="submit"] {
  margin-left: auto;
  width: 230px;
}
.other-setting button[type="submit"].rtl {
  margin-left: 0;
  margin-right: auto;
}
@media (max-width: 575px) {
  .other-setting button[type="submit"] {
    width: 100%;
  }
}
</style>
