<template>
  <div class="custom-box">
    <h4 v-text="$t('commissionRecord')"></h4>
    <div class="our-grid box">
      <div class="rad-10" :class="flex">
        <p>
          <span>{{ $t("underAudit") }} : </span>
          <span>{{ mumberConversion(commission.commission_under_audit) }}</span>
        </p>
        <p>
          <span>{{ $t("amount") }} : </span>
          <span>{{
            mumberConversion(commission.commission_under_audit_amount)
          }}</span>
        </p>
      </div>
      <div class="rad-10" :class="flex">
        <p>
          <span>{{ $t("draft") }} : </span>
          <span>{{ mumberConversion(commission.commission_draft) }}</span>
        </p>
        <p>
          <span>{{ $t("amount") }} : </span>
          <span>{{
            mumberConversion(commission.commission_draft_amount)
          }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommRecord",
  props: ["commission"],
  data() {
    return {
      flex: "d-flex justify-content-between align-items-center flex-wrap flex-md-nowrap",
    };
  },
  methods: {
    mumberConversion(number) {
      let newNumber = 0;
      if (number >= 1000000) {
        newNumber = number / 1000000 + " " + this.$t("m");
      } else if (number >= 1000) {
        newNumber = number / 1000 + " " + this.$t("k");
      } else {
        newNumber = number;
      }
      return newNumber;
    },
  },
};
</script>

<style scoped>
.box > div:first-child {
  background-color: rgba(247, 136, 31, 0.05);
}
.box > div:last-child {
  background-color: rgba(4, 115, 192, 0.05);
}
</style>
