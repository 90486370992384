<template>
  <div
    class="modal fade"
    :id="ident"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content popup-box">
        <!-- Header -->
        <div v-if="title" class="modal-header justify-content-center">
          <h1 class="modal-title fs-4 text-center mb-5" id="exampleModalLabel">
            {{ title }}
          </h1>
        </div>
        <!-- Body -->
        <div class="modal-body">
          <slot />
          <div class="responsive-table" v-if="comments.length">
            <table class="custom-table table-bordered">
              <thead>
                <tr>
                  <th scope="col">{{ $t("userName") }}</th>
                  <th scope="col">{{ $t("content") }}</th>
                  <th scope="col">{{ $t("submitTime") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="c in comments" :key="c.id">
                  <td>{{ c.user.name }}</td>
                  <td>
                    <p class="content-comment">{{ c.content }}</p>
                  </td>
                  <td>{{ c.created_at }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else :style="{ marginTop: '-15px' }">
            {{ $t("noComments") }}
          </div>
        </div>
        <!-- Footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-orange" data-bs-dismiss="modal">
            {{ $t("close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdListPopup",
  props: ["ident", "title", "comments"],
};
</script>

<style scoped>
.modal .modal-dialog {
  max-width: 800px;
}
.modal .modal-dialog button {
  margin: auto;
}
.modal-content .responsive-table {
  margin-top: 0;
  margin-bottom: 30px;
  max-height: 250px;
}
.modal .modal-footer button {
  min-width: 230px;
  flex: 0;
}

.content-comment {
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}
</style>
