<template>
  <div class="publisher-list">
    <h4 class="title">{{ $t("PublisherManagement") }}</h4>
    <TablePublish />
  </div>
</template>

<script>
import TablePublish from "@/components/publisherList/TablePublish.vue";
export default {
  name: "PublisherPage",
  components: {
    TablePublish,
  },
};
</script>
