import { defineStore } from "pinia";
import { ref } from "vue";
import Pusher from "pusher-js";
import Echo from "laravel-echo";

export const usePusherStore = defineStore("echo", () => {
  const thereIsNotification = ref(0);
  const commissionNotification = ref(0);
  const complaintNotification = ref(0);

  function initEcho() {
    const token = localStorage.getItem("topz_admin_token");
    const userId = localStorage.getItem("topz_admin_id");

    // Pusher.logToConsole = true;
    new Pusher("8ed4420babdd399aab42", {
      cluster: "ap2",
      channelAuthorization: {
        endpoint: `https://apis.topz.app/broadcasting/auth`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      },
    });

    const echo = new Echo({
      broadcaster: "pusher",
      key: "8ed4420babdd399aab42",
      cluster: "ap2",
      encrypted: true,
      authEndpoint: "https://apis.topz.app/broadcasting/auth",
      auth: {
        headers: {
          authorization: "Bearer " + token,
        },
      },
    });

    echo.private(`App.Models.Admin.${userId}`).notification((data) => {
      if (data.title_en == "New Complaint") {
        complaintNotification.value++;
      } else {
        commissionNotification.value++;
      }
    });
  }
  return {
    initEcho,
    thereIsNotification,
    commissionNotification,
    complaintNotification,
  };
});
