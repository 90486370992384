<template>
  <div class="row body-table" :class="{ rtl: $i18n.locale == 'ar' }">
    <div class="col">{{ category.id }}</div>
    <div class="col name">
      <div>
        <button
          v-if="category.level == 1 || category.level == 2"
          type="button"
          class="toggle"
          :class="{ expand: expand }"
          data-bs-toggle="collapse"
          :data-bs-target="`#${category.id}`"
          aria-expanded="false"
          :aria-controls="`#${category.id}`"
          @click="toggleMenu"
        ></button>
        <span>{{ category.name }}</span>
      </div>
    </div>
    <div
      class="col"
      type="button"
      data-bs-toggle="modal"
      data-bs-target="#editSort"
      @click="getCategory(category)"
    >
      {{ category.sort }}
    </div>
    <div class="col">
      <input
        type="checkbox"
        class="custom-toggle"
        :defaultChecked="category.status"
        @change="changeStatus(category.id)"
      />
    </div>
    <div class="col">
      <img :src="category.icon" :style="{ width: '50px' }" />
    </div>
    <div class="col">
      <input
        type="checkbox"
        class="custom-toggle"
        :defaultChecked="category.recommended"
        @change="changeRecommended(category.id)"
      />
    </div>
    <!-- Options -->
    <div class="col d-flex justify-content-evenly">
      <button
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#editCategory"
        class="bg-transparent"
        @click="getCategoryInfo(category, '')"
      >
        <img src="@/assets/images/edit.svg" />
      </button>
      <button
        v-if="category.level == 1 || category.level == 2"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#addMinCategory"
        class="bg-transparent"
        @click="[getCategory(category), deleteSquare()]"
      >
        <img src="@/assets/images/add-square.svg" />
      </button>
      <button
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#deleteCategory"
        class="bg-transparent"
        @click="getCategory(category)"
      >
        <img src="@/assets/images/trash.svg" />
      </button>
    </div>

    <!-- Collapse - Mid Category -->
    <MidCategory
      :id="category.id"
      v-for="child in category.children"
      :key="child.id"
      :child="child"
      :parentId="category.id"
    />
  </div>
</template>

<script>
import MidCategory from "./MidCategory.vue";
import axios from "axios";
import saveInfoAlert from "@/assets/js/saveInfoAlert";
import { mapActions } from "pinia";
import { useFetchAll } from "@/stores/FetchAll";

export default {
  name: "GridTableCategory",
  props: ["category"],
  components: {
    MidCategory,
  },
  data() {
    return {
      expand: false,
    };
  },
  methods: {
    ...mapActions(useFetchAll, ["getCategory", "getCategoryInfo"]),
    toggleMenu() {
      this.expand = !this.expand;
    },
    deleteSquare() {
      this.expand = false;
      const items = document.querySelectorAll(".body-table .mid.collapse.show");
      items.forEach((el) => {
        el.classList.remove("show");
      });
    },
    async changeStatus(id) {
      await axios
        .post(`dashboard/categories/${id}/change-status`)
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        });
    },
    async changeRecommended(id) {
      await axios
        .post(`dashboard/categories/${id}/change-recommended`)
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        });
    },
  },
};
</script>

<style>
.body-table div.col {
  border-bottom: 1px solid #9e9e9e;
  border-right: 1px solid #9e9e9e;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: var(--black-alt);
}

.body-table div.name {
  justify-content: flex-start;
  min-width: 250px;
}
.body-table div.name div {
  display: flex;
  align-items: center;
  margin-left: 20px;
  gap: 10px;
  text-align: start;
}
.body-table.rtl div.name div {
  margin-left: 0;
  margin-right: 20px;
}
.body-table div.name .toggle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-shrink: 0; */
  background-color: var(--primary-orange);
  border-radius: 4px;
  width: 14px;
  height: 14px;
  cursor: pointer;
}
.body-table div.name .toggle::before,
.body-table div.name .toggle::after {
  content: "";
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
}
.body-table div.name .toggle::before {
  width: 2px;
  height: 8px;
  transform: rotate(0deg);
  transition: var(--transition);
}
.body-table div.name .toggle::after {
  width: 8px;
  height: 2px;
}
.body-table div.name .toggle.expand::before {
  transform: rotate(90deg);
}
@media (max-width: 767px) {
  .body-table div.col {
    font-size: 13px;
    height: 60px;
  }
}

/* Label For Popus */
.body-table .form-label {
  text-align: left;
}
.body-table.rtl .form-label {
  text-align: right;
}
.row {
  --bs-gutter-x: 0;
}
</style>
