<template>
  <div class="modal-body">
    <!-- Category Title Arabic -->
    <div>
      <img src="@/assets/images/trash.svg" />
    </div>
    <div>
      <p>{{ $t("areYouSureToDeleteThisCategory") }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeleteCategory",
};
</script>

<style scoped>
div {
  text-align: center;
}
div img {
  width: 40px;
}
p {
  font-size: 18px;
  font-weight: 500;
}
@media (max-width: 575px) {
  div img {
    width: 24px;
  }
  p {
    font-size: 14px;
  }
}
</style>
