<template>
  <div
    class="modal fade"
    :id="ident"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content position-relative">
        <button
          type="button"
          class="btn-close position-absolute"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
        <img :src="img" />
      </div>
    </div>
  </div>
</template>

<script scoped>
export default {
  name: "PopupImage",
  props: ["img", "ident"],
};
</script>

<style scoped>
button.btn-close {
  right: 0;
  margin: 10px;
  font-size: 20px;
}
.modal-content {
  border-radius: 15px;
  overflow: hidden;
  transform: scale(1.6);
  max-height: 550px;
}
@media (max-width: 992px) {
  .modal-content {
    transform: scale(1);
    border-radius: 7px;
    max-height: 600px;
  }
}
</style>
