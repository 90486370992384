<template>
  <div class="home">
    <h4 class="title">{{ $t("linksList[0].title") }}</h4>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "HomePage",
};
</script>

<style>
.home h4 {
  font-weight: 700;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .home h4 {
    font-size: 16px;
    margin-bottom: 17px;
  }
  h4.title {
    font-size: 18px;
  }
}
</style>
