<template>
  <div class="twilio-setting box-info">
    <h5 class="sub-title">{{ $t("twilioSetting[0]") }}</h5>
    <div>
      <label for="SID" class="form-label">{{ $t("twilioSetting[1]") }}</label>
      <input
        type="text"
        class="form-control input-text"
        id="SID"
        v-model="sid"
      />
    </div>
    <div>
      <label for="auth-token" class="form-label">{{
        $t("twilioSetting[2]")
      }}</label>
      <input
        type="text"
        class="form-control input-text"
        id="auth-token"
        v-model="authToken"
      />
    </div>
  </div>
</template>

<script>
import { useOtherSettingsInfo } from "@/stores/OtherSettingsInfo";
import { mapState } from "pinia";

export default {
  name: "TwilioSetting",
  data() {
    return {
      sid: "",
      authToken: "",
    };
  },
  computed: {
    ...mapState(useOtherSettingsInfo, ["twilio"]),
  },
  mounted() {
    if (this.twilio) {
      setTimeout(() => {
        this.sid = this.twilio[0].value;
        this.authToken = this.twilio[1].value;
      }, 1500);
    }
  },
};
</script>

<style scoped></style>
