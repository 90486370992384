<template>
  <div class="commission-record">
    <h4 class="title">{{ $t("listCommission[3].title") }}</h4>
    <SearchCommission
      ref="serachCommission"
      id="commissionRecord"
      @search="searchRecord"
    />
    <TableCommrecord @refreshRecords="refreshRecords" />
    <PaginationBox
      :pages="pagesRecords"
      :current_page="current_page_records"
      :next_page="next_page_records"
      :prev_page="prev_page_records"
      @chooseRec="chooseRecord"
      @thisPage="thisPage"
      @nextPage="nextPage"
      @prevPage="prevPage"
    />
    <!-- For Loading -->
    <div v-if="loading" class="loading load">
      <div class="text-center">
        <img src="@/assets/images/loading.svg" alt="spinner" />
      </div>
    </div>
  </div>
</template>

<script>
import SearchCommission from "@/components/commission/SearchCommission.vue";
import TableCommrecord from "@/components/commission/commissionRecord/TableCommrecord.vue";
import PaginationBox from "@/components/global/PaginationBox.vue";

import { useCommission } from "@/stores/Commission";
import { mapState, mapActions } from "pinia";
import { usePusherStore } from "@/stores/PusherNotification";

export default {
  name: "CommissionRecord",
  components: {
    SearchCommission,
    TableCommrecord,
    PaginationBox,
  },
  data() {
    return {
      records: 10,
      page: "",
    };
  },
  setup() {
    const pusherStore = usePusherStore();

    return {
      pusherStore,
    };
  },
  computed: {
    ...mapState(useCommission, [
      "loading",
      "pagesRecords",
      "current_page_records",
      "next_page_records",
      "prev_page_records",
    ]),
  },
  methods: {
    ...mapActions(useCommission, ["getCommissionRecords", "getDate"]),
    getData(record, page) {
      if (this.$refs.serachCommission.isSearch) {
        this.getCommissionRecords(
          this.$refs.serachCommission.startDate,
          this.$refs.serachCommission.endDate,
          this.$refs.serachCommission.username,
          this.$refs.serachCommission.status,
          record,
          page
        );
      } else {
        this.getCommissionRecords(
          "2020-01-01",
          this.$refs.serachCommission.endDate,
          "",
          "",
          record,
          page
        );
      }
    },
    searchRecord() {
      this.$refs.serachCommission.isSearch = true;
      this.getData(this.records, 1);
    },
    chooseRecord(record) {
      this.records = record;
      this.getData(record, 1);
    },
    thisPage(p) {
      this.getData(this.records, p);
      this.page = p;
    },
    nextPage() {
      this.getData(this.records, this.current_page_records + 1);
    },
    prevPage() {
      this.getData(this.records, this.current_page_records - 1);
    },
    refreshRecords() {
      this.getData(this.records, this.page);
    },
  },
  mounted() {
    this.pusherStore.commissionNotification = 0;
  },
};
</script>

<style scoped></style>
