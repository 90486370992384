<template>
  <div class="banner-setting">
    <h4 class="title">{{ $t("linksList[9].title") }}</h4>
    <TableBanner />
  </div>
</template>

<script>
import TableBanner from "@/components/bannerSetting/TableBanner.vue";

export default {
  name: "BannerSetting",
  components: {
    TableBanner,
  },
};
</script>

<style scoped></style>
