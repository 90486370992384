<template>
  <div>
    <SearchingBox
      :selectStatus="$t('selectEnableStatus')"
      type1="1"
      type2="0"
      @searchPublisher="searchPublisher"
      @refresh="refreshSearching"
    />
    <div class="responsive-table">
      <table class="custom-table table-bordered">
        <thead>
          <tr>
            <th scope="col" v-for="head in 8" :key="head">
              {{ $t(`tablePublish[${head - 1}]`) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="publisher in publishers" :key="publisher.id">
            <td
              data-bs-toggle="modal"
              data-bs-target="#publisherAd"
              @click="showProducts(publisher.id, '')"
              class="clickable"
            >
              {{ publisher.id }}
            </td>
            <td>
              <button
                type="button"
                class="btn image-popup"
                data-bs-toggle="modal"
                :data-bs-target="'#' + publisher.id"
              >
                <img :src="publisher.avatar" alt="avatar" />
              </button>
              <PopupImage :img="publisher.avatar" :ident="publisher.id" />
            </td>
            <td>{{ publisher.name }}</td>
            <td>
              <div class="d-flex align-items-center gap-3">
                <img width="24" :src="publisher.country.flag" />
                <span
                  class="text-nowrap"
                  :class="{ 'font-tajawal': $i18n.locale == 'ar' }"
                  >{{ publisher.country.name }}</span
                >
              </div>
            </td>
            <td>
              <input
                type="checkbox"
                class="custom-toggle"
                :defaultChecked="publisher.status"
                @change="changeStatus(publisher.id)"
              />
            </td>
            <td
              data-bs-toggle="modal"
              data-bs-target="#praiseTreat"
              @click="showPraiseTreat(publisher.id, 'all')"
              class="clickable"
            >
              {{ publisher.praise_count }}/{{ publisher.treat_count }}
            </td>
            <td>
              {{ publisher.on_sale_product }}/{{ publisher.on_stop_product }}
            </td>
            <td v-if="publisher.last_login">{{ publisher.last_login }}</td>
            <td v-else>-</td>
          </tr>
        </tbody>
      </table>
    </div>
    <PaginationBox
      :pages="pages"
      :current_page="current_page"
      :next_page="next_page"
      :prev_page="prev_page"
      @chooseRec="chooseRecord"
      @thisPage="thisPage"
      @nextPage="nextPage"
      @prevPage="prevPage"
    />

    <!-- This Popup is for Publisher Ads -->
    <PublishPopup
      :ident="'publisherAd'"
      :title="$t('publishAd')"
      :two="$t('sale')"
      valTwo="on_sale"
      :three="$t('stop')"
      valThree="on_stop"
      :titlesLength="5"
      @changeChoice="changeChoiceAds"
    >
      <tr v-for="pP in publisherProduct" :key="pP.id">
        <td>
          <button type="button" class="btn image-popup">
            <img v-if="pP.image" :src="pP.image" class="image-popup" />
            <img
              v-else
              src="@/assets/images/no-photo.webp"
              class="image-popup"
            />
          </button>
        </td>
        <td>{{ pP.title }}</td>
        <td>{{ pP.category?.name }}</td>
        <td>{{ pP.views }}</td>
        <td>{{ pP.publish_time }}</td>
      </tr>
      <div v-if="loadingAds" class="loading loadAds">
        <div class="text-center">
          <img src="@/assets/images/Pulse.svg" alt="spinner" />
        </div>
      </div>
    </PublishPopup>

    <!-- This Popup is for Like / Dislike -->
    <PublishPopup
      :ident="'praiseTreat'"
      :title="$t('praiseTreat')"
      :two="$t('praise')"
      valTwo="praise"
      :three="$t('treat')"
      valThree="treat"
      :titlesLength="4"
      @changeChoice="changeChoicePraiseTreat"
    >
      <tr v-for="pL in praiseList" :key="pL.id">
        <td>
          {{ pL.created_user.name }}
        </td>
        <td>
          <img v-if="pL.is_praise" src="@/assets/images/like.svg" alt="like" />
          <img v-else src="@/assets/images/dislike.svg" alt="dislike" />
          <div class="stars">
            <i
              v-for="star in +pL.rate"
              :key="star"
              class="fa-solid fa-star"
            ></i>
          </div>
        </td>
        <td>
          <p>{{ pL.content }}</p>
        </td>
        <td>{{ pL.publish_time }}</td>
      </tr>
      <div v-if="loadingAds" class="loading loadAds">
        <div class="text-center">
          <img src="@/assets/images/Pulse.svg" alt="spinner" />
        </div>
      </div>
    </PublishPopup>

    <!-- Loading... -->
    <div v-if="loading" class="loading load">
      <div class="text-center">
        <img src="@/assets/images/loading.svg" alt="spinner" />
      </div>
    </div>
  </div>
</template>

<script>
import PublishPopup from "./PublishPopup.vue";
import PopupImage from "@/components/global/PopupImage.vue";

import axios from "axios";
import PaginationBox from "../global/PaginationBox.vue";
import SearchingBox from "../global/SearchingBox.vue";
import saveInfoAlert from "@/assets/js/saveInfoAlert";

export default {
  name: "TablePublish",
  components: {
    PublishPopup,
    PopupImage,
    PaginationBox,
    SearchingBox,
  },
  data() {
    return {
      loading: false,
      publishers: [],
      publisherProduct: [],
      statusSale: "",
      praiseList: [],
      idPublisher: "",
      statusLike: "",
      loadingAds: false,

      records: 10,
      pages: "",
      current_page: "",
      next_page: "",
      prev_page: "",
      is_search: false,

      name: "",
      country: "",
      status: "",
    };
  },
  methods: {
    async getPublishers(record, page) {
      this.loading = true;
      await axios
        .get(`/dashboard/publishers?records=${record}&page=${page}`)
        .then((res) => {
          this.publishers = res.data.data;
          this.pages = res.data.pagination.last_page;
          this.current_page = res.data.pagination.current_page;
          this.next_page = res.data.pagination.next_page_url;
          this.prev_page = res.data.pagination.prev_page_url;
          this.loading = false;
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
          this.loading = false;
        });
    },
    chooseRecord(record) {
      this.records = record;
      if (this.is_search) {
        this.fetchPublishersAfterSearch(
          this.name,
          this.country,
          this.status,
          record,
          1
        );
      } else {
        this.getPublishers(record, 1);
      }
    },
    thisPage(p) {
      if (this.is_search) {
        this.fetchPublishersAfterSearch(
          this.name,
          this.country,
          this.status,
          this.records,
          p
        );
      } else {
        this.getPublishers(this.records, p);
      }
    },
    nextPage() {
      if (this.is_search) {
        this.fetchPublishersAfterSearch(
          this.name,
          this.country,
          this.status,
          this.records,
          this.current_page + 1
        );
      } else {
        this.getPublishers(this.records, this.current_page + 1);
      }
    },
    prevPage() {
      if (this.is_search) {
        this.fetchPublishersAfterSearch(
          this.name,
          this.country,
          this.status,
          this.records,
          this.current_page - 1
        );
      } else {
        this.getPublishers(this.records, this.current_page - 1);
      }
    },
    async searchPublisher(info) {
      const name = info[0] ? `name=${info[0]}` : "";
      this.name = name;
      const country = info[1] ? `country_id=${info[1]}` : "";
      this.country = country;
      const status = info[2] ? `status=${info[2]}` : "";
      this.status = status;
      let url = `dashboard/publishers?${name}&${country}&${status}&records=${this.records}&page=1`;

      this.loading = true;
      await axios
        .get(url)
        .then((res) => {
          this.publishers = res.data.data;
          this.pages = res.data.pagination.last_page;
          this.current_page = res.data.pagination.current_page;
          this.next_page = res.data.pagination.next_page_url;
          this.prev_page = res.data.pagination.prev_page_url;
          this.loading = false;
          this.is_search = true;
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
          this.loading = false;
          this.is_search = false;
        });
    },
    async fetchPublishersAfterSearch(name, country, status, records, page) {
      this.loading = true;
      await axios
        .get(
          `dashboard/publishers?${name}&${country}&${status}&records=${records}&page=${page}`
        )
        .then((res) => {
          this.publishers = res.data.data;
          this.pages = res.data.pagination.last_page;
          this.current_page = res.data.pagination.current_page;
          this.next_page = res.data.pagination.next_page_url;
          this.prev_page = res.data.pagination.prev_page_url;
          this.loading = false;
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
          this.loading = false;
        });
    },
    refreshSearching() {
      this.getPublishers(this.records, 1);
      this.is_search = false;
    },
    async changeStatus(id) {
      await axios
        .get(`dashboard/publishers/${id}/change-status`)
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        });
    },
    async showProducts(id, status) {
      const stat = this.statusSale ? this.statusSale : status;
      this.idPublisher = id;
      this.loadingAds = true;
      await axios
        .get(`dashboard/publishers/${id}/products`, {
          params: {
            status: stat,
            records: 100000,
          },
        })
        .then((res) => {
          this.publisherProduct = res.data.data;
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        })
        .finally(() => {
          this.loadingAds = false;
        });
    },
    changeChoiceAds(status) {
      this.statusSale = status;
      this.showProducts(this.idPublisher, status);
    },
    async showPraiseTreat(id, status) {
      const stat = this.statusLike ? this.statusLike : status;
      this.idPublisher = id;
      this.loadingAds = true;
      await axios
        .get(`dashboard/publishers/${id}/reviews?status=${stat}`)
        .then((res) => {
          this.praiseList = res.data.data;
        })
        .catch((err) => {
          if (err.response.data.message == "no_data") {
            this.praiseList = [];
          } else {
            saveInfoAlert(
              this.$i18n.locale,
              "error",
              err.response.data.message
            );
          }
        })
        .finally(() => {
          this.loadingAds = false;
        });
    },
    changeChoicePraiseTreat(status) {
      this.statusLike = status;
      this.showPraiseTreat(this.idPublisher, status);
    },
  },
  watch: {
    "$i18n.locale"() {
      this.getPublishers(this.records, this.current_page);
    },
  },
  mounted() {
    this.getPublishers(10, 1);
  },
};
</script>

<style scoped>
.custom-table {
  min-width: 1300px;
}
.stars {
  color: #f0bc02;
  margin-top: 10px;
}
.stars i {
  margin-left: 3px;
}
.clickable {
  cursor: pointer;
}
</style>
