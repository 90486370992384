<template>
  <div>
    <!-- Add New Banner -->
    <div class="add-banner">
      <!-- Button trigger modal -->
      <button
        type="button"
        class="btn btn-blue"
        :class="{ rtl: $i18n.locale == 'ar' }"
        data-bs-toggle="modal"
        data-bs-target="#addBanner"
      >
        {{ $t("addNewBanner") }}
      </button>
      <ModalPopup
        :title="$t('addNewBanner')"
        :ident="'addBanner'"
        :width="'600px'"
        @send="addBanner"
        @cancelModal="cancelBanner"
        ref="modalAddBanner"
      >
        <AddBanner ref="addBanner" />
      </ModalPopup>
    </div>

    <!-- Table Banners -->
    <div class="responsive-table">
      <table class="custom-table table-bordered">
        <thead>
          <tr>
            <th scope="col" v-for="head in 6" :key="head">
              {{ $t(`listBannerTable[${head - 1}]`) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="banner in banners" :key="banner.id">
            <td>{{ banner.id }}</td>
            <td>
              <input
                type="checkbox"
                class="custom-toggle"
                :defaultChecked="banner.status"
                @change="changeStatus(banner.id)"
              />
            </td>
            <td>
              <button
                type="button"
                class="btn image-popup"
                data-bs-toggle="modal"
                :data-bs-target="'#web' + banner.id"
              >
                <img :src="banner.banner_web" />
              </button>
              <PopupImage :img="banner.banner_web" :ident="`web${banner.id}`" />
            </td>
            <td>
              <button
                type="button"
                class="btn image-popup"
                data-bs-toggle="modal"
                :data-bs-target="'#app' + banner.id"
              >
                <img :src="banner.banner_app" />
              </button>
              <PopupImage :img="banner.banner_app" :ident="`app${banner.id}`" />
            </td>
            <td>{{ banner.sort }}</td>
            <td>
              <div class="d-flex justify-content-evenly">
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#editBanner"
                  class="bg-transparent"
                  @click="editBanner(banner)"
                >
                  <img src="@/assets/images/edit.svg" />
                </button>
                <button
                  type="button"
                  class="bg-transparent"
                  @click="removeBanner(banner.id)"
                >
                  <img src="@/assets/images/trash.svg" alt="remove" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <ModalPopup
        :title="$t('editBanner')"
        :ident="'editBanner'"
        :width="'600px'"
        @send="confirmEditBanner"
        ref="modalEditBanner"
      >
        <EditBanner ref="editBanner" />
      </ModalPopup>
    </div>

    <!-- Pagination -->
    <PaginationBox
      :pages="pages"
      :current_page="current_page"
      :next_page="next_page"
      :prev_page="prev_page"
      @chooseRec="chooseRecord"
      @thisPage="thisPage"
      @nextPage="nextPage"
      @prevPage="prevPage"
    />

    <!-- Loading For Add and Edit Banner -->
    <div v-if="loadingBanner" class="loading load" :style="{ zIndex: 2000 }">
      <div class="text-center">
        <img src="@/assets/images/Pulse.svg" :style="{ width: '120px' }" />
      </div>
    </div>

    <!-- Loading For refresh Table -->
    <div v-if="loading" class="loading load">
      <div class="text-center">
        <img src="@/assets/images/loading.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import ModalPopup from "../global/ModalPopup.vue";
import AddBanner from "./AddBanner.vue";
import EditBanner from "@/components/bannerSetting/EditBanner.vue";
import PopupImage from "@/components/global/PopupImage.vue";
import PaginationBox from "../global/PaginationBox.vue";

import saveInfoAlert from "@/assets/js/saveInfoAlert";
import axios from "axios";

export default {
  name: "TableUser",
  components: {
    ModalPopup,
    EditBanner,
    PopupImage,
    PaginationBox,
    AddBanner,
  },
  data() {
    return {
      banners: [],
      bannerId: "",
      loading: false,
      loadingBanner: false,

      records: 10,
      pages: "",
      current_page: "",
      next_page: "",
      prev_page: "",
    };
  },
  methods: {
    async fetchBanner(record, page) {
      this.loading = true;
      await axios
        .get(`dashboard/banners?records=${record}&page=${page}`)
        .then((res) => {
          this.banners = res.data.data;
          this.pages = res.data.pagination.last_page;
          this.current_page = res.data.pagination.current_page;
          this.next_page = res.data.pagination.next_page_url;
          this.prev_page = res.data.pagination.prev_page_url;
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async addBanner() {
      var data = new FormData();
      if (
        this.$refs.addBanner.appImage.length &&
        this.$refs.addBanner.webImage.length
      ) {
        data.append("banner_app", this.$refs.addBanner.file1);
        data.append("banner_web", this.$refs.addBanner.file2);
        data.append("sort", this.$refs.addBanner.sort);
        data.append("product_id", this.$refs.addBanner.productId);
        this.loadingBanner = true;
        await axios
          .post("dashboard/banners", data)
          .then((res) => {
            saveInfoAlert(this.$i18n.locale, "success", res.data.message);
            this.$refs.modalAddBanner.closeModalButton.click();
            this.fetchBanner(this.records, this.current_page);
          })
          .catch((err) => {
            saveInfoAlert(
              this.$i18n.locale,
              "error",
              err.response.data.message
            );
          })
          .finally(() => {
            this.loadingBanner = false;
          });
      } else {
        saveInfoAlert(this.$i18n.locale, "error", this.$t("pleaseUploadImage"));
      }
    },
    cancelBanner() {
      this.$refs.addBanner.sort = "";
      this.$refs.addBanner.productId = "";
      this.$refs.addBanner.appImage = [];
      this.$refs.addBanner.file1 = false;
      this.$refs.addBanner.appSizeLong = false;
      this.$refs.addBanner.appErrorDimension = false;
      this.$refs.addBanner.webImage = [];
      this.$refs.addBanner.file2 = false;
      this.$refs.addBanner.webSizeLong = false;
      this.$refs.addBanner.webErrorDimension = false;
    },
    editBanner(banner) {
      this.bannerId = banner.id;
      this.$refs.editBanner.sort = banner.sort;
      this.$refs.editBanner.productId = banner.product_id;
      this.$refs.editBanner.appImage = banner.banner_app;
      this.$refs.editBanner.webImage = banner.banner_web;
      this.$refs.editBanner.appSizeLong = false;
      this.$refs.editBanner.webSizeLong = false;
      this.$refs.editBanner.appErrorDimension = false;
      this.$refs.editBanner.webErrorDimension = false;
      this.$refs.editBanner.file1 = null;
      this.$refs.editBanner.file2 = null;
    },
    async confirmEditBanner() {
      var data = new FormData();
      if (this.$refs.editBanner.file1) {
        data.append("banner_app", this.$refs.editBanner.file1);
      }
      if (this.$refs.editBanner.file2) {
        data.append("banner_web", this.$refs.editBanner.file2);
      }
      data.append("sort", this.$refs.editBanner.sort);
      data.append("product_id", this.$refs.editBanner.productId);
      this.loadingBanner = true;
      await axios
        .post(`/dashboard/banners/${this.bannerId}/update`, data)
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
          this.$refs.modalEditBanner.closeModalButton.click();
          this.fetchBanner(this.records, this.current_page);
        })
        .catch((err) => {
          console.log(err);
          // saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        })
        .finally(() => {
          this.loadingBanner = false;
        });
    },
    async removeBanner(id) {
      await axios
        .delete(`dashboard/banners/${id}`)
        .then((res) => {
          saveInfoAlert(this.$i18n.locale, "success", res.data.message);
          this.fetchBanner(this.records, this.current_page);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        });
    },
    async changeStatus(id) {
      await axios
        .post(`dashboard/banners/${id}/change-status`)
        .then(() => {
          // saveInfoAlert(this.$i18n.locale, "success", res.data.message);
        })
        .catch((err) => {
          saveInfoAlert(this.$i18n.locale, "error", err.response.data.message);
        });
    },
    chooseRecord(payload) {
      this.records = payload;
      this.fetchBanner(payload, 1);
    },
    thisPage(p) {
      this.fetchBanner(this.records, p);
    },
    nextPage() {
      this.fetchBanner(this.records, this.current_page + 1);
    },
    prevPage() {
      this.fetchBanner(this.records, this.current_page - 1);
    },
  },
  mounted() {
    this.fetchBanner(10, 1);
  },
};
</script>

<style scoped>
table {
  min-width: 1000px;
}
.add-banner button {
  min-width: 200px;
  margin-left: auto;
}
.add-banner button.rtl {
  margin-left: 0;
  margin-right: auto;
}
@media (max-width: 767px) {
  .add-banner button {
    width: 100%;
  }
}
</style>
