export default {
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الخـروج"])},
  "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
  "savedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الحفظ بنجـاح"])},
  "confirmationWasSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التأكيد بنجـاح"])},
  "capatcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتـب الأحرف الظاهرة لـك"])},
  "incorrectAcount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسابـك أو كلـمة المـرور غير صحيحة"])},
  "invalidUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم غير صالح. حاول مرة اخرى"])},
  "invalidPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة السر خاطئة. حاول مرة اخرى"])},
  "invalidChars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أحـرف غير صحيحة ، يرجى المحاولة مرة أخرى"])},
  "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الحقوق محفوظة لموقع توبز الخليجية 2022"])},
  "thisFieldIsMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل اجباري"])},
  "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المـديــر"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلـغاء"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إغـلاق"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تـأكيــد"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مـن"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلـى"])},
  "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدقـائـق"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأيـام"])},
  "times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المـرات"])},
  "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الســاعـات"])},
  "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خيـارات"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جـديد"])},
  "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اونلاين"])},
  "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حوالات"])},
  "linksList": [
    {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيــسية"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/ar"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bi-house"])}
    },
    {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصنيـف الإعـلان"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/ar/category-management"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bi-grid"])}
    },
    {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خصائص الاعلانات"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/ar/specification-management"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bi-card-checklist"])}
    },
    {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائـمة الاعلانـات"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/ar/ad-list"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bi-list-ul"])}
    },
    {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمـولة"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bi-receipt"])}
    },
    {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإحصـائـيات"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/ar/statistic"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bi-clipboard-data"])}
    },
    {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعـدادات المستخـدم"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/ar/user-setting"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bi-person"])}
    },
    {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المساعـدة"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bi-question-octagon"])}
    },
    {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشكـاوي"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/ar/complaining"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bi-exclamation-circle"])}
    },
    {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضبـط البـانرات"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/ar/banner-setting"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bi-sliders"])}
    },
    {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضبـط النـظام"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bi-gear"])}
    },
    {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة المعلنـين"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/ar/publisher-list"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bi-person-vcard"])}
    }
  ],
  "systemStat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصـائيات النـظام"])},
  "pageView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المشاهدات"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستخـدمون"])},
  "newAd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإعلانـات الجديـدة"])},
  "totalAd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي الإعلانـات"])},
  "newAds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعلانـات جـديدة"])},
  "revenue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمـالي الإيرادات"])},
  "byTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" حوالات بـنكية"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيـمة"])},
  "byOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الدفع اونلاين"])},
  "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مليـون"])},
  "sr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ريال"])},
  "k": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ألـف"])},
  "ex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مثال :"])},
  "commissionRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجـل العمـولات"])},
  "underAudit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنتظـار المـراجعة"])},
  "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسـودات"])},
  "onSaleOnStop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المُـعلن و المتـوقف"])},
  "thisYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذه السنـة"])},
  "lastYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السنـة الماضية"])},
  "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهر الماضي"])},
  "last3Months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آخر 3 أشهر"])},
  "thisWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الأسبوع"])},
  "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مخصص"])},
  "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مُـعلن"])},
  "stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مـتوقف"])},
  "months": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يناير"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فبراير"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مارس"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابريل"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مايو"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يونيو"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوليو"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أغسطس"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سبتمبر"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكتوبر"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوفمبر"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ديسمبر"])}
  ],
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تـغيير كلـمة المـرور"])},
  "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلـمة المـرور الحالــية"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلـمة المـرور الجـديدة"])},
  "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكـيد كلـمة المـرور "])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفـــظ"])},
  "selectEnableStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد تمكين الحالة"])},
  "selectSaleStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد حالة العرض"])},
  "enterPublisherName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجـاء إدخـال اسم المُـعلن"])},
  "enterUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجـاء إدخـال اسم المستخـدم"])},
  "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجـاء إختيـار الدولة"])},
  "countryName": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعودية"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإمارات"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكويت"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قطر"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحرين"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمان"])}
  ],
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نـعــم"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بـحــث"])},
  "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصـديـر"])},
  "tablePublish": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقــم"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصـورة"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المُـعلن"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدولة"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمكين الحالـة"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ايجابية / سلبية"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات(الفعالة والمتوقفة)"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آخر وقت دخول"])}
  ],
  "record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجـلات"])},
  "publishAd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعلانات المُعلن"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكــل"])},
  "praiseTreat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الايجابية / السلبية"])},
  "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الايجابية"])},
  "treat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السلبية"])},
  "title1publish": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الإعـلان"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان الإعـلان"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصنيف"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشاهدات"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت النشر"])}
  ],
  "title2publish": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستــخدم"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ايجابية / سلبية"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الـمحتـوى"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت النشر"])}
  ],
  "tableAdList": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعرّف"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان الإعلان"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الإعلان"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصنيف"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المُـعلن"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مُوصى به"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة العرض"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدولة"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليقات"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البلاغات"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت النشر"])}
  ],
  "noComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد تعليقات"])},
  "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المـحتــوى"])},
  "submitTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقـت التقـديـم"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقـم الجـوال"])},
  "noPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد هاتف"])},
  "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصــف"])},
  "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصــور"])},
  "noPictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد صور"])},
  "allowComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السمـاح بالتـعليــق"])},
  "listCommission": [
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصنيف العمولة"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/ar/category-commission-setting"])},
      "table": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف التصنيف"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم التصنيف"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معدل العمولة"])}
      ],
      "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمولة المستخدم النسبية = (مبلغ المعاملة - مبلغ العمولة المجانية) * (عمولة الفئة الرئيسية - خصم المستخدم) "])},
      "defenitions": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1- مبلغ الببيعة :يتم إدخال البيانات في ”حاسبة العمولة” في التطبيق بواسطة مستخدم التطبيق. "])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 - مبلغ العمولة المجانية: البيانات المدخلة في إعداد ”العمولة المجانية” في الكواليس بواسطة مسؤول النظام. "])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 - معدل العمولة: البيانات المدخلة في إعداد ”تصنيف العمولة” بواسطة مسؤول النظام."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 - معدل خصم المستخدم: تعيين البيانات في ”عمولة المستخدم” بواسطة مسؤول النظام."])}
      ],
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النـوع"])},
      "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النسبة"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمولة نسبية"])},
      "fixedComm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمــولة ثـابتة"])}
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمولة مجانية"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/ar/free-commission-setting"])},
      "freeCommissionAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبلغ العمولة المجانية"])},
      "freeplaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مثال: 30 ريال عمولة وأقل ستكون مجانية"])}
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمولة المستخدم"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/ar/user-commission"])},
      "placeholderUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجـاء إدخـال ID المستخـدم أو إسم المستخـدم"])},
      "waitReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتظر المراجعة"])},
      "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسودة"])},
      "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مؤكدة"])},
      "ignore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متجاهلة"])},
      "setDiscountRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضبط نسبة الخصم"])},
      "enterCorrectRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال النسبة بين 0 و 100"])},
      "ThisRateWillBeStartedFromTheNextPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيتم تطبيق هذا الخصم من العملية القادمة"])},
      "table": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف المستخدم"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة الخصم"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ الإجمالي للعمولة"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مؤكدة"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتظار التدقيق"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خيارات"])}
      ]
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجـل العمـولات"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/ar/commission-record"])},
      "viewInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الفاتورة"])},
      "confirmAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد المبلغ"])},
      "commissionTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي العمولة (ريال سعودي)"])},
      "ignore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تجاهل"])},
      "ignored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تجاهلها"])},
      "areYouSureToLgnoreThisRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل أنت متأكد من تجاهل هذا السجل؟"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السبب"])},
      "reasonOpt": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة إثبات التحويل غير واضحة"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ المستلم ليس المبلغ المطلوب"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحويل ليس موجهاً إلينا أو مزيفًا"])}
      ],
      "table": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفاتورة"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة الخصم"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت العملية"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيمة"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النـوع"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمولة"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خيارات"])}
      ]
    }
  ],
  "onlinePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدفع الالكتروني"])},
  "bankTransferred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحويل البنكي"])},
  "statList": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصنف الرئيسي"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي الاعلانات"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الاعلانات الفعالة"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي عمولة المستخدم (ريال)"])}
  ],
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاجمالي"])},
  "addNewUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافـة مسـتخـدم جـديد"])},
  "editUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير المسـتخـدم"])},
  "tableUserSetting": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف المسـتخـدم"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسـم المسـتخـدم"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإسـم الحقيـقي"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الانشاء"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمكين الحالة"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت الدخول"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خيـارات"])}
  ],
  "ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بالعربيـة"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بالانجليـزية"])},
  "listSystem": [
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحـسـاب البـنكي"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/ar/bank-account"])}
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعـدادات أخـرى"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/ar/other-setting"])}
    }
  ],
  "bankInfo": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الحساب البنكي"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسـم البـنــك"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسـم البـنــك"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مالك الحســاب"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مالك الحســاب"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الحســاب"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الآيبان"])}
  ],
  "invoiceInfo": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الفوترة الالكترونية"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إسـم الشـركــة"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخـل إسـم شـركــتك"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البـريــد الإلكتـروني"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخـل بـريــدك الإلكتـروني"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم الضريـبي"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الـدولــة"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الـمديــنة"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحي"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشـارع"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم المبنى"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقــم البـريــدي"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا كنت لا تعـرف ذلك فقـط اكتـب 00000"])}
  ],
  "timePeriod": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التوقيت الزمني "])},
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فترة التحديث"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذه هي الفترة الزمنية المحددة لمستخدم التطبيق لتحديث الإعلان مرة أخرى."])},
      "ex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["على سبيل المثال ، إذا تم التعيين على 30 دقيقة بعد المستخدم ، فسيتم تحديث الإعلان ، فيمكنه فقط تحديث نفس الإعلان للمرة الثانية بعد 30 دقيقة."])}
    },
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت ايقاف الاعلان"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" هذه هي الفترة الزمنية التي لم يتم فيها تحديث المستخدم الإعلان"])},
      "ex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["على سبيل المثال في حالة التعيين على 30 يوما عندما لا يتم تحديث المستخدم اعلان لمدة 30 يوما بعدها سيرسل النظام اشعار الى مستخدم التطبيق تلقائيا"])}
    },
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ايقاف البيع بعد الاشعـارات"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذه هي الفترة الزمنية التي لم يتم فيها تحديث المستخدم الإعلان بعد وقت الاشعار بوقف البيع"])},
      "ex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["على سبيل المثال  في حالة التعيين على 5 أيام عندما لا يتم تحديث المستخدم الإعلان لمدة 30 يومًا بعد وقت إشعار إيقاف البيع فهذا يعني عدم قيام المستخدم بتحديث الإعلان"])}
    },
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ايقاف البيع بعد تقديم الشكوى"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا هو مرات الشكاوي من اعلان واحد"])},
      "ex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["على سبيل المثال في حالة التعيين على 15 مرة عند تلقي الإعلان 15 شكوى ثم يقوم النظام بإيقاف اظهار الإعلان وإرسال رسالة اشعار إلى مستخدم التطبيق تلقائيًا"])}
    },
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشر اعلان جديد بعد كل "])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاكثر بحثاً"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضغط على ctrl + enter لإضافة نموذج جديد"])}
  ],
  "pleaseEnterModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال نموذج جديد"])},
  "emailSetting": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعداد البريد الالكتروني "])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستضيف"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خادم البريد الصادر "])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنفذ"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منفذ خادم SMTP"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب البريد "])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم البريد "])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة مرور البريد"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من البريد"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد المرسل للرسائل الصادرة"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرسل البريد"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المرسل البريد الصادر "])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد المخفي "])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسخة مخفية الوجهة لعنوان بريد اختياري"])}
  ],
  "twilioSetting": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعدادات Twilio للرسائل"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسـاب SID"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمـز مصـادقة Token"])}
  ],
  "onlinePay": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدفع الالكتروني"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بروفايل ID"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مفتاح API"])}
  ],
  "invoicing": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مزود الفوترة"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مفتاح API"])}
  ],
  "pusherInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الناشـر"])},
  "pusherID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pusher APP ID"])},
  "pusherKEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pusher APP ID"])},
  "pusherSecret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pusher APP ID"])},
  "shareMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشاركة الرسالة"])},
  "shareMessageplatform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشاركة منصة الرسائل"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أخرى"])},
  "listHelp": [
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة العـقــود"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/ar/protocols-management"])}
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة الفيديو"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/ar/video-management"])}
    }
  ],
  "PublisherManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائـمة النـاشريـن"])},
  "processingDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمت المعالجة"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في الانتظار"])},
  "addNewBanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة بانر جديد"])},
  "listBannerTable": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقـم"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التمـكيـن"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الويب"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة التطبيق"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الترتـيب"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خيـارات"])}
  ],
  "bannerApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بانر التطبيق"])},
  "bannerWeb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بانر الويب"])},
  "uploadImg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتحميل صور بتنسيق png أو Jpg  بحجم 6 ميجا بايت كحد أقصى ، مقاس 300 * 200 بكسل"])},
  "uploadImgWeb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتحميل صور Png أو Jpg، بحجم 6 يجا بايت بحد أقصى، 1800 * 900 بكسل أو أكثر"])},
  "imageSizeLargerThan6MB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجم الصورة أكبر من 6 ميجا"])},
  "sizeLongMuch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجم الصورة أكبر من 4 ميجا"])},
  "imageDimensionsAreSmallerThan300200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أبعاد الصورة اصغر من 300 * 200 بكسل"])},
  "imageDimensionsAreSmallerThan1800900": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أبعاد الصورة اصغر من 1800 * 900 بكسل"])},
  "thisIconLargerThan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجم الأيقونة أكبر من 50 كيلو بايت"])},
  "iconDimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أبعاد الأيقونة أكبر من 50 * 50 بكسل"])},
  "UploadYourPicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارفع صورتك"])},
  "editBanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير البانر"])},
  "ImageApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصورة / التطبيق"])},
  "ImageWeb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصورة / الويب"])},
  "editImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الصورة"])},
  "enterSpecificationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال اسم المواصفات"])},
  "addNewSpecification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة مواصفات جديدة"])},
  "editSpecification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير المواصفات"])},
  "listSpecificationTable": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسـم"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصـف"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النـوع"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمكين الحالـة"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجبـاري"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["افتـراضي"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خيـارات"])}
  ],
  "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متـعدد الخيـارات"])},
  "radio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خيار واحـد"])},
  "dropDown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قـائـمة منسـدلة"])},
  "selfWrite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكتـابة الذاتـية"])},
  "pdfFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملـف PDF"])},
  "chooseClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حـدد الأصنـاف"])},
  "relatedClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصنـاف ذات صلة"])},
  "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجبـاري"])},
  "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ظـاهر"])},
  "enterCategoryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال اسم الصنف"])},
  "addNewCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة فئة جديدة"])},
  "editCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير الصنف"])},
  "deleteCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف الفئة"])},
  "areYouSureToDeleteThisCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل أنت متأكد من حذف هذه الفئة؟"])},
  "listCategoryTable": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فئة الإعـلان"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم التصنيف"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الترتـيب"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمكيـن الحالـة"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأيـقونـة"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المـوصى بـه"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خيـارات"])}
  ],
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسـم"])},
  "categoriesName": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العقارات"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السيارات"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمات"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الثروة الحيوانية"])}
  ],
  "categoryTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان الصنـف"])},
  "uploadPng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتحميل  png أو svg حجم 50 كيلو بايت كحد أقصى ، 50 * 50 بكسل"])},
  "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأيـقونـة"])},
  "uploadYourIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل الأيقونة"])},
  "editIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير الايقونة"])},
  "addMinCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة التصنيف الوسطي"])},
  "addFinalCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة التصنيف النهائي"])},
  "addNewVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضـافة فيديو جديد"])},
  "editVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير الفيديو"])},
  "videoTable": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقــم"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان الفيديو"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابط الفيديو"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الترتـيب"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خيـارات"])}
  ],
  "coverPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الغلاف"])},
  "uploadPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتحميل الصورة بتنسيق png أو ملف jpeg أو 4 ميجابايت كحد أقصى, 300 * 200 بكسل أو أكثر"])},
  "pleaseUploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى تحميل الصور"])},
  "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر ملف "])},
  "pleaseUploadIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تحميل الأيقونة"])},
  "pleaseUploadCoverPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تحميل صورة الغلاف"])},
  "addedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمت الإضافة بنجـاح"])},
  "editedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التحرير بنجاح"])},
  "removedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الحذف بنجاح"])},
  "pleaseChooseClasses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تحديد الاصناف"])},
  "areYouSuredeleteVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل أنت متأكد أنك تريد حذف هذا الفيديو ؟"])},
  "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عقـد العمولة"])},
  "useOfTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيـاسة الاستخـدام"])},
  "returnPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسة الاسترجـاع"])},
  "aboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحــن"])},
  "pleaseSelectPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تحديد الأذونات"])},
  "oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...أُووبس"])},
  "youtubeLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابط اليوتيوب"])},
  "noLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد رابط"])}
}